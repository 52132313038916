import { AxiosApiWrapper } from "../abstract/axios-api-wrapper.abstract";
import { ProfileListType } from "../../types/profile/profileListTypes";

class ProfileApi extends AxiosApiWrapper {
  protected endPoint!: string;

  // 프로필 복사본 생성
  public fetchCreateCopyProfile(
    data: Record<string, unknown>,
  ): Promise<ProfileListType> {
    this.endPoint = "api/v1/business/tutor-profile";
    try {
      return this.post(data);
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  }

  // 선택한 선생님 프로필 메인 리스트
  public fetchProfileList(profileId: string): Promise<ProfileListType> {
    this.endPoint = `api/v1/business/tutor-profile/${profileId}`;
    try {
      return this.get();
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  }

  // 프로필 메인 리스트 수정
  public fetchProfileListUpdate(
    profileId: string,
    endPoint: string,
    data: FormData,
  ): Promise<ProfileListType> {
    this.endPoint = `api/v1/business/tutor-profile/${profileId}/${endPoint}`;
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    try {
      return this.put(data, config);
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  }

  // 프로필 서류 인증 플래그 수정
  public fetchProfileDocCertFlagUpdate(
    profileId: string,
    endPoint: string,
    data: FormData,
  ): Promise<ProfileListType> {
    this.endPoint = `api/v1/business/tutor-profile/${profileId}/${endPoint}`;
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    try {
      return this.put(data, config);
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  }

  // 프로필 전송
  public fetchProfileTransfer(data: Record<string, unknown>) {
    this.endPoint = "api/v1/business/tutor-profile/send";
    try {
      return this.post(data);
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  }
}

export default new ProfileApi();
