import { useState, useRef, useEffect } from "react";

const SelectDropDown = ({
  initialLabel,
  options,
  isFull,
  disabled,
  value,
  onChange,
}: {
  value?: string;
  initialLabel: string;
  options: { [key: string]: string };
  isFull: boolean;
  disabled?: boolean;
  onChange: (newValue: string) => void;
}) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const [selectedLabel, setSelectedLabel] = useState<string>(
    value ? options[value] : initialLabel,
  );
  const selectBoxRef = useRef<HTMLDivElement>(null);

  const toggleSelectBox = () => {
    if (!disabled) {
      setExpanded(!expanded);
    }
  };

  const selectOption = (optionKey: string) => {
    setSelectedLabel(options[optionKey]);
    setExpanded(false);
    onChange(optionKey);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      selectBoxRef.current &&
      !selectBoxRef.current.contains(event.target as Node)
    ) {
      setExpanded(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setSelectedLabel(value ? options[value] : initialLabel);
  }, [value, initialLabel, options]);

  const optionKeys: string[] = options ? Object.keys(options) : [];

  useEffect(() => {
    if (value && options[value]) {
      setSelectedLabel(options[value]);
    } else {
      setSelectedLabel(initialLabel);
    }
  }, [initialLabel, options, value]);

  return (
    <div className={`select-dropdown ${isFull ? "select-dropdown--full" : ""}`}>
      <div
        role={"presentation"}
        className={`select-dropdown__inner ${expanded ? "expanded" : ""} ${disabled ? "select-dropdown__inner--disabled" : ""}`}
        onClick={toggleSelectBox}
        ref={selectBoxRef}
      >
        <div className="select-dropdown__arrow" />
        <div className="select-dropdown__label">
          <span>{selectedLabel}</span>
        </div>

        <ul className="select-dropdown__list">
          {optionKeys.map((key: string) => (
            <li
              role={"presentation"}
              key={`select-dropdown-list-ul-li-${key}`}
              className="select-dropdown__item"
              onClick={() => selectOption(key)}
            >
              {options[key]}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default SelectDropDown;
