import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { SnackbarProvider } from "notistack";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("app") as HTMLElement);

/**
 * local 환경에서 mock worker 띄움
 * mockWorker 용도 : Backend API spec 확정 이후 Front 개발 pending 시간을 줄이기 위함
 */
// async function enableMocking() {
//   if (process.env.REACT_APP_ENV !== "local") {
//     return;
//   }
//   const { mockWorker } = await import("./mocks/browser");
//   console.debug("## running - mock worker ##");
//   return mockWorker.start();
// }
// eslint-disable-next-line @typescript-eslint/no-empty-function
// enableMocking().then(() => {});

root.render(
  <React.StrictMode>
    <SnackbarProvider
      autoHideDuration={3000}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      <App />
    </SnackbarProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
