import { useLocation } from "react-router-dom";
import moment from "moment";
import { Box } from "@mui/material";
import useBoardInfoQuery from "../../../../../hooks/myInfo/board/useBoardInfoQuery";

function QuestionInfo() {
  const location = useLocation();
  const { data: boardInfo } = useBoardInfoQuery(location.state);
  return (
    <main className="content pb0">
      <div className="section">
        <div className="board-view">
          <div className="board-view__head">
            <div className="board-view__title">{boardInfo?.boardContent}</div>
            {boardInfo?.replyFlag === "Y" && (
              <span className="board-view__date">
                {moment(boardInfo?.createdAt).format("YYYY.MM.DD")}
              </span>
            )}
          </div>
          {boardInfo?.files && boardInfo?.files.length > 0 && (
            <div className="board-view__body">
              {boardInfo?.files.map((file, index) => (
                <div key={`img-${index}`}>
                  <img
                    style={{ width: "100%" }}
                    src={file.fileUrl}
                    alt="file"
                  />
                </div>
              ))}
              {/* <br /> */}
              {/* <p> */}
              {/*   안녕하세요 문의사항이 한가지 있습니다. 제가 선생님인데 학부모로 */}
              {/*   잘못 가입했어요. 어떻게 해야 하나요? ㅠㅠ */}
              {/* </p> */}
            </div>
          )}
        </div>

        {/* 확인 중  */}
        {!boardInfo?.replyFlag && (
          <div className="master-answer pb24">
            <div className="master-answer__head">문의사항을 확인 중입니다.</div>
            <div className="master-answer__cont">
              <div>답변 등록 시, 앱 알림 또는 알림톡으로 안내해드립니다.</div>
            </div>
          </div>
        )}

        {/* 답변완료 */}
        {boardInfo?.replyFlag === "Y" && (
          <div className="master-answer pb24">
            <div className="master-answer__head">답변 내용</div>
            <div className="master-answer__cont">
              <div>
                <Box
                  sx={{}}
                  dangerouslySetInnerHTML={{
                    __html: boardInfo?.replyContent,
                  }}
                />
              </div>
              <span className="master-answer__date">
                {moment(boardInfo?.updatedAt).format("YYYY.MM.DD")}
              </span>
            </div>
          </div>
        )}
      </div>
    </main>
  );
}

export default QuestionInfo;
