import { useMutation } from "react-query";
import MemberApi from "../../services/member/MemberApi";

interface WithdrawParams {
  withDrawCode: string;
  memberType: string;
}

const useMemberWithDrawMutation = (modalClose: () => void) => {
  return useMutation(
    (params: WithdrawParams) => {
      return MemberApi.fetchMemberWithdraw({ ...params });
    },
    {
      onSuccess: (data) => {
        console.log("Mutation successful:", data);
        modalClose();
      },
      onError: (error) => {
        console.error("Mutation error:", error);
      },
    },
  );
};

export default useMemberWithDrawMutation;
