import { useQuery, UseQueryResult } from "react-query";
import { SubjectListType } from "../../types/standardInfo/standardInfoTypes";
import StandardInfoApi from "../../services/standardInfo/StandardInfoApi";

const useSubjectsQuery = (): UseQueryResult<SubjectListType, Error> => {
  return useQuery<SubjectListType, Error>({
    queryKey: ["universities"],
    queryFn: () => StandardInfoApi.fetchSubjects(),
    onSuccess: (data: SubjectListType) => {
      console.log("Query successful:", data);
    },
    onError: (error) => {
      console.error("Query error:", error);
    },
  });
};

export default useSubjectsQuery;
