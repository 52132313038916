import { useMutation, useQueryClient } from "react-query";
import { enqueueSnackbar } from "notistack";
import PartnerTutorApi from "../../../services/partnerTutor/PartnerTutorApi";
import { PartnerTutorInterestMutationParams } from "../../../types/partnerTutor/partnerTutorTypes";

const usePartnerTutorInterestAddMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (params: PartnerTutorInterestMutationParams) => {
      return PartnerTutorApi.fetchPartnerTutorInterestAdd({ ...params });
    },
    {
      onSuccess: (data) => {
        console.log("Mutation successful:", data);
        queryClient.invalidateQueries("partnerTutorList");
        enqueueSnackbar("저장되었습니다.");
      },
      onError: (error) => {
        console.error("Mutation error:", error);
      },
    },
  );
};

export default usePartnerTutorInterestAddMutation;
