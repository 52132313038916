import { useMutation } from "react-query";
import { enqueueSnackbar } from "notistack";
import AuthApi from "../../services/auth/AuthApi";

interface UpdatePasswordParams {
  newPassword: string;
  confirmPassword: string;
  memberType: string;
}

const useUpdatePasswordMutation = () => {
  return useMutation(
    (params: UpdatePasswordParams) => {
      return AuthApi.fetchUpdatePassword({ ...params });
    },
    {
      onSuccess: (data) => {
        console.log("Mutation successful:", data);
        enqueueSnackbar("비밀번호가 변경되었습니다.");
      },
      onError: (error) => {
        console.error("Mutation error:", error);
      },
    },
  );
};

export default useUpdatePasswordMutation;
