export const profileGroups = [
  {
    label: "학력정보",
    fields: [
      "universityName",
      "majorName",
      "classNumber",
      "schoolStatus",
      // "diplomaImg",
      // "diplomaDoc",
    ],
    required: true,
    noEdit: true,
  },
  {
    label: "지도과목",
    fields: ["subject"],
    required: true,
  },
  {
    label: "한줄소개",
    fields: ["introduce"],
    required: true,
  },
  {
    label: "경력",
    fields: ["career"],
    required: true,
    common: true,
    placeholder:
      "* 예시와 같이 각 경력과 총 경력을 작성해주세요.\n\n예시)\n00학원 0년\n00학교 0년\n총 경력 00년",
  },
  {
    label: "수업스타일 및 수업 특장점",
    fields: ["classStyle"],
    required: true,
    common: true,
    placeholder: "* 수업 스타일 및 특장점을 작성해주세요.",
  },
  {
    label: "성적 향상 사례",
    fields: ["improveGrades"],
    common: true,
    placeholder: "성적을 향상했던 사례가 있다면 작성해주세요.",
  },
  {
    label: "수업 선호 학년",
    fields: ["preferGrade"],
    required: true,
  },
  {
    label: "과외 방식(대면과외/화상과외)",
    fields: [
      "classType",
      // "certFaceToFaceImg",
      // "certFaceToFaceDoc",
      // "certOnlineImg",
      // "certOnlineDoc",
    ],
    required: true,
    noEdit: true,
  },
  {
    label: "수업 경험 학교",
    fields: ["schoolExperience"],
    common: true,
    placeholder:
      "수업을 경험했던 학교명을 작성해주세요.\n\n예시)\n낙생고, 보평고, 늘푸른고",
  },
  {
    label: "시범 과외(유료/무료)",
    fields: ["demoClassType", "demoClassDesc"],
    required: true,
    placeholder: "시범 과외 소요시간 등 추가적인 설명이 있다면 작성해주세요.",
  },
  {
    label: "주 사용 교재",
    fields: ["teachingMaterial"],
    common: true,
    placeholder: "주로 사용하시는 교재를 작성해주세요.",
  },
  {
    label: "대학 합격 및 중/고등학교 정보",
    fields: [
      "admissionSelection",
      "admissionProcess",
      "highSchool",
      // "middleSchool",
    ],
    required: true,
    noEdit: true,
  },
  {
    label: "사진 및 동영상",
    fields: ["imageLinks"],
    noEdit: true,
  },
  {
    label: "대면과외 가능지역",
    fields: ["faceToFaceArea", "faceToFaceAreaPublicFlag"],
    required: true,
    noPreview: true, // 학부모 미노출, 미리보기에 미노출 시킴
    noEdit: true,
  },
  {
    label: "거주지",
    fields: ["address", "addressPublicFlag"],
    required: true,
    common: true,
    noPreview: true,
    noEdit: true,
  },
  {
    label: "수업 가능 일정",
    fields: ["possibleSchedule", "possibleSchedulePublicFlag"],
    common: true,
    placeholder: "수업 가능 또는 불가능한 일정을 작성해주세요.",
    noPreview: true,
    noEdit: true,
  },
  {
    label: "평균 수업료",
    fields: ["averagePay", "averagePayPublicFlag"],
    common: true,
    placeholder: "평균 수업료를 작성해주세요.",
    noPreview: true,
    noEdit: true,
  },
  {
    label: "휴대폰 외 연락처",
    fields: ["contactAddress", "contactAddressPublicFlag"],
    common: true,
    placeholder: "휴대폰번호 외 이메일 등 연락 가능한 연락처를 작성해주세요.",
    noPreview: true,
    noEdit: true,
  },
];
