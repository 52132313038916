import { useRecoilValue } from "recoil";
import moment from "moment";
import { useState } from "react";
import useAlarmSettingInfoQuery from "../../../hooks/myInfo/alarmSetting/useAlarmSettingQuery";
import { userAtom } from "../../../store/common/userStore";
import useAlarmSettingAlimUpdateMutation from "../../../hooks/myInfo/alarmSetting/useAlarmSettingUpdateMutation";
import CommonModal from "../../common/modal/CommonModal";

function AlarmSetting() {
  const user = useRecoilValue(userAtom);
  const [modalName, setModalName] = useState<string>("");
  const modalClose = () => setModalName("");
  const { data: alarmSettingInfo } = useAlarmSettingInfoQuery(
    String(user.member.id),
  );
  const alarmSettingAlimUpdateMutation = useAlarmSettingAlimUpdateMutation();

  // 알림받기, 이벤트 알림받기 둘다 동의에서 비동의로 변경 시도 시 얼럿 노출 후 컨펌해야 변경 됨
  const handleAlimFlag = () => {
    if (alarmSettingInfo?.alimFlag === "Y") {
      setModalName("alimFlag");
    } else {
      // 바로 mutate 호출
      alarmSettingAlimUpdateMutation.mutate({
        params: {
          alimFlag: "Y",
        },
        id: String(user.member.id),
        endPoint: "alim",
      });
    }
  };

  // 알림 받기 설정을 Y에서 N으로 변경 할 시
  const confirmAlimFlagChange = () => {
    // 모달을 통해서 확인을 누르면 mutate 호출
    alarmSettingAlimUpdateMutation.mutate({
      params: {
        alimFlag: "N",
      },
      id: String(alarmSettingInfo?.id),
      endPoint: "alim",
    });
    modalClose();
  };

  const handleEventFlag = () => {
    if (alarmSettingInfo?.eventFlag === "Y") {
      setModalName("eventFlag");
    } else {
      alarmSettingAlimUpdateMutation.mutate({
        params: {
          eventFlag: "Y",
        },
        id: String(alarmSettingInfo?.id),
        endPoint: "event",
      });
    }
  };

  // 이벤트 받기 설정을 Y에서 N으로 변경 할 시
  const confirmEventFlagChange = () => {
    alarmSettingAlimUpdateMutation.mutate({
      params: {
        eventFlag: "N",
      },
      id: String(alarmSettingInfo?.id),
      endPoint: "event",
    });
    modalClose();
  };

  return (
    <>
      <main className="content">
        <div className="section">
          <div className="link-nav">
            <ul className="link-nav__list">
              <li className="link-nav__item">
                <div className="link-nav__link link-nav__link--justify-between link-nav__link--nohover">
                  <div className="link-nav__title">알림받기</div>
                  <div className="switcher">
                    <span className="switcher__title" />
                    <label className="switcher__label">
                      <input
                        type="checkbox"
                        className="switcher__input"
                        checked={alarmSettingInfo?.alimFlag === "Y"}
                        onChange={handleAlimFlag}
                      />
                      <b />
                    </label>
                  </div>
                </div>
              </li>
              <li className="link-nav__item">
                <div className="link-nav__link link-nav__link--justify-between link-nav__link--nohover">
                  <div className="link-nav__title link-nav__title--direction-column link-nav__title--items-start">
                    이벤트 혜택 알림 받기
                    <span className="typo-small2 normal color-gray-700">
                      마케팅 정보 수신 해제{" "}
                      {alarmSettingInfo?.eventFlagDate
                        ? moment(alarmSettingInfo?.eventFlagDate).format(
                            "YYYY.MM.DD",
                          )
                        : "YYYY.MM.DD"}
                    </span>
                  </div>
                  <div className="switcher">
                    <span className="switcher__title" />
                    <label className="switcher__label">
                      <input
                        type="checkbox"
                        className="switcher__input"
                        checked={alarmSettingInfo?.eventFlag === "Y"}
                        onChange={handleEventFlag}
                      />
                      <b />
                    </label>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </main>
      <CommonModal
        open={modalName === "alimFlag"}
        modalClose={modalClose}
        title={"알림 미전송"}
        text={"정보성 알림을 받을 수 없습니다."}
        onConfirm={confirmAlimFlagChange}
      />
      <CommonModal
        open={modalName === "eventFlag"}
        modalClose={modalClose}
        title={"알림 미전송"}
        text={
          "알림을 끄시겠어요? 유용한 정보나 이벤트 알림을 받지 못할 수도 있습니다."
        }
        onConfirm={confirmEventFlagChange}
      />
    </>
  );
}

export default AlarmSetting;
