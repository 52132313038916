import styled from "styled-components";

const StyledCommonButton = styled.button<{ $isSelected: boolean }>`
  background-color: ${(props) =>
    props.$isSelected ? "rgba(246, 98, 23, 0.1)" : "white"};
  color: ${(props) => (props.$isSelected ? "#f66217" : "#868686")};
  border-color: ${(props) =>
    props.$isSelected ? "rgba(246, 98, 23, 0.25)" : "#dbdbdb"};

  // .week-time__daybtn - common.css
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: calc((100% - 60px) / 5);
  height: 2.25rem;
  font-size: 1rem;
  //color: #868686;
  //border: 0.0625rem solid #dbdbdb;
  border-radius: 2.25rem;
  transition: all 0.3s;
`;

function CommonWeekButton({
  week,
  onClick,
  isSelected,
}: {
  week: string;
  onClick: (week: string) => void;
  isSelected: boolean;
}) {
  const handleClick = () => {
    onClick(week);
  };
  return (
    <StyledCommonButton
      type={"button"}
      role={"presentation"}
      className="check-chip__title"
      $isSelected={isSelected}
      onClick={handleClick}
    >
      {week}
    </StyledCommonButton>
  );
}

export default CommonWeekButton;
