import { useMutation } from "react-query";
import AuthApi from "../../services/auth/AuthApi";

interface ConfirmEmailAuthCodeParams {
  email: string;
  memberType: string;
  certNum: string;
}

const useConfirmEmailAuthCodeMutation = (
  setIsConfirm: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  return useMutation(
    (params: ConfirmEmailAuthCodeParams) => {
      return AuthApi.fetchConfirmEmailAuthCode({ ...params });
    },
    {
      onSuccess: (data) => {
        console.log("Mutation successful:", data);
        setIsConfirm(true);
      },
      onError: (error) => {
        console.error("Mutation error:", error);
      },
    },
  );
};

export default useConfirmEmailAuthCodeMutation;
