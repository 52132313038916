import { AxiosApiWrapper } from "../abstract/axios-api-wrapper.abstract";
import { ConfigListType } from "../../types/config/configTypes";

class ConfigListApi extends AxiosApiWrapper {
  protected endPoint = "api/v1/code/get/all";
  public fetchConfigList(): Promise<ConfigListType> {
    try {
      return this.get();
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  }
}

export default new ConfigListApi();
