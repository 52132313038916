import { useQuery, UseQueryResult } from "react-query";
import { BoardInfoType } from "../../../types/myInfo/boardTypes";
import BoardApi from "../../../services/myInfo/BoardApi";

const useBoardInfoQuery = (
  endPoint: string,
): UseQueryResult<BoardInfoType, Error> => {
  return useQuery<BoardInfoType, Error>({
    queryKey: ["boardInfo", endPoint],
    queryFn: () => BoardApi.fetchBoardInfo(endPoint),
    onSuccess: (data: BoardInfoType) => {
      console.log("Query successful:", data);
    },
    onError: (error) => {
      console.error("Query error:", error);
    },
  });
};

export default useBoardInfoQuery;
