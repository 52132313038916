import { useEffect, useState } from "react";
import { enqueueSnackbar } from "notistack";
import { useQueryClient } from "react-query";
import TabMenu from "../../tab/subjectTab/TabMenu";
import MenuList from "../../tab/subjectTab/MenuList";
import SelectedItems from "../../tab/subjectTab/SelectedItems";
import useProfileListUpdateMutation from "../../../../../hooks/profile/useProfileListUpdateMutation";
import useSubjectsQuery from "../../../../../hooks/standardInfo/useSubjectsQuery";
import { ProfileListType } from "../../../../../types/profile/profileListTypes";

const ProfileSubjectEditContainer = ({
  clickClose,
}: {
  clickClose: () => void;
}) => {
  const { data } = useSubjectsQuery();
  const mutation = useProfileListUpdateMutation(clickClose);
  const queryClient = useQueryClient();
  const profileData =
    queryClient.getQueryData<ProfileListType>("profileCopyList");
  console.log(profileData?.subject);

  const categories: string[] = Object.keys(data ?? {});
  const [selectedCategory, setSelectedCategory] = useState<string>("");
  const [selectedItems, setSelectedItems] = useState<string[]>(
    profileData?.subject?.split(",") ?? [],
  );
  const [isValid, setIsValid] = useState(false);

  const handleCategorySelect = (category: string) => {
    setSelectedCategory(category);
  };

  const handleItemToggle = (item: string) => {
    setSelectedItems((prev) =>
      prev.includes(item) ? prev.filter((i) => i !== item) : [...prev, item],
    );
  };

  const handleItemRemove = (item: string) => {
    setSelectedItems((prev) => prev.filter((i) => i !== item));
  };

  const handleReset = () => {
    setSelectedItems([]);
    enqueueSnackbar("초기화되었습니다.");
  };

  const handleSave = () => {
    const formDataToSend = new FormData();
    formDataToSend.append("subject", selectedItems.toString());
    mutation.mutate({ params: formDataToSend, endPoint: "subject" });
  };

  useEffect(() => {
    if (categories.length > 0 && !selectedCategory) {
      setSelectedCategory(categories[0]);
    }
  }, [categories, selectedCategory]);

  useEffect(() => {
    if (selectedItems.length > 0) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [selectedItems]);

  return (
    <>
      <div className="modal-dialog__header">
        <span className="modal-dialog__title">지도과목</span>
        <button
          onClick={clickClose}
          type="button"
          className="modal-dialog__close"
        >
          <i className="icon i-close" />
        </button>
      </div>
      <div className="modal-dialog__body">
        <div className="msg-ly">
          <i className="icon i-info" />
          <p className="msg-ly__text">
            지도가 가능한 과목을 모두 선택해주세요.
          </p>
        </div>
        <div className="master-menu mt4">
          <div className="master-menu__container">
            <div className="master-menu__list">
              <TabMenu
                categories={categories}
                selectedCategory={selectedCategory}
                onSelect={handleCategorySelect}
              />
              <MenuList
                items={data ? data[selectedCategory] : []}
                selectedItems={selectedItems}
                onToggle={handleItemToggle}
              />
            </div>
            <SelectedItems
              selectedItems={selectedItems}
              onRemove={handleItemRemove}
            />
          </div>
        </div>
      </div>
      <div className="modal-dialog__footer">
        <button
          type="button"
          className="btn btn--full btn--gray"
          onClick={handleReset}
        >
          선택항목 초기화
        </button>
        <button
          type="button"
          disabled={!isValid}
          className={`btn btn--full ${!isValid ? "is-disabled" : "btn--primary"}`}
          onClick={handleSave}
        >
          저장하기
        </button>
      </div>
    </>
  );
};

export default ProfileSubjectEditContainer;
