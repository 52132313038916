import { useParams } from "react-router-dom";
import ProfileInfoPreview from "../profile/info/ProfileInfoPreview";
import { decrypt } from "../../../util/crypto";

const EncryptedProfileRedirect = () => {
  const { encryptedLink } = useParams();

  try {
    if (encryptedLink != null) {
      const decryptedData = decrypt(encryptedLink);
      const [ID, expireDate] = decryptedData.split("|");

      const now = new Date();
      if (new Date(expireDate) >= now) {
        // 유효한 경우, 복호화된 ID를 이용해 프로필 정보 로드
        return <ProfileInfoPreview propsId={ID} isExternal />;
      }
    }
    // 링크가 만료된 경우 처리
    return <div>링크가 만료되었습니다.</div>;
  } catch (error) {
    console.error("Decryption failed or invalid data");
    return <div>Invalid link.</div>;
  }
};

export default EncryptedProfileRedirect;
