import { useInfiniteQuery } from "react-query";
import {
  PartnerTutorInterestQueryParamsType,
  PartnerTutorListType,
} from "../../../types/partnerTutor/partnerTutorTypes";
import PartnerTutorApi from "../../../services/partnerTutor/PartnerTutorApi";

const usePartnerTutorInterestListInfiniteQuery = (
  params: Omit<PartnerTutorInterestQueryParamsType, "page"> | undefined,
) => {
  return useInfiniteQuery<PartnerTutorListType, Error>({
    queryKey: ["partnerTutorInterestList"],
    queryFn: ({ pageParam = 0 }) =>
      PartnerTutorApi.fetchPartnerTutorInterestList({
        ...params,
        page: pageParam,
      }),
    getNextPageParam: (lastFetchedPage, allFetchedPages) => {
      const currentPageIndex = allFetchedPages.length;
      if (currentPageIndex < lastFetchedPage.totalPages) {
        return currentPageIndex;
      }
      return undefined;
    },
    onSuccess: (data) => {
      console.log("Query successful:", data);
    },
    onError: (error) => {
      console.error("Query error:", error);
    },
  });
};

export default usePartnerTutorInterestListInfiniteQuery;
