import { useInfiniteQuery } from "react-query";
import PartnerTutorApi from "../../services/partnerTutor/PartnerTutorApi";
import {
  PartnerTutorListType,
  PartnerTutorRequestListQueryParams,
} from "../../types/partnerTutor/partnerTutorTypes";

const usePartnerTutorListRequestInfiniteQuery = (
  params: Omit<PartnerTutorRequestListQueryParams, "page"> | undefined,
) => {
  return useInfiniteQuery<PartnerTutorListType, Error>({
    queryKey: ["partnerTutorRequestList", params],
    queryFn: ({ pageParam = 0 }) =>
      PartnerTutorApi.fetchPartnerTutorRequestList({
        ...params,
        page: pageParam,
      }),
    getNextPageParam: (lastFetchedPage, allFetchedPages) => {
      const currentPageIndex = allFetchedPages.length;
      if (currentPageIndex < lastFetchedPage.totalPages) {
        return currentPageIndex;
      }
      return undefined;
    },
    onSuccess: (data) => {
      console.log("Query successful:", data);
    },
    onError: (error) => {
      console.error("Query error:", error);
    },
  });
};

export default usePartnerTutorListRequestInfiniteQuery;
