import { useEffect, useState } from "react";
import DaumPostcode from "react-daum-postcode";
import { Modal, Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { setBackPressOn } from "../../../gnote_api";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  height: "60%",
  bgcolor: "background.paper",
  border: "1px solid black",
  boxShadow: 24,
  p: 2,
  paddingTop: "40px",
};

const AddressSearch = ({
  value,
  onChange,
}: {
  value: string;
  onChange: (value: string) => void;
}) => {
  const [open, setOpen] = useState(false);

  const handleComplete = (data: {
    address: string;
    sido: string;
    sigungu: string;
    bname1: string;
    bname2: string;
    bname: string;
  }) => {
    // let fullAddress = `${data.sido} ${data.sigungu}`;
    // if (data.bname1) fullAddress += ` ${data.bname1}`;
    // if (data.bname2) fullAddress += ` ${data.bname2}`;
    onChange(data.bname);
    handleClose();
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    if (open) {
      setBackPressOn(false);
    } else {
      setBackPressOn(true);
    }
  }, [open]);

  return (
    <>
      <div
        role={"presentation"}
        className="field-group__cont"
        onClick={handleOpen}
      >
        <i className="icon i-search" />
        <input
          type="text"
          value={value}
          onChange={(e) => onChange(e.target.value)}
          className="field-group__input"
          placeholder="* 지번/도로명 입력"
        />
      </div>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <CloseIcon
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 12,
              top: 10,
            }}
          />
          <DaumPostcode
            onComplete={handleComplete}
            autoClose
            style={{ height: "100%" }}
          />
        </Box>
      </Modal>
    </>
  );
};

export default AddressSearch;
