import { useInfiniteQuery } from "react-query";
import {
  CourseListQueryParamsType,
  CourseListType,
} from "../../types/courseManagement/courseManagementTypes";
import CourseListApi from "../../services/courseManagement/CourseManagementApi";

const useCourseListInfiniteQuery = (
  params: Omit<CourseListQueryParamsType, "page"> | undefined,
) => {
  return useInfiniteQuery<CourseListType, Error>({
    queryKey: ["courseList", params],
    queryFn: ({ pageParam = 0 }) =>
      CourseListApi.fetchCourseList({ ...params, page: pageParam }),
    getNextPageParam: (lastFetchedPage, allFetchedPages) => {
      const currentPageIndex = allFetchedPages.length;
      if (currentPageIndex < lastFetchedPage.totalPages) {
        return currentPageIndex;
      }
      return undefined;
    },
    onSuccess: (data) => {
      console.log("Query successful:", data);
    },
    onError: (error) => {
      console.error("Query error:", error);
    },
  });
};

export default useCourseListInfiniteQuery;
