function PartnerTutorSearchForm({
  searchName,
  onClickSearchName,
  setSearchName,
  handleKeyDown,
}: {
  searchName: string;
  onClickSearchName: () => void;
  handleKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  setSearchName: React.Dispatch<React.SetStateAction<string>>;
}) {
  return (
    <div className="field-group">
      <label className="field-group__label field-group__label--blind">
        이름 검색
      </label>
      <div className="field-group__cont">
        <p role={"presentation"} onClick={onClickSearchName}>
          <i className="icon i-search" />
        </p>
        <input
          type="text"
          className="field-group__input"
          placeholder="이름 검색"
          value={searchName}
          onChange={(e) => {
            setSearchName(e.target.value);
          }}
          onKeyDown={handleKeyDown}
        />
        <span
          className="field-group__clear"
          role={"presentation"}
          onClick={() => {
            setSearchName("");
          }}
        />
      </div>
    </div>
  );
}

export default PartnerTutorSearchForm;
