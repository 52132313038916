import { useState, useRef, useEffect } from "react";

interface ButtonDropDownProps {
  buttonType?: string;
  buttonText?: string;
  buttonIcon?: string;
  items: { text: string; onClick?: () => void }[];
}

const ButtonDropDown = ({
  buttonType,
  buttonText,
  buttonIcon,
  items,
}: ButtonDropDownProps) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const selectBoxRef = useRef<HTMLDivElement>(null);

  const toggleSelectBox = () => {
    setExpanded(!expanded);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      selectBoxRef.current &&
      !selectBoxRef.current.contains(event.target as Node)
    ) {
      setExpanded(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div
      className="button-dropdown"
      role={"presentation"}
      onClick={(e) => e.stopPropagation()}
    >
      <div
        role={"presentation"}
        className={`button-dropdown__inner ${expanded ? "expanded" : ""}`}
        onClick={toggleSelectBox}
        ref={selectBoxRef}
      >
        <div
          className={`button-dropdown__label ${buttonType === "filter" ? "typo-small" : ""}`}
        >
          {buttonText && <span>{buttonText}</span>}
          {buttonIcon && <i className={`icon ${buttonIcon}`} />}
        </div>

        <ul className="button-dropdown__list">
          {items.map((item, index) => (
            <li
              key={index}
              role={"presentation"}
              className="button-dropdown__item"
              onClick={item.onClick}
            >
              {item.text}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default ButtonDropDown;
