import { useRecoilValue } from "recoil";
import { useEffect, useRef } from "react";
import { userAtom } from "../../../store/common/userStore";
import useCourseListInfiniteQuery from "../../../hooks/courseManagement/useCourseListInfiniteQuery";
import CourseCard from "../../common/card/CourseCard";
import { CourseType } from "../../../types/courseManagement/courseManagementTypes";
import { selectedPartnerInfoAtom } from "../../../store/profile/selectedPartnerInfoStore";

function CompletedCourseTabContainer() {
  const user = useRecoilValue(userAtom);
  const selectedPartnerInfo = useRecoilValue(selectedPartnerInfoAtom);
  const queryParams = {
    itemsPerPage: "10",
    sortBy: "",
    sortColumn: "",
    status: "CLOSE",
    memberCode: String(user?.member?.id),
    tutorCode: String(selectedPartnerInfo.tutorCode),
  };

  const { data, fetchNextPage, hasNextPage } =
    useCourseListInfiniteQuery(queryParams);
  const observerElem = useRef(null);

  useEffect(() => {
    const currentObserverElem = observerElem.current;

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && hasNextPage) {
          fetchNextPage();
        }
      },
      { threshold: 1.0 },
    );

    if (currentObserverElem) {
      observer.observe(currentObserverElem);
    }

    return () => {
      if (currentObserverElem) {
        observer.unobserve(currentObserverElem);
      }
    };
  }, [hasNextPage, fetchNextPage]);

  return (
    <>
      {data && data?.pages[0]?.items?.length > 0 ? (
        <main className="content">
          <div className="section">
            <div className="tutoring-list">
              {data?.pages.map((page, pageIndex) =>
                page.items.map((course: CourseType, index: number) => (
                  <CourseCard
                    cardtype={"completed"}
                    data={course}
                    key={`completed-course-${pageIndex}-${index}`}
                  />
                )),
              )}
            </div>
            <div ref={observerElem} />
          </div>
        </main>
      ) : (
        <main className="content">
          <div className="page-empty">과외가 없습니다.</div>
        </main>
      )}
    </>
  );
}

export default CompletedCourseTabContainer;
