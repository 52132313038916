import { useEffect, useRef, useState } from "react";
import { useRecoilValue } from "recoil";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { userAtom } from "../../../../store/common/userStore";
import useMemberInfoQuery from "../../../../hooks/member/useMemberInfoQuery";
import useUpdatePasswordMutation from "../../../../hooks/auth/useUpdatePasswordMutation";
import useSendMobileAuthCodeMutation from "../../../../hooks/auth/useSendMobileAuthCodeMutation";
import CommonInput from "../../../common/form/CommonInput";
import CommonModalDialog from "../../../common/modal/CommonModalDialog";
import WithdrawForm from "./WithdrawForm";
import CommonModal from "../../../common/modal/CommonModal";
import useMemberMobileUpdateMutation from "../../../../hooks/member/useMemberMobileUpdateMutation";

function MyInfoManagementContainer() {
  const [openUpdateForm, setOpenUpdateForm] = useState(false);
  const methods = useForm({
    mode: "onChange",
  });
  const {
    handleSubmit,
    formState: { errors, isValid },
    getValues,
    watch,
  } = methods;
  const [modalName, setModalName] = useState<string>("");
  const modalClose = () => {
    setModalName("");
  };
  const user = useRecoilValue(userAtom);
  const { data: memberInfo } = useMemberInfoQuery(String(user.member.id));
  const updatePasswordMutation = useUpdatePasswordMutation();
  const memberMobileUpdateMutation =
    useMemberMobileUpdateMutation(setOpenUpdateForm);
  const [timeLeft, setTimeLeft] = useState<number | null>(null);
  const sendMobileAuthCodeMutation = useSendMobileAuthCodeMutation(
    setModalName,
    setTimeLeft,
  );
  const authCodeInputRef = useRef<HTMLInputElement | null>(null);

  const onSubmit = (formData: any) => {
    updatePasswordMutation.mutate({
      newPassword: formData.password,
      confirmPassword: formData.confirmPassword,
      memberType: user.member.memberType,
    });
  };

  useEffect(() => {
    if (timeLeft === null) return;
    if (timeLeft <= 0) {
      setTimeLeft(null);
      return;
    }

    const timer = setInterval(() => {
      setTimeLeft((prev) => (prev !== null ? prev - 1 : null));
    }, 1000);

    return () => clearInterval(timer);
  }, [timeLeft]);

  const formatTime = (seconds: number) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins}:${secs < 10 ? `0${secs}` : secs}`;
  };

  const onClickMobileAuth = () => {
    console.log(memberInfo?.member.mobile);
    console.log(getValues("mobile"));
    if (!/^[0-9]{10,11}$/.test(getValues("mobile"))) {
      setModalName("notMobilePatten");
    }
    if (memberInfo?.member.mobile === getValues("mobile")) {
      setModalName("sameMobile");
    } else {
      sendMobileAuthCodeMutation.mutate({
        mobile: getValues("mobile"),
      });
      // mutate Success시 setModalName, setTimeLeft
    }
  };

  const onClickMobileUpdate = () => {
    memberMobileUpdateMutation.mutate({
      mobileModifyNumber: getValues("mobile"),
      memberType: user.member.memberType,
      certNum: getValues("certNum"),
    });
  };

  const handleConfirm = () => {
    if (authCodeInputRef.current) {
      authCodeInputRef.current.focus();
    }
    modalClose();
  };

  return (
    <main className="content">
      <div className="section pb5">
        <div className="profile-group flex-box flex-box--items-center flex-box--justify-between flex-box--gap2">
          <div className="profile-group__head mb0">
            <div className="profile-group__label typo-small">이름</div>
          </div>
          <div className="profile-group__cont">
            <div className="profile-group__text">
              <div>
                <p>{memberInfo?.member.name}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="profile-group flex-box flex-box--items-center flex-box--justify-between flex-box--gap2">
          <div className="profile-group__head mb0">
            <div className="profile-group__label typo-small">
              아이디(이메일)
            </div>
          </div>
          <div className="profile-group__cont">
            <div className="profile-group__text">
              <div>
                <p>{memberInfo?.member.email}</p>
              </div>
            </div>
          </div>
        </div>

        {openUpdateForm ? (
          <div className="profile-group">
            <div className="profile-group__cont">
              <div className="field-group flex-box flex-box--gap1 mt0">
                <label className="field-group__label field-group__label--blind">
                  휴대폰 번호
                </label>
                <Controller
                  name="mobile"
                  control={methods.control}
                  rules={{
                    required: "휴대폰번호는 필수 항목입니다.",
                    pattern: {
                      value: /^[0-9]{10,11}$/,
                      message: "휴대폰번호 형식이 잘못되었습니다.",
                    },
                  }}
                  render={({ field }) => (
                    <CommonInput placeholder="숫자만 입력" field={field} />
                  )}
                />
                <button
                  onClick={() => {
                    setOpenUpdateForm(false);
                  }}
                  className="btn btn--gray mr2"
                  type="button"
                >
                  취소
                </button>
              </div>
              <div className="field-group mt2">
                <span className="field-group__label field-group__label--blind">
                  인증번호
                </span>
                <button
                  className={`btn btn--full btn--brown ${!watch("mobile") ? "is-disabled" : ""}`}
                  type="button"
                  onClick={onClickMobileAuth}
                >
                  인증번호 요청
                </button>
                <span className="field-group__help">
                  휴대폰 인증 후 수정할 수 있습니다.
                </span>
                <div className="field-group__cont mt4">
                  <Controller
                    name="certNum"
                    control={methods.control}
                    rules={{
                      validate: (value) => value?.length === 6,
                    }}
                    render={({ field }) => (
                      <input
                        {...field}
                        ref={(e) => {
                          field.ref(e);
                          authCodeInputRef.current = e;
                        }}
                        className="field-group__input"
                        style={{ backgroundColor: "#f9f9f9" }}
                        placeholder="6자리 입력"
                        value={field.value || ""}
                        maxLength={6}
                      />
                    )}
                  />
                  <button
                    className="btn btn--small btn--brown mr2"
                    type="button"
                    onClick={onClickMobileUpdate}
                  >
                    인증
                  </button>
                </div>
                {timeLeft !== null && (
                  <span className="field-group__help">
                    인증 유효시간{" "}
                    <em className="color-red">{formatTime(timeLeft)}</em>
                  </span>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className="profile-group flex-box flex-box--items-center flex-box--justify-between flex-box--gap2">
            <div className="profile-group__head mb0">
              <div className="profile-group__label typo-small">휴대폰번호</div>
            </div>
            <div className="profile-group__cont">
              <div className="profile-group__text profile-group__text--items-center">
                <div>
                  <p>{memberInfo?.member.mobile}</p>
                </div>
                <button
                  onClick={() => {
                    setOpenUpdateForm(true);
                  }}
                  className="btn btn--small btn--bordered-base ml3"
                >
                  수정
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="divider" />

      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} style={{ display: "contents" }}>
          <div className="section pb5">
            <div className="section__title typo-small">비밀번호 변경</div>

            <div className="field-group mt2">
              <label
                htmlFor=""
                className="field-group__label typo-small2 color-gray-700"
              >
                새 비밀번호
              </label>
              <Controller
                name="password"
                control={methods.control}
                rules={{
                  required: "비밀번호는 필수 항목입니다.",
                  pattern: {
                    value:
                      /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?])[a-zA-Z0-9!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]{8,20}$/,
                    message:
                      "영문/숫자/특수문자 3가지 조합(8~20자)으로 입력해주세요.",
                  },
                }}
                render={({ field }) => (
                  <CommonInput
                    placeholder="비밀번호를 입력해주세요"
                    field={field}
                    type="password"
                    error={errors.password?.message}
                  />
                )}
              />
            </div>

            <div className="field-group mt2">
              <label
                htmlFor=""
                className="field-group__label typo-small2 color-gray-700"
              >
                새 비밀번호 확인
              </label>
              <Controller
                name="confirmPassword"
                control={methods.control}
                rules={{
                  required: "비밀번호 확인은 필수 항목입니다.",
                  validate: (value) =>
                    value === getValues("password") ||
                    "비밀번호와 비밀번호 확인의 번호가 일치하지 않습니다.",
                }}
                render={({ field }) => (
                  <CommonInput
                    placeholder="비밀번호를 다시 입력해주세요"
                    field={field}
                    type="password"
                    error={errors.confirmPassword?.message}
                  />
                )}
              />
            </div>

            <div className="field-group">
              <button
                className={`btn btn--full btn--primary ${
                  !isValid ? "is-disabled" : ""
                }`}
                type="submit"
              >
                비밀번호 변경
              </button>
            </div>
          </div>
        </form>
      </FormProvider>

      <div
        role={"presentation"}
        className="flex-box flex-box--items-center flex-box--justify-center pt8"
        onClick={() => {
          setModalName("withdraw");
        }}
      >
        <p className="text-btn text-btn--large text-btn--underline">
          회원 탈퇴 신청
        </p>
      </div>

      {modalName === "withdraw" && (
        <CommonModalDialog setModalName={setModalName}>
          {({ clickClose }) => <WithdrawForm clickClose={clickClose} />}
        </CommonModalDialog>
      )}
      <CommonModal
        open={modalName === "mobileAuth"}
        modalClose={modalClose}
        text={"인증 번호를 휴대폰으로 발송하였습니다"}
        onConfirm={handleConfirm}
        noCancelButton
      />
      <CommonModal
        open={modalName === "notMobilePatten"}
        modalClose={modalClose}
        text={"휴대폰번호 형식이 맞지 않습니다"}
        onConfirm={handleConfirm}
        noCancelButton
      />
      <CommonModal
        open={modalName === "sameMobile"}
        modalClose={modalClose}
        text={"현재 사용중이신 휴대폰번호 입니다"}
        onConfirm={handleConfirm}
        noCancelButton
      />
    </main>
  );
}

export default MyInfoManagementContainer;
