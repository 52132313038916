import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { setBackPressOn } from "../../../gnote_api";

function CommonModalDialog({
  setModalName,
  children,
}: {
  setModalName: React.Dispatch<React.SetStateAction<string>>;
  children: (props: { clickClose: () => void }) => React.ReactNode;
}) {
  const [isOpen, setIsOpen] = useState<boolean>(true);

  const handleAnimationComplete = () => {
    if (!isOpen) {
      setModalName("");
      document.body.style.overflow = "auto"; // 모달이 닫힐 때 스크롤 복원
    } else {
      document.body.style.overflow = "hidden"; // 모달이 열릴 때 스크롤 막기
    }
  };
  const clickClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    setBackPressOn(false);
    return () => {
      setBackPressOn(true);
    };
  }, []);

  return (
    <div className="modal-dialog-custom">
      <motion.div
        className="modal-dialog__container"
        initial={{ y: "100%" }}
        animate={{ y: isOpen ? "0%" : "100%" }}
        transition={{ type: "tween", duration: 0.2 }}
        onAnimationComplete={handleAnimationComplete}
      >
        {children({ clickClose })}
      </motion.div>
    </div>
  );
}

export default CommonModalDialog;
