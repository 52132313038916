import { Controller, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import CommonTextArea from "../../../../common/form/CommonTextArea";
import AddressSearch from "../../../../common/modal/AdressSearchModal";
import useProfileListUpdateMutation from "../../../../../hooks/profile/useProfileListUpdateMutation";
import { ProfileData } from "../../../../../types/profile/profileListTypes";

function ProfileCommonEditContainer({
  clickClose,
  title,
  placeholder,
  fields,
}: {
  clickClose: () => void;
  title: string;
  placeholder: string;
  fields: string[];
}) {
  const {
    handleSubmit,
    control,
    formState: { isValid },
  } = useForm();

  const queryClient = useQueryClient();
  const profileData = queryClient.getQueryData<ProfileData>("profileCopyList");
  const mutation = useProfileListUpdateMutation(clickClose);

  function camelToKebab(str: string): string {
    return str.replace(/([a-z])([A-Z])/g, "$1-$2").toLowerCase();
  }

  const onSubmit = async (formData: any) => {
    const formDataToSend = new FormData();

    if (fields[0] === "address") {
      formDataToSend.append(fields[1], "Y");
    } else if (fields[0] === "possibleSchedule") {
      formDataToSend.append(fields[1], "Y");
    } else if (fields[0] === "averagePay") {
      formDataToSend.append(fields[1], "Y");
    } else if (fields[0] === "contactAddress") {
      formDataToSend.append(fields[1], "Y");
    }

    formDataToSend.append(fields[0], formData?.[fields[0]]);
    mutation.mutate({
      params: formDataToSend,
      endPoint: camelToKebab(fields[0]),
    });
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      style={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div className="modal-dialog__header">
        <span className="modal-dialog__title">{title}</span>
        <button
          onClick={clickClose}
          type="button"
          className="modal-dialog__close"
        >
          <i className="icon i-close" />
        </button>
      </div>

      <div className="modal-dialog__body">
        {/* 거주지 모달 일 때 주소 검색 폼 렌더링 */}
        {fields[0] === "address" && (
          <Controller
            name="address"
            control={control}
            rules={{ required: true }}
            defaultValue={""}
            render={({ field }) => (
              <AddressSearch value={field.value} onChange={field.onChange} />
            )}
          />
        )}

        {/* 거주지 제외 다른 모달들은 공통으로 CommonTextArea 렌더링 */}
        {fields[0] !== "address" && (
          <div className="field-group2 field-group2--no-border">
            <div className="field-group2__cont">
              <Controller
                name={fields[0]}
                control={control}
                defaultValue={profileData?.[fields[0] as keyof ProfileData]}
                rules={{ required: true }}
                render={({ field }) => (
                  <CommonTextArea
                    value={field.value}
                    onChange={field.onChange}
                    placeholder={placeholder}
                  />
                )}
              />
            </div>
          </div>
        )}
      </div>
      <div className="modal-dialog__footer">
        <button
          type="button"
          className="btn btn--full btn--gray"
          onClick={clickClose}
        >
          취소
        </button>
        <button
          disabled={!isValid}
          type="submit"
          className={`btn btn--full ${!isValid ? "is-disabled" : "btn--primary"}`}
        >
          저장하기
        </button>
      </div>
    </form>
  );
}

export default ProfileCommonEditContainer;
