function CheckBoxGroup({
  checkBoxOption,
  subTitle,
  selectedGroups,
  setSelectedGroups,
  addClass,
}: {
  checkBoxOption: { [key: string]: string };
  subTitle: string;
  selectedGroups: string[];
  setSelectedGroups: React.Dispatch<React.SetStateAction<string[]>>;
  addClass?: string;
}) {
  const handleCheckBoxChange = (value: string) => {
    setSelectedGroups(
      (prev) =>
        prev.includes(value)
          ? prev.filter((v) => v !== value) // 이미 체크된 값이면 해제
          : [...prev, value], // 새롭게 체크된 값 추가
    );
  };
  return (
    <>
      {Object.keys(checkBoxOption).length > 0 && (
        <div className={`field-group ${addClass}`}>
          <span className="field-group__label">{subTitle}</span>
          <div className="field-group__cont field-group__cont--no-style">
            {Object.keys(checkBoxOption).map((key) => (
              <div className="checkbox" key={key}>
                <label className="checkbox__wrapper">
                  <span className="checkbox__title checkbox__title--large">
                    {checkBoxOption[key]}
                  </span>
                  <input
                    type="checkbox"
                    className="checkbox--hidden checkbox--primary"
                    value={key}
                    checked={selectedGroups.includes(key)}
                    onChange={() => handleCheckBoxChange(key)}
                  />
                  <div className="checkbox--show" />
                </label>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
}

export default CheckBoxGroup;
