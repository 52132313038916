import { useEffect, useRef, useState } from "react";
import { useRecoilValue } from "recoil";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { userAtom } from "../../../store/common/userStore";
import useEmailSearchQuery from "../../../hooks/auth/useEmailSearchQuery";
import CommonModal from "../../common/modal/CommonModal";
import maskEmail from "../../../util/util";
import CommonInput from "../../common/form/CommonInput";
import useSendMobileAuthCodeMutation from "../../../hooks/auth/useSendMobileAuthCodeMutation";

function FindEmailTab() {
  const methods = useForm({
    mode: "onChange",
  });
  const user = useRecoilValue(userAtom);
  const [modalName, setModalName] = useState("");
  const modalClose = () => {
    setModalName("");
  };
  const [timeLeft, setTimeLeft] = useState<number | null>(null);
  const authCodeInputRef = useRef<HTMLInputElement | null>(null);
  const sendMobileAuthCodeMutation = useSendMobileAuthCodeMutation(
    setModalName,
    setTimeLeft,
  );
  // 이메일 인증번호 검증이 완료 됐는지
  const [isAuth, setIsAuth] = useState(false);
  const navigate = useNavigate();

  const {
    handleSubmit,
    formState: { errors, isValid },
    getValues,
    watch,
  } = methods;

  const { data: searchEmailInfo, refetch } = useEmailSearchQuery(
    {
      mobile: getValues("mobile"),
      memberType: user.member.memberType || "BUSINESS",
      certNum: getValues("certNum"),
    },
    setIsAuth,
  );

  const handleConfirm = () => {
    if (authCodeInputRef.current) {
      authCodeInputRef.current.focus();
    }
    modalClose();
  };

  const onSubmit = () => {
    refetch();
  };

  useEffect(() => {
    if (timeLeft === null) return;
    if (timeLeft <= 0) {
      setTimeLeft(null);
      return;
    }

    const timer = setInterval(() => {
      setTimeLeft((prev) => (prev !== null ? prev - 1 : null));
    }, 1000);

    return () => clearInterval(timer);
  }, [timeLeft]);

  const formatTime = (seconds: number) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins}:${secs < 10 ? `0${secs}` : secs}`;
  };

  const onClickMobileAuth = () => {
    sendMobileAuthCodeMutation.mutate({
      mobile: getValues("mobile"),
    });
    // mutate Success시 setModalName, setTimeLeft
  };

  return (
    <main className="content pb0">
      {!isAuth ? (
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="section pb16">
              <p className="typo-large strong mt7">
                휴대폰번호를 인증하고 이메일을 찾을 수 있습니다.
              </p>

              <div className="field-group">
                <label className="field-group__label">휴대폰번호</label>
                <Controller
                  name="mobile"
                  control={methods.control}
                  rules={{
                    required: "휴대폰번호는 필수 항목입니다.",
                    pattern: {
                      value: /^[0-9]{10,11}$/,
                      message: "휴대폰번호 형식이 잘못되었습니다.",
                    },
                  }}
                  render={({ field }) => (
                    <CommonInput
                      placeholder="숫자만 입력"
                      field={field}
                      error={errors.mobileNumber?.message}
                    />
                  )}
                />
              </div>

              <div className="field-group">
                <button
                  className={`btn btn--full btn--brown ${!watch("mobile") || errors.mobile ? "is-disabled" : ""}`}
                  type="button"
                  onClick={onClickMobileAuth}
                >
                  인증번호 요청
                </button>
                <span className="field-group__label typo-small2 mt4 mb2">
                  인증번호
                </span>
                <Controller
                  name="certNum"
                  control={methods.control}
                  rules={{
                    validate: (value) => value?.length === 6,
                  }}
                  render={({ field }) => (
                    <input
                      {...field}
                      ref={(e) => {
                        field.ref(e);
                        authCodeInputRef.current = e;
                      }}
                      className="field-group__input"
                      style={{ backgroundColor: "#f9f9f9" }}
                      placeholder="6자리 입력"
                      value={field.value || ""}
                      maxLength={6}
                    />
                  )}
                />
                <span className="field-group__help">
                  인증번호는 발송 후 3분 이내에만 유효합니다.
                </span>
                {timeLeft !== null && (
                  <span className="field-group__help">
                    인증 유효시간{" "}
                    <em className="color-red">{formatTime(timeLeft)}</em>
                  </span>
                )}
                <div
                  className="divider divider--thin mt4"
                  style={{ marginLeft: "0", marginRight: "0" }}
                />
                <span className="field-group__help">
                  <i className="icon i-info mr1" />
                  인증문자가 발송되지 않을 시 스팸으로 분류되어 있는지
                  확인바랍니다.
                </span>
              </div>
            </div>
            <div className="content-footer">
              <button
                id="btn-submit"
                className={`btn btn--full btn--primary ${
                  !isValid ? "is-disabled" : ""
                }`}
                type="submit"
              >
                이메일 확인
              </button>
            </div>
            <CommonModal
              open={modalName === "mobileAuth"}
              modalClose={modalClose}
              text={"인증 번호를 휴대폰으로 발송하였습니다"}
              onConfirm={handleConfirm}
              noCancelButton
            />
          </form>
        </FormProvider>
      ) : (
        <>
          <div className="section flex-box flex-box--direction-column flex-box--items-center mt7">
            <p className="typo-small mb7">회원님의 이메일(아이디) 입니다.</p>
            <div className="text-box text-box--rounded text-center">
              <p className="typo-large strong color-black py1">
                {maskEmail(searchEmailInfo?.email)}
              </p>
            </div>
          </div>
          <div className="content-footer">
            <button
              id="btn-submit"
              className="btn btn--full btn--primary"
              type="button"
              onClick={() => {
                navigate("/login");
              }}
            >
              로그인
            </button>
          </div>
        </>
      )}
    </main>
  );
}
export default FindEmailTab;
