import React, { useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import moment from "moment/moment";
import KeyboardArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardArrowLeftOutlined";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";

const StudyCalendarToolbar: React.FC<{
  calendarRef: React.RefObject<FullCalendar>;
  setSelectedDate: React.Dispatch<React.SetStateAction<string>>;
  setCurrentMonth: React.Dispatch<React.SetStateAction<string>>;
  currentMonth: string;
}> = ({ calendarRef, setSelectedDate, setCurrentMonth, currentMonth }) => {
  const [currentDate, setCurrentDate] = useState(
    moment().format("YYYY년 MM월"),
  );

  useEffect(() => {
    const calendarApi = calendarRef.current?.getApi();
    if (calendarApi) {
      setCurrentDate(moment(calendarApi.getDate()).format("YYYY년 MM월"));
    }
  }, [calendarRef, currentMonth]);

  const handleClickToolbar = (action: string) => {
    const calendarApi = calendarRef.current?.getApi();
    if (!calendarApi) return;

    switch (action) {
      case "prev":
        calendarApi.prev();
        break;
      case "next":
        calendarApi.next();
        break;
      default:
        calendarApi.today();
        setSelectedDate(moment().format("YYYY-MM-DD"));
        break;
    }

    const newDate = moment(calendarApi.getDate());
    setCurrentMonth(newDate.format("YYYY-MM"));
    setCurrentDate(newDate.format("YYYY년 MM월"));
  };

  return (
    <div
      style={{
        display: "flex",
        padding: "5px 0px 25px 0px",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <div style={{ flex: "0.8" }} /> {/* 왼쪽 여백을 위한 빈 div */}
      <div
        style={{
          display: "flex",
          flex: "1.4",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          role="presentation"
          onClick={() => {
            handleClickToolbar("prev");
          }}
        >
          <KeyboardArrowLeftOutlinedIcon
            style={{
              color: "gray",
              transform: "scaleY(1.3) scaleX(1.2)",
              display: "flex",
            }}
          />
        </div>
        <span style={{ margin: "auto" }}>{currentDate}</span>
        <div
          role="presentation"
          onClick={() => {
            handleClickToolbar("next");
          }}
        >
          <KeyboardArrowRightOutlinedIcon
            style={{
              color: "gray",
              transform: "scaleY(1.3) scaleX(1.2)",
              display: "flex",
            }}
          />
        </div>
      </div>
      <div
        role="presentation"
        style={{
          flex: "0.8",
          textAlign: "right",
        }}
        onClick={() => {
          handleClickToolbar("today");
        }}
      >
        today
      </div>
    </div>
  );
};

export default StudyCalendarToolbar;
