import { useQuery, UseQueryResult } from "react-query";
import StudyCalendarListApi from "../../services/studyCalendar/StudyCalendarListApi";
import { StudyCalendarListType } from "../../types/studyCalendar/studyCalendarTypes";

const useStudyCalendarListQuery = ({
  params,
  endPoint,
}: {
  params: Record<string, unknown>;
  endPoint: string;
}): UseQueryResult<StudyCalendarListType, Error> => {
  return useQuery<StudyCalendarListType, Error>({
    queryKey: ["studyCalendarList", params],
    queryFn: () =>
      StudyCalendarListApi.fetchStudyCalendarList(params, endPoint),
    onSuccess: (data: StudyCalendarListType) => {
      console.log("Query successful:", data);
    },
    onError: (error) => {
      console.error("Query error:", error);
    },
  });
};

export default useStudyCalendarListQuery;
