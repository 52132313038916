import { Outlet, useLocation, useNavigate } from "react-router-dom";

function FindEmailPasswordContainer() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  return (
    <>
      <div className="local-nav-bar">
        <ul className="local-nav-bar__group">
          <li
            className={`local-nav-bar__item local-nav-bar__link ${pathname === "/find-login-info/email" ? "local-nav-bar__link--active" : ""}`}
            role="presentation"
            onClick={() => {
              navigate("/find-login-info/email");
            }}
          >
            이메일 찾기
          </li>
          <li
            className={`local-nav-bar__item local-nav-bar__link ${["/find-login-info/password", "/find-login-info/password-reset"].includes(pathname) ? "local-nav-bar__link--active" : ""}`}
            role="presentation"
            onClick={() => {
              navigate("/find-login-info/password");
            }}
          >
            비밀번호 찾기
          </li>
        </ul>
      </div>
      <Outlet />
    </>
  );
}
export default FindEmailPasswordContainer;
