import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import { AddUpdateCourseRequestQueryParamsType } from "../../types/requestedCourse/requestedCourseTypes";
import RequestedCourseListApi from "../../services/requestedCourse/RequestedCourseApi";

const useAddCourseRequestMutation = () => {
  const navigate = useNavigate();

  return useMutation(
    (params: AddUpdateCourseRequestQueryParamsType) => {
      return RequestedCourseListApi.fetchAddCourseRequest({ ...params });
    },
    {
      onSuccess: (data) => {
        console.log("Mutation successful:", data);
        navigate("/requested-course");
        window.scrollTo(0, 0);
        enqueueSnackbar("과외 요청이 완료되었습니다.");
      },
      onError: (error) => {
        console.error("Mutation error:", error);
      },
    },
  );
};

export default useAddCourseRequestMutation;
