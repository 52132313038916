import React, { useState, useEffect, SetStateAction } from "react";
import { useRecoilValue } from "recoil";
import usePartnerTutorSearchListQuery from "../../../hooks/partnerTutor/usePartnerTutorSearchListQuery";
import { PartnerTutorSearchType } from "../../../types/partnerTutor/partnerTutorTypes";
import { maskMobileNumber } from "../../../util/util";
import { userAtom } from "../../../store/common/userStore";

const CommonSearchForm = ({
  setSearchModalName,
  setValue,
  searchType,
}: {
  setSearchModalName: React.Dispatch<React.SetStateAction<boolean>>;
  setValue: React.Dispatch<
    SetStateAction<{ id: number; name: string; mobile: string }>
  >;
  searchType: string;
}) => {
  const [inputValue, setInputValue] = useState<string>("");
  const [filteredData, setFilteredData] = useState<PartnerTutorSearchType[]>(
    [],
  );
  const user = useRecoilValue(userAtom);

  const [queryParams, setQueryParams] = useState({
    searchName: "",
  });

  const handleInputChange = (e: { target: { value: string } }) => {
    setInputValue(e.target.value);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      setQueryParams((prev) => ({
        ...prev,
        searchData: inputValue,
      }));
      e.preventDefault();
    }
  };
  const handleListClick = (
    item: React.SetStateAction<{ id: number; name: string; mobile: string }>,
  ) => {
    setValue(item);
    setSearchModalName(false);
  };

  const { data } = usePartnerTutorSearchListQuery({
    searchName: queryParams.searchName,
    businessCode: String(user.member.id),
    searchType,
  });

  useEffect(() => {
    if (inputValue !== "") {
      if (data && data.items) {
        let filteredItems: PartnerTutorSearchType[] = data.items;

        filteredItems = filteredItems.filter((item) =>
          item.name.includes(inputValue),
        );

        setFilteredData(filteredItems);
      }
    }
  }, [inputValue, data, queryParams.searchName]);

  return (
    <div className="overlayContainer">
      <div className="overlay-search" style={{ display: "flex" }}>
        <div className="overlay-search__content">
          <div className="overlay-search__head">
            <button
              className="closeButton"
              onClick={() => {
                setSearchModalName(false);
              }}
            >
              <i className="icon i-arrow-back" />
            </button>
            <input
              type="text"
              id="searchInput"
              value={inputValue}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              className="overlay-search__input"
              placeholder="Search..."
            />
          </div>
          <div className="overlay-search__body">
            {filteredData.length > 0 ? (
              <ul className="overlay-results-list">
                {filteredData.map(
                  (item: PartnerTutorSearchType, index: number) => (
                    <li
                      role={"presentation"}
                      onClick={() => {
                        handleListClick(item);
                      }}
                      key={`data-${index}`}
                    >
                      {item.name} {maskMobileNumber(item.mobile)}
                    </li>
                  ),
                )}
              </ul>
            ) : (
              <div className="msg-ly my4 mx4">
                <p className="msg-ly__text color-gray-700">
                  검색 결과가 없습니다.
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommonSearchForm;
