import { useMutation, useQueryClient } from "react-query";
import { enqueueSnackbar } from "notistack";
import ProfileApi from "../../services/profile/ProfileApi";

const useProfileTransferMutation = (modalClose: () => void) => {
  const queryClient = useQueryClient();

  return useMutation(
    (params: {
      profileType: string;
      tutorCode: string;
      businessCode: string | null;
      comment: string;
      phone: string;
      url: string;
    }) => {
      return ProfileApi.fetchProfileTransfer({ ...params });
    },
    {
      onSuccess: (data) => {
        console.log("Mutation successful:", data);
        queryClient.invalidateQueries("profileList");
        modalClose();
        enqueueSnackbar("전송이 완료되었습니다.");
      },
      onError: (error) => {
        console.error("Mutation error:", error);
      },
    },
  );
};

export default useProfileTransferMutation;
