import { useForm } from "react-hook-form";
import { ChangeEvent, useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { userAtom } from "../../../../../store/common/userStore";
import useBoardAddMutation from "../../../../../hooks/myInfo/board/useBoardAddMutation";
import CommonModal from "../../../../common/modal/CommonModal";

function QuestionAddForm() {
  const user = useRecoilValue(userAtom);
  const { register, handleSubmit, watch } = useForm();
  const watchContent = watch("content", "");
  const [images, setImages] = useState<string[]>([]);
  const [modalName, setModalName] = useState("");
  const modalClose = () => {
    setModalName("");
  };
  const questionAddMutation = useBoardAddMutation(setModalName);
  const [isValid, setIsValid] = useState(false);

  const onImageUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    if (images.length > 2) return;
    const { files } = e.target;
    if (!files || files.length === 0) return;

    const file: File = files[0];
    const reader: FileReader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => {
      if (reader.result) {
        setImages((prev) => [...prev, reader.result as string]);
      }
      e.target.value = "";
    };
  };

  const onSubmit = async (data: any) => {
    const formDataToSend = new FormData();
    formDataToSend.append("boardTitle", "");
    formDataToSend.append("boardContent", data.content);
    formDataToSend.append("boardKind", "QNA");
    formDataToSend.append("memberType", user.member.memberType);
    formDataToSend.append("createdMemberCode", String(user.member.id));

    // 이미지 블롭을 병렬로 처리
    const imagePromises = images.map(async (image, index) => {
      const imageBlob = await fetch(image).then((res) => res.blob());
      formDataToSend.append("files", imageBlob, `image_${index + 1}.png`);
    });

    // 모든 이미지 처리 완료 후 FormData에 추가
    await Promise.all(imagePromises);
    questionAddMutation.mutate({ params: formDataToSend });
  };

  const onClickDelete = (index: number) => {
    setImages((prevMedia) => prevMedia.filter((_, i) => i !== index));
  };

  const handleConfirm = () => {
    modalClose();
  };

  useEffect(() => {
    if (watchContent) {
      setIsValid(true);
    }
  }, [watchContent]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <main className="content pb0">
        <div className="section pb20">
          <p className="typo-small strong mt5">
            문의글을 작성해주세요.
            <br /> 확인 후 답변 드리겠습니다.
          </p>
          <div className="field-group mt4">
            <label
              htmlFor="phone"
              className="field-group__label field-group__label--blind"
            >
              문의글 작성
            </label>
            <div className="field-group__cont">
              <textarea
                {...register("content", { required: true })}
                id="ip-content"
                className="field-group__textarea"
                placeholder="문의사항을 입력해주세요."
                maxLength={1000}
              />
            </div>
            <span className="field-group__check-characters">
              <em>{watchContent.length}</em> / 1000자
            </span>
          </div>
          <div className="field-group">
            <div className="field-group__cont field-group__cont--no-style">
              <div className="field-group__check-characters text-left">
                <span className="color-black">사진</span>{" "}
                <em>{images.length}</em> / 3
              </div>
              <div className="regi-image regi-image--no-overflow">
                <div className="regi-image__list">
                  <div className="regi-image__item">
                    <label className="regi-image__label">
                      <i className="icon i-add-circle" />
                      <input
                        type="file"
                        className="regi-image__input"
                        accept="image/*"
                        onChange={onImageUpload}
                      />
                    </label>
                  </div>
                  {images.map((item, index) => (
                    <div
                      key={`regi-image__item-${index}`}
                      className="regi-image__item"
                    >
                      <span
                        className="btn-delete"
                        role={"presentation"}
                        onClick={() => {
                          onClickDelete(index);
                        }}
                      >
                        <i className="icon i-close-white" />
                      </span>
                      <img src={item} className="thumb" alt="uploaded" />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="content-footer content-footer--transparent"
          style={{ bottom: "3.375rem" }}
        >
          <button
            id="btn-submit"
            className={`btn btn--full ${!isValid ? "is-disabled" : "btn--primary"}`}
            type="submit"
          >
            문의하기
          </button>
        </div>
      </main>
      <CommonModal
        open={modalName === "add"}
        modalClose={modalClose}
        text={
          "고객님의 소중한 문의가 등록되었습니다. 최대한 빠르게 답변 드리겠습니다."
        }
        noCancelButton
        onConfirm={handleConfirm}
      />
    </form>
  );
}

export default QuestionAddForm;
