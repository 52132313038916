import { useState, useEffect } from "react";
import { useQueryClient } from "react-query";
import CommonCheckChip from "../../../../common/form/CommonCheckChip";
import BottomSheetModal from "../../../../common/modal/BottomSheetModal";
import { introductionOptions } from "../../../../../config/config";
import useProfileListUpdateMutation from "../../../../../hooks/profile/useProfileListUpdateMutation";
import { ProfileListType } from "../../../../../types/profile/profileListTypes";

function ProfileIntroductionEditContainer({
  clickClose,
}: {
  clickClose: () => void;
}) {
  const queryClient = useQueryClient();
  const profileData =
    queryClient.getQueryData<ProfileListType>("profileCopyList");
  const mutation = useProfileListUpdateMutation(clickClose);
  const [directInput, setDirectInput] = useState(() => {
    if (
      profileData?.introduce &&
      !introductionOptions.includes(profileData.introduce)
    ) {
      // introductionOptions에 없는 경우 직접 입력한 값으로 설정
      return profileData.introduce;
    }
    return "직접입력";
  });
  const [selectedOption, setSelectedOption] = useState<string>(() => {
    if (profileData?.introduce) {
      // introductionOptions에 포함되어 있는지 확인
      return introductionOptions.includes(profileData.introduce)
        ? profileData.introduce
        : directInput;
    }
    return "";
  });
  const [modalName, setModalName] = useState<string>();
  const [isValid, setIsValid] = useState(false);

  const handleSelect = (subject: string) => {
    if (subject === directInput) {
      setModalName("directInput");
    } else {
      setSelectedOption(subject);
    }
  };

  const onSubmit = () => {
    console.log(selectedOption === directInput ? directInput : selectedOption);
    const formDataToSend = new FormData();
    formDataToSend.append(
      "introduce",
      selectedOption === directInput ? directInput : selectedOption,
    );
    mutation.mutate({ params: formDataToSend, endPoint: "introduce" });
  };

  // directInput이 변하면 setSelectedOption을 해준다
  useEffect(() => {
    if (directInput !== "직접입력") {
      setSelectedOption(directInput);
    }
  }, [directInput]);

  useEffect(() => {
    if (
      selectedOption !== "" ||
      (directInput !== "" && directInput !== "직접입력")
    ) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [selectedOption, directInput]);

  return (
    <>
      <div className="modal-dialog__header">
        <span className="modal-dialog__title">한줄소개</span>
        <button
          onClick={clickClose}
          type="button"
          className="modal-dialog__close"
        >
          <i className="icon i-close" />
        </button>
      </div>
      <div className="modal-dialog__body">
        <div className="msg-ly">
          <i className="icon i-info" />
          <p className="msg-ly__text">
            원하는 한줄소개를 선택하거나 직접 입력해 주세요.
          </p>
        </div>
        <div className="oneline-intro mt4">
          <div className="oneline-intro__container">
            <div className="radio-chip">
              {introductionOptions.map((item, index) => (
                <CommonCheckChip
                  key={index}
                  chipType="radio"
                  subject={item}
                  onClick={handleSelect}
                  isSelected={selectedOption === item}
                />
              ))}
              <CommonCheckChip
                chipType="radio"
                subject={directInput}
                onClick={handleSelect}
                isSelected={selectedOption === directInput}
                isDirectButton
              />
            </div>
          </div>
          {modalName === "directInput" && (
            <BottomSheetModal
              setDirectInput={(value) => {
                setDirectInput(value);
                setModalName("");
              }}
              title={"한줄소개 직접입력"}
              placeHolder={["최대 30자 입력"]}
              textLimit={30}
              modalName={modalName}
              setModalName={setModalName}
              inputKeys={["subject"]}
            />
          )}
        </div>
      </div>
      <div className="modal-dialog__footer">
        <button
          type="button"
          className="btn btn--full btn--gray"
          onClick={clickClose}
        >
          취소
        </button>
        <button
          onClick={onSubmit}
          type="button"
          disabled={!isValid}
          className={`btn btn--full ${!isValid ? "is-disabled" : "btn--primary"}`}
        >
          저장하기
        </button>
      </div>
    </>
  );
}

export default ProfileIntroductionEditContainer;
