import { InternalAxiosRequestConfig } from "axios";
import { Cookies } from "react-cookie";

export default function AxiosRequestHandler(
  config: InternalAxiosRequestConfig,
): InternalAxiosRequestConfig {
  const cookies = new Cookies();
  const accessToken = cookies.get("accessToken");

  if (!config.headers["Content-Type"]) {
    config.headers["Content-Type"] = "application/json;charset=utf-8";
  }

  if (accessToken) {
    config.headers.Authorization = `${accessToken}`;
  }

  return config;
}
