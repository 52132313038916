import { useRecoilValue } from "recoil";
import { useEffect, useRef } from "react";
import PartnerListContainer from "../PartnerListContainer";
import usePartnerTutorInterestListInfiniteQuery from "../../../../hooks/partnerTutor/interest/usePartnerTutorInterestListQuery";
import { userAtom } from "../../../../store/common/userStore";

function PartnerTutorInterestTab() {
  const user = useRecoilValue(userAtom);
  const { data, fetchNextPage, hasNextPage } =
    usePartnerTutorInterestListInfiniteQuery({
      businessCode: String(user.member.id),
    });
  const observerElem = useRef(null);

  useEffect(() => {
    const currentObserverElem = observerElem.current;

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && hasNextPage) {
          fetchNextPage();
        }
      },
      { threshold: 1.0 },
    );

    if (currentObserverElem) {
      observer.observe(currentObserverElem);
    }

    return () => {
      if (currentObserverElem) {
        observer.unobserve(currentObserverElem);
      }
    };
  }, [hasNextPage, fetchNextPage]);

  const totalItems =
    data?.pages?.reduce((acc, page) => acc + page.items.length, 0) || 0;

  return (
    <>
      {data && data?.pages[0].items.length > 0 ? (
        <>
          <div className="section pt5">
            <div className="sorting">
              <span className="sorting__title">
                <b>{totalItems}</b> 건
              </span>
            </div>

            <PartnerListContainer partnerTutorList={data} />
          </div>
          <div ref={observerElem} />
        </>
      ) : (
        <div className="page-empty">목록이 없습니다.</div>
      )}
    </>
  );
}

export default PartnerTutorInterestTab;
