import { useState } from "react";
import { useRecoilValue } from "recoil";
import { useNavigate } from "react-router-dom";
import { useLoginMutation } from "../../../../hooks/auth/useLoginMutation";
import { userAtom } from "../../../../store/common/userStore";

function MyInfoManagementSecurityCheck() {
  const user = useRecoilValue(userAtom);
  const loginMutation = useLoginMutation("myInfoSecurityCheck");
  const [loginPassword, setLoginPassword] = useState<string>("");
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  // const [passwordError, setPasswordError] = useState<string>("");

  // const validatePassword = (password: string) => {
  //   const passwordRegex =
  //     /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,20}$/;
  //   return passwordRegex.test(password);
  // };

  const handleLogin = () => {
    const isValid = true;

    // if (!validateEmail(loginId)) {
    //   setEmailError("이메일을 올바른 형식으로 입력해주세요.");
    //   isValid = false;
    // } else {
    //   setEmailError("");
    // }
    //
    // if (!validatePassword(loginPassword)) {
    //   setPasswordError(
    //     "영문/숫자/특수문자 3가지 조합(8~20자)으로 입력해주세요.",
    //   );
    //   isValid = false;
    // } else {
    //   setPasswordError("");
    // }

    if (isValid) {
      loginMutation.mutate({ loginId: user.member.loginId, loginPassword });
    }
  };

  return (
    <main className="content">
      <div className="section my-profile">
        <p className="typo-large strong mt7">
          개인정보를 안전하게 보호하기 위하여
          <br /> 비밀번호를 한번 더 입력해주세요.
        </p>
        <div className="field-group">
          <label className="field-group__label field-group__label--blind">
            비밀번호
          </label>
          <div className="field-group__cont">
            <input
              type={`${!showPassword && "password"}`}
              name=""
              id="password"
              className="field-group__input"
              value={loginPassword}
              onChange={(e) => setLoginPassword(e.target.value)}
              placeholder="비밀번호 입력"
            />
            <span
              role={"presentation"}
              className="field-group__clear"
              onClick={() => {
                setLoginPassword("");
              }}
            />
            <button
              type="button"
              className="field-group__pwbtn js-pwToggle"
              onClick={() => {
                setShowPassword((e) => !e);
              }}
            >
              <i
                className={`icon ${showPassword ? "i-visibility-on" : "i-visibility-off"} `}
              />
            </button>
          </div>
          {/* {passwordError && ( */}
          {/*   <span className="field-group__help field-group__help--error"> */}
          {/*     {passwordError} */}
          {/*   </span> */}
          {/* )} */}
        </div>
        <div className="mt4">
          <p
            className="text-btn text-btn--black"
            role={"presentation"}
            onClick={() => {
              navigate("/find-login-info/password");
            }}
          >
            비밀번호를 잊어버렸어요 <i className="icon i-arrow-right" />
          </p>
        </div>
        <div className="field-group">
          <button
            className="btn btn--full btn--brown"
            type="button"
            onClick={handleLogin}
          >
            확인
          </button>
        </div>
      </div>
    </main>
  );
}

export default MyInfoManagementSecurityCheck;
