import { useMutation } from "react-query";
import { useCookies } from "react-cookie";
import axios from "axios";
import { useSetRecoilState } from "recoil";
import { useNavigate } from "react-router-dom";
import { userAtom } from "../../store/common/userStore";
import { LoginQueryResType } from "../../types/auth/authTypes";
import AuthApi from "../../services/auth/AuthApi";
import { checkDeviceType, pushToken } from "../../gnote_api";
import useMemberDeviceTokenUpdateMutation from "../member/useMemberDeviceTokenUpdateMutation";

const fetchLogin = async ({
  loginId,
  loginPassword,
}: {
  loginId: string;
  loginPassword: string;
}): Promise<any> => {
  const baseUrl = AuthApi.getBaseUrl();
  try {
    const response = await axios.post(`${baseUrl}/api/v1/auth/login`, {
      loginId,
      loginPassword,
      memberType: "BUSINESS",
    });

    return response;
  } catch (error) {
    console.error("Login failed:", error);
    throw error;
  }
};

export const useLoginMutation = (type?: string) => {
  const [, setCookie] = useCookies(["accessToken"]);
  const setUser = useSetRecoilState(userAtom);
  const navigate = useNavigate();
  const deviceTokenUpdateMutation = useMemberDeviceTokenUpdateMutation();

  return useMutation(fetchLogin, {
    onSuccess: async (res: LoginQueryResType) => {
      const expiryDate = new Date(res.exp);

      // 쿠키 설정: 만료 시간을 포함하여 지속적 쿠키로 설정
      setCookie("accessToken", res.accessToken, {
        path: "/",
        expires: expiryDate,
      });
      setUser({
        member: {
          id: res.member.id,
          loginId: res.member.loginId,
          name: res.member.name,
          memberType: res.member.memberType,
          memberStatus: res.member.memberStatus,
        },
      });

      // 디바이스 타입 확인 및 Push Token 가져오기
      const deviceType = checkDeviceType();
      let deviceToken: string | null = null;

      if (deviceType === 1 || deviceType === 2) {
        deviceToken = await new Promise((resolve) => {
          (window as any).onMessageListener = (key: string, value: string) => {
            if (key === "pushToken") {
              resolve(value);
            }
          };
          pushToken(); // Push Token 요청
        });

        // 디바이스 토큰이 존재하는 경우에만 업데이트
        if (deviceToken) {
          deviceTokenUpdateMutation.mutate({
            memberCode: String(res.member.id),
            deviceType: deviceType === 1 ? "G" : "A",
            deviceToken,
          });
        }
      }

      const tutorialFlag = localStorage.getItem("tutorialFlag");
      if (!tutorialFlag) {
        navigate("/tutorial");
      } else if (type === "myInfoSecurityCheck") {
        navigate("/my-info/management");
      } else {
        navigate("/home");
      }
    },
    onError: (error) => {
      console.error("Login failed:", error);
    },
  });
};
