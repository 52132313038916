import { useState } from "react";
import CommonModal from "../../../common/modal/CommonModal";
import useMemberWithDrawMutation from "../../../../hooks/member/useMemberWithdrawMutation";

function WithdrawForm({ clickClose }: { clickClose: () => void }) {
  const [selectedOption, setSelectedOption] = useState<string>("");
  const [customReason, setCustomReason] = useState<string>("");
  const [modalName, setModalName] = useState("");
  const modalClose = () => {
    setModalName("");
  };
  const memberWithDrawMutation = useMemberWithDrawMutation(modalClose);

  const handleOptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(e.target.value);
    if (e.target.value !== "custom") {
      setCustomReason(""); // "기타"가 아닌 옵션을 선택하면 customReason을 초기화
    }
  };

  const handleCustomReasonChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    setCustomReason(e.target.value);
    setSelectedOption("custom"); // "기타"를 선택했음을 알리기 위해 설정
  };

  // 버튼 비활성화 조건: selectedOption이 없거나, "custom" 선택 시 customReason이 비어있으면 비활성화
  const isDisabled =
    !selectedOption || (selectedOption === "custom" && !customReason);

  const onSubmit = () => {
    setModalName("withDraw");
  };

  const handleWithdraw = () => {
    memberWithDrawMutation.mutate({
      withDrawCode: `${selectedOption === "custom" ? customReason : selectedOption}`,
      memberType: "BUSINESS",
    });
  };

  return (
    <>
      <div className="modal-dialog__header">
        <span className="modal-dialog__title">회원 탈퇴</span>
        <button
          type="button"
          className="modal-dialog__close"
          onClick={clickClose}
        >
          <i className="icon i-close" />
        </button>
      </div>
      <div className="modal-dialog__body">
        <div className="typo-large strong color-black pt5">
          과외노트 탈퇴 전 꼭 확인해주세요.
        </div>
        <div className="msg-ly mt2">
          <div className="msg-ly__text">
            <ul className="msg-ly__ul">
              <li className="basic">
                회원 탈퇴 시 모든 개인정보가 삭제되며 이후 복구가 불가능합니다.
              </li>
              <li className="basic">
                위 내용을 모두 확인하였고, 탈퇴 완료 시 내용에 동의합니다.
              </li>
            </ul>
          </div>
        </div>
        <div className="typo-large strong color-black pt10">
          더 나은 서비스 제공을 위해 탈퇴 사유를 알려주세요.
        </div>
        <div className="field-group mt0">
          <span className="field-group__label field-group__label--blind">
            탈퇴 사유
          </span>
          <div className="field-group__cont field-group__cont--no-style">
            {/* <RadioBoxGroup */}
            {/*   options={withdrawOptions} */}
            {/*   value={selectedOption} */}
            {/*   onChange={handleOptionChange} */}
            {/*   name={"withdraw"} */}
            {/*   type={"withdraw"} */}
            {/* /> */}
            <div className="radiobox">
              <label className="radiobox__wrapper">
                <span className="radiobox__title radiobox__title--large">
                  기타
                </span>
                <input
                  type="radio"
                  name="withdraw"
                  value="custom"
                  checked={selectedOption === "custom"}
                  onChange={handleOptionChange}
                  className="radiobox--hidden radiobox--primary"
                />
                <div className="radiobox--show" />
              </label>
            </div>
            {selectedOption === "custom" && (
              <div className="field-group2 field-group2--no-border">
                <label className="field-group2__label field-group2__label--blind">
                  탈퇴 사유 직접 입력
                </label>
                <div className="field-group2__cont">
                  <textarea
                    name="customReason"
                    id="customReason"
                    className="field-group2__textarea js-auto-resize"
                    rows={1}
                    placeholder="탈퇴 사유 직접 입력"
                    value={customReason}
                    onChange={handleCustomReasonChange}
                    style={{ height: "47px" }}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="modal-dialog__footer">
        <button
          type="button"
          className={`btn btn--full btn--primary ${isDisabled && "is-disabled"}`}
          disabled={isDisabled}
          onClick={onSubmit}
        >
          탈퇴 완료
        </button>
      </div>

      <CommonModal
        open={modalName === "withDraw"}
        modalClose={modalClose}
        title={"회원 탈퇴"}
        text={"탈퇴를 완료 하시겠습니까?"}
        onConfirm={handleWithdraw}
      />
    </>
  );
}

export default WithdrawForm;
