/**
 **
 **  강의노트 APP Javascript API v1.0 (24.09.26)
 **
 **  Create by Charnseo bahng on 2024.09.26
 **  Copyright 2024 ilark communication co.,ltd. all rights reserved.
 * */

/**
API를 사용하기 전, 리턴값 수신을 위한 메세지 리스너를 아래와 같이 작성해야 한다. 
//Api의 결과를 받는 메세지 리스너.
 function onMessageListener(key, value) {	

 	// PushToken 값을 수신받음
 	if (key == "pushToken") {
		//처리 코드 작성 
 	}
 }

* */

/**
 * 디바이스 타입을 리턴
 * @return 1 android
 *         2 ios
 *         0 other
 * */
export function checkDeviceType() {
  let ret = 0;
  // 안드로이드
  // if( /Android/i.test(navigator.userAgent)) {
  if (/inAppBrowserAndroid/i.test(navigator.userAgent)) {
    ret = 1;
    // iOS 아이폰, 아이패드, 아이팟
    // } else if (/iPhone|iPad|iPod/i.test(navigator.userAgent)  ||
    // (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) /* iPad OS 13 */ {
  } else if (/inAppBrowseriOS/i.test(navigator.userAgent)) {
    ret = 2;
    // 그 외 디바이스
  }
  return ret;
}

/**
 *
 * iOS앱의 API를 호출
 * @param JSObject
 *
 */
export function iOSsendMessage(param) {
  try {
    webkit.messageHandlers.ios.postMessage(param);
  } catch (error) {
    alert(error);
  }
}

/*
 * iOS 단말기의 Push Token 값을 호출한다.
 * 결과값은 onMessageListener(key, value)의 key = 'pushToken'으로 전달받는다.
 */

export function pushTokeniOS() {
  try {
    iOSsendMessage({ method: "pushToken" });
  } catch (error) {
    alert(error);
  }
}

/*
 * Android 단말기의 Push Token 값을 호출한다.
 * @return String pushToken or null
 */
export function pushTokenAndroid() {
  try {
    return window.android.getPushToken();
  } catch (error) {
    alert(error);
  }
  return null;
}

/*
 * Push Token 값을 호출한다.
 * 결과값은 onMessageListener(key, value)의 key = 'pushToken'으로 전달받는다.
 */
export function pushToken() {
  const method = "pushToken";
  switch (checkDeviceType()) {
    // android
    case 1:
      onMessageListener(method, pushTokenAndroid());
      break;
    // ios
    case 2:
      pushTokeniOS();
      break;

    default:
      break;
  }
}

export function getAppVersionAndroid() {
  let ret = "";
  try {
    ret = window.android.getAppVersion();
  } catch (error) {
    alert(error);
  }
  return ret;
}

export function getAppVersioniOS() {
  iOSsendMessage({ method: "getAppVersion" });
}

/*
* 앱 버전을 가져온다

   onMessageListener(key, value)의 함수를 통해서 다음과 같은 값이 전달된다. 
	-	key : “getAppVersion”
	-	value : String type appVersion 
*/
export function getAppVersion() {
  switch (checkDeviceType()) {
    // android
    case 1:
      onMessageListener("getAppVersion", getAppVersionAndroid());
      break;
    // ios
    case 2:
      getAppVersioniOS();
      break;

    default:
      break;
  }
}

/** 
     Contact picker를 실행     
   
	 onMessageListener(key, value)의 함수를 통해서 다음과 같은 값이 전달된다. 
    - key : "runContactPickerResult"
	- value : json 문자열  ex) {"name" :  "홍길동", "phoneNumbers" : ["010-0000-0000", ... ]}
* */
export function runContactPicker() {
  let ret = ""; // var 대신 let 사용
  switch (checkDeviceType()) {
    // android
    case 1:
      try {
        ret = window.android.runContactPicker();
      } catch (error) {
        alert(error);
      }
      return ret;
    // ios
    case 2:
      iOSsendMessage({ method: "runContactPicker" });
      break;

    default:
      break;
  }
}

export function setBackPressOn(onOff) {
  // android
  if (checkDeviceType() === 1) {
    if (window.android.setBackPressOn) {
      window.android.setBackPressOn(onOff);
    }
  }
}
