import { useQuery, UseQueryResult } from "react-query";
import { PartnerTutorSearchListType } from "../../types/partnerTutor/partnerTutorTypes";
import PartnerTutorApi from "../../services/partnerTutor/PartnerTutorApi";

const usePartnerTutorSearchListQuery = (params: {
  searchName: string;
  businessCode: string;
  searchType: string;
}): UseQueryResult<PartnerTutorSearchListType, Error> => {
  return useQuery<PartnerTutorSearchListType, Error>({
    queryKey: ["requestedCourseInfo", params],
    queryFn: () => PartnerTutorApi.fetchPartnerTutorSearchList({ ...params }),
    onSuccess: (data: PartnerTutorSearchListType) => {
      console.log("Query successful:", data);
    },
    onError: (error) => {
      console.error("Query error:", error);
    },
  });
};

export default usePartnerTutorSearchListQuery;
