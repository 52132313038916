import { useQuery, UseQueryResult } from "react-query";
import { AlarmSettingType } from "../../../types/member/memberTypes";
import AlarmSettingApi from "../../../services/myInfo/AlarmSettingApi";

const useAlarmSettingInfoQuery = (
  params: string,
): UseQueryResult<AlarmSettingType, Error> => {
  return useQuery<AlarmSettingType, Error>({
    queryKey: ["alarmSetting", params],
    queryFn: () => AlarmSettingApi.fetchAlarmSetting(params),
    onSuccess: (data: AlarmSettingType) => {
      console.log("Query successful:", data);
    },
    onError: (error) => {
      console.error("Query error:", error);
    },
  });
};

export default useAlarmSettingInfoQuery;
