import { useQuery, UseQueryResult } from "react-query";
import CourseManagementApi from "../../services/courseManagement/CourseManagementApi";
import { CourseInfoListType } from "../../types/courseManagement/courseManagementTypes";

const useCourseInfoQuery = (
  params: string,
): UseQueryResult<CourseInfoListType, Error> => {
  return useQuery<CourseInfoListType, Error>({
    queryKey: ["requestedCourseInfo", params],
    queryFn: () => CourseManagementApi.fetchCourseInfoList(params),
    onSuccess: (data: CourseInfoListType) => {
      console.log("Query successful:", data);
    },
    onError: (error) => {
      console.error("Query error:", error);
    },
  });
};

export default useCourseInfoQuery;
