import { useQuery, UseQueryResult } from "react-query";

import ProfileApi from "../../services/profile/ProfileApi";

import { ProfileListType } from "../../types/profile/profileListTypes";

const useCreateCopyProfileQuery = (params: {
  tutorCode: string;
  businessCode: string;
}): UseQueryResult<ProfileListType, Error> => {
  return useQuery<ProfileListType, Error>(
    ["profileCopyList"],
    () =>
      ProfileApi.fetchCreateCopyProfile({
        ...params,
      }),
    {
      onSuccess: (data: ProfileListType) => {
        console.log("Query successful:", data);
      },
      onError: (error: Error) => {
        console.error("Query error:", error);
      },
    },
  );
};

export default useCreateCopyProfileQuery;
