import { useMutation, useQueryClient } from "react-query";
import AlarmApi from "../../../services/home/alarm/AlarmApi";

const useAlarmListDeleteMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (params: { id: string }) => {
      return AlarmApi.fetchAlarmDelete({ ...params });
    },
    {
      onSuccess: (data) => {
        console.log("Mutation successful:", data);
        queryClient.invalidateQueries("alarmList");
      },
      onError: (error) => {
        console.error("Mutation error:", error);
      },
    },
  );
};

export default useAlarmListDeleteMutation;
