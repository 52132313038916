import React, { useEffect } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter } from "react-router-dom";
import { RecoilRoot } from "recoil";
import "./App.css";
import "./assets/css/common.css";
import "./assets/js/function";
import GlobalWrapper from "./components/common/GlobalWrapper";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const App: React.FC = () => {
  useEffect(() => {
    // SDK 초기화
    if (window.Kakao && !window.Kakao.isInitialized()) {
      window.Kakao.init("29191c6988bfd46c904b6ebebc093d93"); // 발급받은 JavaScript 앱 키를 입력합니다.
      console.log(window.Kakao.isInitialized()); // 초기화 상태 확인용
    }
  }, []);
  return (
    <QueryClientProvider client={queryClient}>
      <RecoilRoot>
        <BrowserRouter basename="/">
          <GlobalWrapper />
        </BrowserRouter>
      </RecoilRoot>
    </QueryClientProvider>
  );
};
export default App;
