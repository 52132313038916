import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import usePartnerTutorMemoUpdateMutation from "../../../../hooks/partnerTutor/usePartnerTutorMemoUpdateMutation";
import { setBackPressOn } from "../../../../gnote_api";

function MemoEditBottomSheetModal({
  setBottomSheetName,
  initialValue,
  mappingId,
}: {
  setBottomSheetName: React.Dispatch<React.SetStateAction<string>>;
  initialValue: string;
  mappingId: number;
}) {
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const [memo, setMemo] = useState(initialValue || "");
  const PartnerTutorMemoUpdateMuation = usePartnerTutorMemoUpdateMutation(
    () => {
      setIsOpen(false);
    },
  );

  const handleAnimationComplete = () => {
    if (!isOpen) {
      setBottomSheetName("addClassSelected");
    }
  };

  const handleSave = () => {
    PartnerTutorMemoUpdateMuation.mutate({
      id: String(mappingId),
      memo,
    });
  };

  const handleCancel = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isOpen]);

  useEffect(() => {
    if (isOpen) {
      setBackPressOn(false);
    } else {
      setBackPressOn(true);
    }
  }, [isOpen]);

  return (
    <div className="bottom-sheet">
      <motion.div
        className="bottom-sheet__container"
        initial={{ y: "100%" }}
        animate={{ y: isOpen ? "0%" : "100%" }}
        transition={{ type: "spring", stiffness: 250, damping: 30 }}
        onAnimationComplete={handleAnimationComplete}
      >
        <button
          type="button"
          className="bottom-sheet__close"
          onClick={handleCancel}
        >
          <i className="icon i-close" />
        </button>

        <div className="bottom-sheet__header">메모 입력</div>
        <div className="bottom-sheet__body">
          <div className="field-group">
            <label className="field-group__label field-group__label--blind">
              메모
            </label>
            <div className="field-group__cont">
              <input
                type="text"
                value={memo}
                onChange={(e) => {
                  // 길이를 15자로 제한
                  if (e.target.value.length <= 15) {
                    setMemo(e.target.value);
                  }
                }}
                className="field-group__input"
                placeholder="메모 입력"
              />
              <span
                role={"presentation"}
                className="field-group__clear"
                onClick={() => {
                  setMemo("");
                }}
              />
            </div>
            <span className="field-group__check-characters">
              <em>{memo.length}</em> / 15자
            </span>
          </div>
        </div>
        <div className="bottom-sheet__footer">
          <button type="button" className="btn btn--full btn--gray">
            취소
          </button>
          <button
            type="button"
            className="btn btn--full btn--primary"
            onClick={handleSave}
          >
            저장하기
          </button>
        </div>
      </motion.div>
    </div>
  );
}

export default MemoEditBottomSheetModal;
