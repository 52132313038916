import React, { useEffect } from "react";
import Picker from "react-mobile-picker";
import type { PickerValue } from "react-mobile-picker";
import dayjs, { Dayjs } from "dayjs";

interface TimePickerProps {
  value: Dayjs | null;
  onChange: (value: Dayjs | null) => void;
  disabled?: boolean;
  width?: number;
}

interface TimeValue extends PickerValue {
  meridiem: string;
  hour: string;
  minute: string;
}

const pickerStyles = `
  .picker-container {
    height: 110px;
    position: relative;
    background-color: #fff;
    -webkit-overflow-scrolling: touch;
  }
  
  .picker-column {
    -webkit-overflow-scrolling: touch;
  }
`;

const TimePicker: React.FC<TimePickerProps> = ({
  value,
  onChange,
  disabled = false,
  width = 100,
}) => {
  const [meridiemValue, hourValue, minuteValue] = value
    ? [
        value.hour() < 12 ? "오전" : "오후",
        value.format("hh"),
        value.format("mm"),
      ]
    : ["오후", "06", "00"];

  useEffect(() => {
    // 이벤트 리스너를 컴포넌트가 마운트될 때 추가
    const handleTouchMove = (e: TouchEvent) => {
      if (e.target instanceof HTMLElement) {
        const pickerElement = e.target.closest(".picker-container");
        if (pickerElement) {
          e.preventDefault();
        }
      }
    };

    // passive: false로 설정하여 preventDefault가 동작하도록 함
    document.addEventListener("touchmove", handleTouchMove, { passive: false });

    // 컴포넌트가 언마운트될 때 이벤트 리스너 제거
    return () => {
      document.removeEventListener("touchmove", handleTouchMove);
    };
  }, []);

  const handleChange = (newValue: TimeValue) => {
    let hour24 = parseInt(newValue.hour, 10);

    if (newValue.meridiem === "오후" && hour24 !== 12) {
      hour24 += 12;
    } else if (newValue.meridiem === "오전" && hour24 === 12) {
      hour24 = 0;
    }

    const newDateTime = dayjs()
      .hour(hour24)
      .minute(parseInt(newValue.minute, 10))
      .second(0);

    onChange(newDateTime);
  };

  return (
    <>
      <style>{pickerStyles}</style>
      <div
        className="border rounded-lg overflow-hidden"
        style={{
          width: `${width}%`,
          opacity: disabled ? 0.5 : 1,
          pointerEvents: disabled ? "none" : "auto",
        }}
      >
        <Picker<TimeValue>
          value={{
            meridiem: meridiemValue,
            hour: hourValue,
            minute: minuteValue,
          }}
          onChange={handleChange}
          height={110}
          itemHeight={34}
          wheelMode="natural"
        >
          <Picker.Column name="meridiem">
            <Picker.Item
              value="오전"
              style={{ opacity: meridiemValue === "오전" ? 1 : 0.5 }}
            >
              오전
            </Picker.Item>
            <Picker.Item
              value="오후"
              style={{ opacity: meridiemValue === "오후" ? 1 : 0.5 }}
            >
              오후
            </Picker.Item>
          </Picker.Column>
          <Picker.Column name="hour">
            {Array.from({ length: 12 }, (_, i) => {
              const hour = (i + 1).toString().padStart(2, "0");
              const isSelected = hour === hourValue;
              return (
                <Picker.Item
                  key={hour}
                  value={hour}
                  style={{
                    opacity: isSelected ? 1 : 0.5,
                  }}
                >
                  {hour}
                </Picker.Item>
              );
            })}
          </Picker.Column>
          <Picker.Column name="minute">
            {Array.from({ length: 12 }, (_, i) => {
              const minute = (i * 5).toString().padStart(2, "0");
              const isSelected = minute === minuteValue;
              return (
                <Picker.Item
                  key={minute}
                  value={minute}
                  style={{
                    opacity: isSelected ? 1 : 0.5,
                  }}
                >
                  {minute}
                </Picker.Item>
              );
            })}
          </Picker.Column>
        </Picker>
      </div>
    </>
  );
};

export default TimePicker;
