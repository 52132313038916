import { useState } from "react";
import { formatBusinessNumber } from "../../../util/util";

interface CommonInputProps {
  placeholder: string;
  field: any;
  type?: string;
  error?: any;
  maskBusinessNumber?: boolean;
}

function CommonInput({
  placeholder,
  field,
  type = "text",
  error,
  maskBusinessNumber = false,
}: CommonInputProps) {
  const [showPassword, setShowPassword] = useState(false);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let { value } = e.target;

    // 사업자번호 포맷 적용 여부
    if (maskBusinessNumber) {
      value = formatBusinessNumber(value);
    }

    field.onChange(value);
  };

  return (
    <>
      <div className="field-group__cont">
        <input
          {...field}
          value={field.value || ""}
          type={`${type === "password" && !showPassword && "password"}`}
          className="field-group__input"
          autoCapitalize="off"
          placeholder={placeholder}
          onChange={handleInputChange} // 입력 값 변경 시 포맷팅 처리
        />
        <span
          className="field-group__clear"
          role={"presentation"}
          onClick={() => field.onChange("")}
        />
        {type === "password" && (
          <button
            type="button"
            className="field-group__pwbtn"
            onClick={() => {
              setShowPassword((e) => !e);
            }}
          >
            <i
              className={`icon ${showPassword ? "i-visibility-on" : "i-visibility-off"} `}
            />
          </button>
        )}
      </div>
      {error && (
        <span className="field-group__help field-group__help--error">
          {error}
        </span>
      )}
    </>
  );
}

export default CommonInput;
