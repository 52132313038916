import { SetStateAction, useEffect, useState } from "react";
import { useNavigate, To, useLocation } from "react-router-dom";

const navItems = [
  {
    label: "파트너 선생님",
    icon: "i-ft-partner",
    url: "/partner-tutor/accept",
  },
  {
    label: "과외 요청",
    icon: "i-ft-tutoring",
    url: "/requested-course",
  },
  { label: "내 정보", icon: "i-ft-my", url: "/my-info" },
];

const myInfoPaths = [
  "/my-info",
  "/my-info/question",
  "/my-info/question/add",
  "/my-info/question/info",
  "/my-info/notice",
  "/my-info/notice/info",
  "/my-info/security-check",
  "/my-info/management",
  "/my-info/alarm-setting",
];

function NavigationBar() {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeIndex, setActiveIndex] = useState(0);

  const handleItemClick = (
    index: SetStateAction<number>,
    url: To,
    label: string,
  ) => {
    setActiveIndex(index);
    navigate(url, { state: label });
  };

  useEffect(() => {
    const currentPath = location.pathname;

    // 내 정보 경로 처리
    if (myInfoPaths.includes(currentPath)) {
      setActiveIndex(navItems.findIndex((item) => item.url === "/my-info"));
      return;
    }

    // 일반 경로 처리
    const currentIndex = navItems.findIndex((item) => item.url === currentPath);
    if (currentIndex !== -1) {
      setActiveIndex(currentIndex);
    }
  }, [location.pathname]);

  const NoActive = () => {
    const paths = ["/home", "/alarm-list"];
    if (paths.includes(location.pathname)) {
      return true;
    }
    return "";
  };

  return (
    <footer id="app-footer">
      <nav className="global-bottom-nav">
        {navItems.map((item, index) => (
          <div
            role={"presentation"}
            key={index}
            className={`global-bottom-nav__item ${
              activeIndex === index && !NoActive()
                ? "global-bottom-nav__item--active"
                : ""
            }`}
            onClick={() => handleItemClick(index, item.url, item.label)}
          >
            <div className="global-bottom-nav__item-content">
              <i className={`icon ${item.icon}`} />
              {item.label}
            </div>
          </div>
        ))}
      </nav>
    </footer>
  );
}

export default NavigationBar;
