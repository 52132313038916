import { useInfiniteQuery } from "react-query";
import PartnerTutorApi from "../../services/partnerTutor/PartnerTutorApi";
import {
  PartnerTutorListType,
  PartnerTutorQueryParamsType,
} from "../../types/partnerTutor/partnerTutorTypes";

const usePartnerTutorListInfiniteQuery = (
  params: Omit<PartnerTutorQueryParamsType, "page"> | undefined,
) => {
  return useInfiniteQuery<PartnerTutorListType, Error>({
    queryKey: ["partnerTutorList", params],
    queryFn: ({ pageParam = 0 }) =>
      PartnerTutorApi.fetchPartnerTutorList({ ...params, page: pageParam }),
    getNextPageParam: (lastFetchedPage, allFetchedPages) => {
      const currentPageIndex = allFetchedPages.length;
      if (currentPageIndex < lastFetchedPage.totalPages) {
        return currentPageIndex;
      }
      return undefined;
    },
    onSuccess: (data) => {
      console.log("Query successful:", data);
    },
    onError: (error) => {
      console.error("Query error:", error);
    },
  });
};

export default usePartnerTutorListInfiniteQuery;
