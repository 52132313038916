import { useMutation } from "react-query";
import MemberApi from "../../services/member/MemberApi";
import { DeviceTokenUpdateMutationParams } from "../../types/member/memberTypes";

const useMemberDeviceTokenUpdateMutation = () => {
  return useMutation(
    (params: DeviceTokenUpdateMutationParams) => {
      return MemberApi.fetchMemberDeviceTokenUpdate({ ...params });
    },
    {
      onSuccess: (data) => {
        console.log("Mutation successful:", data);
      },
      onError: (error) => {
        console.error("Mutation error:", error);
      },
    },
  );
};

export default useMemberDeviceTokenUpdateMutation;
