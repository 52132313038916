import { Box, Modal } from "@mui/material";
import { useState } from "react";
import { ProfileListType } from "../../../../types/profile/profileListTypes";
import { extractYoutubeId } from "../../../../util/util";

function ImageAndVideoViewSection({
  profileData,
}: {
  profileData?: ProfileListType;
}) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    outline: "none",
  };

  const [modalName, setModalName] = useState<string>("");
  const [selectedImg, setSelectedImg] = useState<string | null>(null);
  const [selectedVideo, setSelectedVideo] = useState<string | null>(null);
  const [selectedYoutube, setSelectedYoutube] = useState<string | null>(null);

  const handleMediaClick = (linkUrl: string) => {
    const youtubeId = extractYoutubeId(linkUrl);
    if (youtubeId) {
      setModalName("youtube");
      setSelectedYoutube(linkUrl);
    } else if (linkUrl.endsWith(".mp4")) {
      setModalName("video");
      setSelectedVideo(linkUrl);
    } else {
      setModalName("img");
      setSelectedImg(linkUrl);
    }
  };

  return (
    <>
      {profileData?.imageLinks?.map((item: any, index) => {
        const { linkUrl } = item;
        const youtubeId = extractYoutubeId(linkUrl);
        const isMp4 = linkUrl.endsWith(".mp4");

        return (
          <div
            role="presentation"
            onClick={() => handleMediaClick(linkUrl)}
            className="regi-image__item"
            key={`regi-image__item-${index}`}
          >
            {youtubeId ? (
              <img
                className="thumb"
                src={`https://img.youtube.com/vi/${youtubeId}/0.jpg`}
                alt="YouTube Thumbnail"
              />
            ) : isMp4 ? (
              // eslint-disable-next-line jsx-a11y/media-has-caption
              <video className="thumb" controls>
                <source src={linkUrl} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            ) : (
              <img
                className="thumb"
                src={linkUrl.toString()}
                alt="contentImage"
              />
            )}
          </div>
        );
      })}

      {modalName === "img" && selectedImg && (
        <Modal open={modalName === "img"} onClose={() => setModalName("")}>
          <Box sx={style}>
            <img
              width="300"
              height="300"
              src={selectedImg}
              alt="contentImage"
            />
          </Box>
        </Modal>
      )}

      {modalName === "video" && selectedVideo && (
        <Modal open={modalName === "video"} onClose={() => setModalName("")}>
          <Box sx={style}>
            <video width="300" height="300" src={selectedVideo} controls>
              <track kind="captions" />
            </video>
          </Box>
        </Modal>
      )}

      {modalName === "youtube" && selectedYoutube && (
        <Modal open={modalName === "youtube"} onClose={() => setModalName("")}>
          <Box sx={style}>
            <iframe
              width="300"
              height="300"
              src={`https://www.youtube.com/embed/${extractYoutubeId(selectedYoutube)}`}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </Box>
        </Modal>
      )}
    </>
  );
}

export default ImageAndVideoViewSection;
