import { useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";
import CourseCard from "../../common/card/CourseCard";
import useCourseInfoQuery from "../../../hooks/courseManagement/useCourseInfoQuery";
import { CourseClassType } from "../../../types/courseManagement/courseManagementTypes";
import ClassCard from "../../common/card/ClassCard";
import { courseIndexAtom } from "../../../store/courseManagement/courseIndexStore";

function CourseInfoContainer() {
  const location = useLocation();
  const { data } = useCourseInfoQuery(location.state);
  const courseIndex = useRecoilValue(courseIndexAtom);

  return (
    <>
      <main className="content">
        <div className="section">
          <div className="tutoring-list">
            <CourseCard
              data={data?.courseInfo}
              courseParent={data?.courseParent}
              courseClassStatus={data?.courseClassStatus}
              cardtype={"info"}
            />
          </div>
        </div>
        <div className="divider" />
        <div className="section">
          <div className="flex-box flex-box--justify-between flex-box--items-end">
            <div className="section__title">수업 목록</div>
          </div>

          <div className="class-list">
            {data?.courseClass[courseIndex]?.map(
              (data: CourseClassType, index: number) => (
                <ClassCard data={data} key={`class-list-${index}`} />
              ),
            )}
          </div>
        </div>
      </main>
    </>
  );
}

export default CourseInfoContainer;
