import { useMutation } from "react-query";
import { Dispatch, SetStateAction } from "react";
import AuthApi from "../../services/auth/AuthApi";
import { SendEmailAuthCodeMutationParams } from "../../types/auth/authTypes";

const useSendEmailAuthCodeMutation = (
  setModalName: Dispatch<SetStateAction<string>>,
) => {
  return useMutation(
    (params: SendEmailAuthCodeMutationParams) => {
      return AuthApi.fetchSendEmailAuthCode({ ...params });
    },
    {
      onSuccess: (data) => {
        console.log("Mutation successful:", data);
        setModalName("sendEmailAuth");
      },
      onError: (error) => {
        console.error("Mutation error:", error);
      },
    },
  );
};

export default useSendEmailAuthCodeMutation;
