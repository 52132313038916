import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";

function MobileAuthConfirm() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const resultMsg = searchParams.get("resultMsg");
  const navigate = useNavigate();

  useEffect(() => {
    if (resultMsg === "OK") {
      navigate("/signup-complete");
    } else if (resultMsg === "FAIL") {
      navigate("/signup/mobile-auth");
    }
  }, [navigate, resultMsg]);

  return <></>;
}

export default MobileAuthConfirm;
