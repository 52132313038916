import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { profileGroups } from "./ProfileGroups";
import ProfileInfoList from "./ProfileInfoList";
import { useProfilePreviewQuery } from "../../../../hooks/profile/preview/useProfilePreviewQuery";
import useProfileListQuery from "../../../../hooks/profile/useProfileListQuery";
import defaultImage from "../../../../assets/img/profile_default.jpg";
import { calculateAge, translateValue } from "../../../../util/util";
import { genderConfig } from "../../../../config/config";

function ProfileInfoPreview({
  propsId,
  isExternal,
}: {
  propsId?: string;
  isExternal?: boolean;
}) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { id } = useParams();
  const isExternalLink = isExternal ?? false;
  const { data: profilePreview } = isExternalLink
    ? useProfilePreviewQuery(String(propsId))
    : useProfileListQuery(String(id));
  const imageSrc = profilePreview?.profileImage
    ? profilePreview?.profileImage.toString()
    : defaultImage;
  return (
    <main className="content profile pb0">
      <div className="section pb8">
        <div className="flex-box flex-box--direction-column flex-box--justify-center flex-box--items-center">
          <div className="profile-photo">
            <div className="profile-photo__image-preview">
              <img
                className="profile-photo__img"
                src={imageSrc}
                alt="Profile"
              />
            </div>
          </div>
          <div className="flex-box flex-box--direction-column flex-box--items-center text-center">
            {/* TODO 본인인증 기능 추가 되면 작업 */}
            {/* <div className="badge">본인인증 완료</div> */}
            <span className="typo-large strong my1">
              {profilePreview?.name} 선생님
            </span>
            {profilePreview?.gender && (
              <span className="typo-small">
                <>
                  {translateValue(profilePreview?.gender, genderConfig)} /{" "}
                  {calculateAge(profilePreview?.birthDate)}세
                </>
              </span>
            )}
          </div>
          <div className="text-center pt6">
            <p className="typo-small">{profilePreview?.introduce}</p>
          </div>
        </div>
      </div>
      <div className="divider" />

      <div className="section">
        {profileGroups.map(({ label, noPreview }, index) => (
          <ProfileInfoList
            key={`profileInfoList-preview-${index}`}
            profileData={profilePreview}
            label={label}
            type={"preview"}
            noPreview={noPreview}
          />
        ))}
        <div className={"typo-small2 color-blue pt5 pb5 py3"}>
          *프로필에는 개인정보가 포함되어 있으므로 무단 전재 및 재배포를
          금지합니다.
        </div>
      </div>
    </main>
  );
}

export default ProfileInfoPreview;
