function RadioBoxGroup({
  radioOption,
  subTitle,
  selectedValue,
  setSelectedValue,
  addClass,
}: {
  radioOption: { [key: string]: string };
  subTitle: string;
  selectedValue: string;
  setSelectedValue: React.Dispatch<React.SetStateAction<string>>;
  addClass?: string;
}) {
  const handleRadioChange = (value: string) => {
    setSelectedValue(value);
  };
  return (
    <>
      {Object.keys(radioOption).length > 0 && (
        <div className={`field-group ${addClass}`}>
          <span className="field-group__label">{subTitle}</span>
          <div className="field-group__cont field-group__cont--no-style">
            {Object.keys(radioOption).map((key) => (
              <div className="radiobox" key={key}>
                <label className="radiobox__wrapper">
                  <span className="radiobox__title radiobox__title--large">
                    {radioOption[key]}
                  </span>
                  <input
                    type="radio"
                    name="rd-group1"
                    className="radiobox--hidden radiobox--primary"
                    value={key}
                    checked={selectedValue === key}
                    onChange={() => handleRadioChange(key)}
                  />
                  <div className="radiobox--show" />
                </label>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
}

export default RadioBoxGroup;
