import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import BoardCard from "../BoardCard";
import { userAtom } from "../../../../../store/common/userStore";
import useQuestionListQuery from "../../../../../hooks/myInfo/board/useQuestionListQuery";
import { BoardType } from "../../../../../types/myInfo/boardTypes";

function QuestionContainer() {
  const user = useRecoilValue(userAtom);
  const navigate = useNavigate();
  const { data: questionList } = useQuestionListQuery({
    memberCode: String(user.member.id),
    memberType: user.member.memberType,
    searchType: "",
    searchText: "",
  });

  return (
    <>
      {questionList?.items && questionList?.items.length > 0 ? (
        <main className="content">
          <div className="flex-box flex-box--gap1 pos-sticky">
            {/* <button */}
            {/*   type="button" */}
            {/*   className="btn btn--small btn--full btn--bordered-primary" */}
            {/* > */}
            {/*   자주 묻는 질문에서 먼저 확인하기 */}
            {/* </button> */}
            <button
              type="button"
              className="btn btn--small btn--full btn--primary"
              onClick={() => {
                navigate("/my-info/question/add");
              }}
            >
              문의하기
            </button>
          </div>
          <div className="section py3">
            <div className="section__title pt0">문의내역</div>
            <span className="typo-small color-gray-700">
              문의일 기준 1년 후 자동 삭제됩니다.
            </span>
          </div>
          <div className="divider" />
          <div className="section">
            <div className="board-list">
              <ul className="board-list__group">
                {questionList.items.map((item: BoardType, index: number) => (
                  <BoardCard
                    type={"question"}
                    boardData={item}
                    key={`board-list-${index}`}
                  />
                ))}
              </ul>
            </div>
          </div>
        </main>
      ) : (
        <main className="content">
          <div className="page-empty page-empty--base">
            문의한 내역이 없습니다.
            {/* <button */}
            {/*   type="button" */}
            {/*   className="btn btn--small btn--bordered-primary px10 mt6" */}
            {/* > */}
            {/*   자주 묻는 질문에서 먼저 확인하기 */}
            {/* </button> */}
            <button
              type="button"
              className="btn btn--small btn--primary px10 mt6"
              onClick={() => {
                navigate("/my-info/question/add");
              }}
            >
              문의하기
            </button>
          </div>
        </main>
      )}
    </>
  );
}

export default QuestionContainer;
