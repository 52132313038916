import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { enqueueSnackbar } from "notistack";
import { useQueryClient } from "react-query";
import MenuList from "../../tab/subjectTab/MenuList";
import SelectedItems from "../../tab/subjectTab/SelectedItems";
import { configAtom } from "../../../../../store/common/configStore";
import useProfileListUpdateMutation from "../../../../../hooks/profile/useProfileListUpdateMutation";
import { ProfileListType } from "../../../../../types/profile/profileListTypes";

const ProfilePreferredGradeEditContainer = ({
  clickClose,
}: {
  clickClose: () => void;
}) => {
  const mutation = useProfileListUpdateMutation(clickClose);
  const [isValid, setIsValid] = useState(false);
  const config = useRecoilValue(configAtom);
  const queryClient = useQueryClient();
  const profileData =
    queryClient.getQueryData<ProfileListType>("profileCopyList");
  const [selectedItems, setSelectedItems] = useState<string[]>(
    profileData?.preferGrade?.split(",") ?? [],
  );

  const handleItemToggle = (item: string) => {
    setSelectedItems((prev) =>
      prev.includes(item) ? prev.filter((i) => i !== item) : [...prev, item],
    );
  };

  const handleItemRemove = (item: string) => {
    setSelectedItems((prev) => prev.filter((i) => i !== item));
  };

  const handleReset = () => {
    setSelectedItems([]);
    enqueueSnackbar("초기화되었습니다.");
  };

  const handleSave = () => {
    const formDataToSend = new FormData();
    formDataToSend.append("preferGrade", selectedItems.toString());
    mutation.mutate({ params: formDataToSend, endPoint: "prefer-grade" });
  };

  useEffect(() => {
    if (selectedItems.length > 0) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [selectedItems]);

  return (
    <>
      <div className="modal-dialog__header">
        <span className="modal-dialog__title">수업 선호 학년</span>
        <button
          onClick={clickClose}
          type="button"
          className="modal-dialog__close"
        >
          <i className="icon i-close" />
        </button>
      </div>
      <div className="modal-dialog__body">
        <div className="msg-ly">
          <i className="icon i-info" />
          <p className="msg-ly__text">수업 선호 학년을 모두 선택해주세요.</p>
        </div>
        <div className="master-menu mt4">
          <div className="master-menu__container">
            <div className="master-menu__list">
              <MenuList
                items={Object.values(config?.preferGradeType)}
                selectedItems={selectedItems}
                onToggle={handleItemToggle}
              />
            </div>
            <SelectedItems
              selectedItems={selectedItems}
              onRemove={handleItemRemove}
            />
          </div>
        </div>
      </div>
      <div className="modal-dialog__footer">
        <button
          type="button"
          className="btn btn--full btn--gray"
          onClick={handleReset}
        >
          선택항목 초기화
        </button>
        <button
          type="button"
          onClick={handleSave}
          disabled={!isValid}
          className={`btn btn--full ${!isValid ? "is-disabled" : "btn--primary"}`}
        >
          저장하기
        </button>
      </div>
    </>
  );
};

export default ProfilePreferredGradeEditContainer;
