import moment from "moment/moment";
import { useRecoilValue } from "recoil";
import { userAtom } from "../../../store/common/userStore";
import { StudyCalendar } from "../../../types/studyCalendar/studyCalendarTypes";
import StudyCalendarClassDayCard from "./StudyCalendarClassDayCard";
import useStudyCalendarListQuery from "../../../hooks/studyCalendar/useStudyCalendarListQuery";
import { selectedPartnerInfoAtom } from "../../../store/profile/selectedPartnerInfoStore";

function StudyCalendarDayList({ selectedDate }: { selectedDate: string }) {
  const user = useRecoilValue(userAtom);
  const selectedPartnerInfo = useRecoilValue(selectedPartnerInfoAtom);
  const queryParams = {
    day: selectedDate,
    memberCode: user?.member?.id,
    tutorCode: selectedPartnerInfo.tutorCode,
  };
  const { data } = useStudyCalendarListQuery({
    params: queryParams,
    endPoint: "day",
  });

  const sortedClassData = data?.items.sort(
    (a, b) =>
      new Date(a.classStartTime).getTime() -
      new Date(b.classStartTime).getTime(),
  );

  return (
    <>
      {data && (
        <main className="content">
          <div className="flex-box flex-box--justify-between flex-box--items-center">
            <span className="typo-small strong">
              {moment(selectedDate).format("YY.MM.DD.(ddd)")}
            </span>
          </div>
          <div className="schedule-main mt5">
            <ul
              className="schedule-main__group schedule-main__group--scroll"
              style={{ height: "200px" }}
            >
              {sortedClassData?.map((data: StudyCalendar, index: number) => (
                <StudyCalendarClassDayCard
                  key={`study-day-card-${index}`}
                  data={data}
                  index={index}
                />
              ))}
            </ul>
          </div>
        </main>
      )}
    </>
  );
}

export default StudyCalendarDayList;
