import { Controller, FormProvider, useForm } from "react-hook-form";
import { useState, useRef } from "react";
import { useRecoilValue } from "recoil";
import { userAtom } from "../../../../store/common/userStore";
import useSendEmailAuthCodeMutation from "../../../../hooks/auth/useSendEmailAuthCodeMutation";
import useConfirmEmailAuthCodeMutation from "../../../../hooks/auth/useConfirmEmailAuthCodeMutation";
import CommonInput from "../../../common/form/CommonInput";
import CommonModal from "../../../common/modal/CommonModal";
import PasswordReset from "./PasswordReset";

function FindPasswordTab() {
  const methods = useForm({
    mode: "onChange",
  });
  const [isConfirm, setIsConfirm] = useState(false);

  const [modalName, setModalName] = useState("");
  const modalClose = () => {
    setModalName("");
  };

  const {
    handleSubmit,
    formState: { errors, isValid },
    getValues,
    watch,
  } = methods;

  const user = useRecoilValue(userAtom);
  const authCodeInputRef = useRef<HTMLInputElement | null>(null);
  const sendEmailAuthCodeMutation = useSendEmailAuthCodeMutation(setModalName);
  const confirmEmailAuthCodeMutation =
    useConfirmEmailAuthCodeMutation(setIsConfirm);

  const handleConfirm = () => {
    if (authCodeInputRef.current) {
      authCodeInputRef.current.focus();
    }
    modalClose();
  };

  const onSubmit = (formData: any) => {
    confirmEmailAuthCodeMutation.mutate({
      email: formData.email,
      certNum: formData.certNum,
      memberType: user.member.memberType || "BUSINESS",
    });
  };

  const onClickEmailAuth = () => {
    sendEmailAuthCodeMutation.mutate({
      email: getValues("email"),
      memberType: user.member.memberType || "BUSINESS",
    });
  };

  return (
    <>
      {!isConfirm ? (
        <FormProvider {...methods}>
          <form
            onSubmit={handleSubmit(onSubmit)}
            style={{ display: "contents" }}
          >
            <main className="content pb0">
              <div className="section pb16">
                <p className="typo-large strong mt7">
                  이메일을 인증하고 비밀번호를 재설정할 수 있습니다.
                </p>
                <div className="field-group">
                  <label className={"field-group__label"}>이메일</label>
                  <Controller
                    name="email"
                    control={methods.control}
                    rules={{
                      required: "이메일은 필수 항목입니다.",
                      pattern: {
                        value: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
                        message: "올바른 이메일 형식을 입력해주세요.",
                      },
                    }}
                    render={({ field }) => (
                      <CommonInput
                        placeholder="이메일 주소를 입력해주세요"
                        field={field}
                        error={errors.email?.message}
                      />
                    )}
                  />
                </div>

                <div className="field-group">
                  <button
                    className={`btn btn--full btn--brown ${!watch("email") || errors.email ? "is-disabled" : ""}`}
                    type="button"
                    onClick={onClickEmailAuth}
                  >
                    인증번호 요청
                  </button>
                  <span className="field-group__label typo-small2 mt4 mb2">
                    인증번호
                  </span>
                  <Controller
                    name="certNum"
                    control={methods.control}
                    rules={{
                      validate: (value) => value?.length === 6,
                    }}
                    render={({ field }) => (
                      <input
                        {...field}
                        ref={(e) => {
                          field.ref(e);
                          authCodeInputRef.current = e;
                        }}
                        className="field-group__input"
                        style={{ backgroundColor: "#f9f9f9" }}
                        placeholder="6자리 입력"
                        value={field.value || ""}
                        maxLength={6}
                      />
                    )}
                  />
                  <span className="field-group__help">
                    인증번호는 발송 후 1시간 이내에만 유효합니다.
                  </span>
                  <div
                    className="divider divider--thin mt4"
                    style={{ marginLeft: "0", marginRight: "0" }}
                  />
                  <span className="field-group__help">
                    <i className="icon i-info mr1" />
                    이메일이 발송되지 않을 시 스팸으로 분류되어 있는지
                    확인바랍니다.
                  </span>
                </div>
              </div>
              <div className="content-footer">
                <button
                  id="btn-submit"
                  className={`btn btn--full btn--primary ${
                    !isValid ? "is-disabled" : ""
                  }`}
                  type="submit"
                >
                  비밀번호 재설정
                </button>
              </div>
            </main>
            <CommonModal
              open={modalName === "sendEmailAuth"}
              modalClose={modalClose}
              title={"인증번호 전송완료"}
              text={"비밀번호 찾기를 위한 인증 번호를 이메일로 발송하였습니다."}
              onConfirm={handleConfirm}
            />
          </form>
        </FormProvider>
      ) : (
        <PasswordReset
          certNum={getValues("certNum")}
          email={getValues("email")}
        />
      )}
    </>
  );
}

export default FindPasswordTab;
