import { AxiosApiWrapper } from "../abstract/axios-api-wrapper.abstract";
import {
  PartnerTutorListType,
  PartnerTutorSearchListType,
} from "../../types/partnerTutor/partnerTutorTypes";

class PartnerTutorApi extends AxiosApiWrapper {
  protected endPoint!: string;

  // 파트너 선생님 리스트
  public fetchPartnerTutorList(
    data: Record<string, unknown>,
  ): Promise<PartnerTutorListType> {
    this.endPoint = "api/v1/business/tutor-profile/list";
    try {
      return this.post(data);
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  }

  // 파트너 관심 선생님 리스트
  public fetchPartnerTutorInterestList(
    data: Record<string, unknown>,
  ): Promise<PartnerTutorListType> {
    this.endPoint = "api/v1/business/tutor-interest/list";
    try {
      return this.post(data);
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  }

  // 파트너 신청 리스트
  public fetchPartnerTutorRequestList(
    data: Record<string, unknown>,
  ): Promise<PartnerTutorListType> {
    this.endPoint = "api/v1/business/partner-mapping/list";
    try {
      return this.post(data);
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  }

  // 파트너 신청
  public fetchPartnerTutorRequest(data: Record<string, unknown>) {
    this.endPoint = "api/v1/business/partner-mapping";
    try {
      return this.post(data);
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  }

  // 파트너 관심 리스트 추가
  public fetchPartnerTutorInterestAdd(data: Record<string, unknown>) {
    this.endPoint = "api/v1/business/tutor-interest";
    try {
      return this.post(data);
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  }

  // 파트너 관심 리스트 해제
  public fetchPartnerTutorInterestDelete(data: Record<string, unknown>) {
    this.endPoint = "api/v1/business/tutor-interest";
    try {
      return this.delete(data);
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  }

  // 파트너 해제
  public fetchPartnerTutorDelete(data: Record<string, unknown>) {
    this.endPoint = "api/v1/business/partner-mapping";
    try {
      return this.delete(data);
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  }

  // 파트너 검색 리스트
  public fetchPartnerTutorSearchList(
    data: Record<string, unknown>,
  ): Promise<PartnerTutorSearchListType> {
    this.endPoint = "api/v1/business/partner-mapping/tutor-list";
    try {
      return this.post(data);
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  }

  // 파트너 메모 수정
  public fetchPartnerTutorMemoUpdate(data: Record<string, unknown>) {
    this.endPoint = "api/v1/business/partner-mapping/memo";
    try {
      return this.put(data);
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  }

  // 프로필 작성 요청
  public fetchPartnerTutorProfileRequest(data: Record<string, unknown>) {
    this.endPoint = "api/v1/business/tutor-profile/request";
    try {
      return this.post(data);
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  }

  // 프로필 전송
  public fetchPartnerTutorProfileSend(data: Record<string, unknown>) {
    this.endPoint = "api/v1/business/tutor-profile/send";
    try {
      return this.post(data);
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  }
}

export default new PartnerTutorApi();
