export const personalRadioBoxOption = {
  "": "전체",
  "1": "남자",
  "2": "여자",
};
export const personalCheckBoxOption = {
  "10": "10대",
  "20": "20대",
  "30": "30대",
  "40": "40대",
  "50": "50대",
  "60": "60대 이상",
};
export const tutoringRadioBoxOption = {
  PAID: "유료",
  DENY: "불가",
  FREE: "무료",
};
export const tutoringCheckBoxOption = {
  FACE: "대면과외",
  ONLINE: "화상과외",
};

export const introductionOptions = [
  "꼼꼼한 수업으로 신뢰를 드리겠습니다.",
  "책임감 있는 모습으로 열의를 다하겠습니다.",
  "다년간 축적된 노하우로 지도하겠습니다.",
  "포기하지 않고 책임질게요.",
  "이해가 잘 되도록 쉽게 설명해드릴게요.",
  "철저한 복습으로 꼭 실력을 향상시켜드리겠습니다.",
  "누구나 잘 할 수 있어요, 함께 공부해요!",
  "성적 향상을 위해 아낌없이 케어해드릴게요.",
  "성적 올리는 기쁨을 맛볼 수 있도록 해드릴게요!",
];

export const withdrawOptions = {
  "더 이상 과외를 하지 않아요.": "더 이상 과외를 하지 않아요.",
  "잘 사용하지 않아요.": "잘 사용하지 않아요.",
  "알림이 너무 많이 와요.": "알림이 너무 많이 와요.",
  "사용하기 어려워요.": "사용하기 어려워요.",
  "새로운 계정을 만들고 싶어요.": "새로운 계정을 만들고 싶어요.",
};

export const genderConfig = {
  1: "남",
  2: "여",
};

export const hourConfig = {
  1: "1시",
  2: "2시",
  3: "3시",
  4: "4시",
  5: "5시",
  6: "6시",
  7: "7시",
  8: "8시",
  9: "9시",
  10: "10시",
  11: "11시",
  12: "12시",
  13: "13시",
  14: "14시",
  15: "15시",
  16: "16시",
  17: "17시",
  18: "18시",
  19: "19시",
  20: "20시",
  21: "21시",
  22: "22시",
  23: "23시",
  24: "24시",
};

export const minuteConfig = {
  0o0: "00분",
  0o5: "05분",
  10: "10분",
  15: "15분",
  20: "20분",
  25: "25분",
  30: "30분",
  35: "35분",
  40: "40분",
  45: "45분",
  50: "50분",
  55: "55분",
};
