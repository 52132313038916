import { useQuery, UseQueryResult } from "react-query";
import {
  BoardListQueryParamsType,
  BoardListType,
} from "../../../types/myInfo/boardTypes";
import BoardApi from "../../../services/myInfo/BoardApi";

const useNoticeListQuery = (
  params: BoardListQueryParamsType,
): UseQueryResult<BoardListType, Error> => {
  return useQuery<BoardListType, Error>({
    queryKey: ["noticeList", params],
    queryFn: () => BoardApi.fetchNoticeList({ ...params }),
    onSuccess: (data: BoardListType) => {
      console.log("Query successful:", data);
    },
    onError: (error) => {
      console.error("Query error:", error);
    },
  });
};

export default useNoticeListQuery;
