import { AxiosApiWrapper } from "../abstract/axios-api-wrapper.abstract";
import {
  MemberInfoType,
  MemberProfileImageType,
} from "../../types/member/memberTypes";

class MemberApi extends AxiosApiWrapper {
  protected endPoint!: string;

  //  회원 정보 조회
  public fetchMemberInfo(id: string): Promise<MemberInfoType> {
    this.endPoint = `api/v1/member/${id}`;
    try {
      return this.get();
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  }

  // 회원 탈퇴
  public fetchMemberWithdraw(data: Record<string, unknown>) {
    this.endPoint = "api/v1/member/withdraw";
    try {
      return this.post(data);
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  }

  //  내정보 프로필 이미지 조회
  public fetchMemberProfileImage(id: string): Promise<MemberProfileImageType> {
    this.endPoint = `api/v1/member/profile-image/${id}`;
    try {
      return this.get();
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  }

  //  내정보 프로필 이미지 수정
  public fetchMemberProfileImageUpdate(data: any) {
    this.endPoint = "api/v1/member/profile-image";
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    try {
      return this.put(data, config);
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  }

  //  내정보 프로필 이미지 수정
  public fetchMemberProfileImageDelete(data: Record<string, unknown>) {
    this.endPoint = "api/v1/member/profile-image";
    try {
      return this.delete(data);
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  }

  // 휴대폰 번호 수정
  public fetchMemberMobileUpdate(data: Record<string, unknown>) {
    this.endPoint = "api/v1/member/mobile";
    try {
      return this.put(data);
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  }

  // 디바이스 토큰 수정
  public fetchMemberDeviceTokenUpdate(data: Record<string, unknown>) {
    this.endPoint = "api/v1/member/device-token";
    try {
      return this.put(data);
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  }
}

export default new MemberApi();
