import moment from "moment";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { BoardType } from "../../../../types/myInfo/boardTypes";
import useBoardDeleteMutation from "../../../../hooks/myInfo/board/useBoardDeleteMutation";
import ButtonDropDown from "../../../common/select/ButtonDropDown";
import CommonModal from "../../../common/modal/CommonModal";

function BoardCard({
  boardData,
  type,
}: {
  boardData: BoardType;
  type: string;
}) {
  const [modalName, setModalName] = useState("");
  const modalClose = () => {
    setModalName("");
  };
  const boardDeleteMutation = useBoardDeleteMutation(setModalName);
  const navigate = useNavigate();

  const handleConfirm = (e?: React.MouseEvent) => {
    e?.stopPropagation(); // 이벤트 버블링 방지
    boardDeleteMutation.mutate({ id: String(boardData.id) });
  };

  const onClickCard = () => {
    if (type === "question") {
      navigate("/my-info/question/info", { state: String(boardData.id) });
    } else if (type === "notice") {
      navigate("/my-info/notice/info", { state: String(boardData.id) });
    }
  };

  return (
    <li
      className={`board-list__item ${boardData.topFlag === "Y" && "notice"}`}
      role={"presentation"}
      onClick={onClickCard}
    >
      {type === "question" && (
        <ButtonDropDown
          buttonIcon="i-more"
          items={[
            {
              text: "삭제",
              onClick: () => {
                setModalName("delete");
              },
            },
          ]}
        />
      )}
      <div
        className={`board-list__link ${type === "question" && "board-list__link--basic"} py5`}
      >
        {type === "question" && (
          <div className="chips mb2">
            {boardData.replyFlag === "Y" ? (
              <span className="chips__item chips__item--dark-blue-gray">
                답변완료
              </span>
            ) : (
              <span className="chips__item chips__item--gray">확인중</span>
            )}
          </div>
        )}
        <div className="board-list__title board-list__title--ellipsis">
          {type === "notice" ? (
            <> {boardData?.boardTitle}</>
          ) : (
            <> {boardData?.boardContent}</>
          )}
        </div>
        <span className="board-list__date">
          {moment(boardData.createdAt).format("YYYY.MM.DD")}
        </span>
      </div>
      <CommonModal
        open={modalName === "delete"}
        modalClose={modalClose}
        title={"문의 삭제"}
        text={"삭제된 문의는 복구할 수 없습니다. 문의를 삭제 하시겠습니까?"}
        onConfirm={handleConfirm}
      />
    </li>
  );
}

export default BoardCard;
