import { useMutation, useQueryClient } from "react-query";
import { enqueueSnackbar } from "notistack";
import PartnerTutorApi from "../../services/partnerTutor/PartnerTutorApi";
import { PartnerTutorMemoUpdateMutationParams } from "../../types/partnerTutor/partnerTutorTypes";

const usePartnerTutorMemoUpdateMutation = (modalClose: () => void) => {
  const queryClient = useQueryClient();

  return useMutation(
    (params: PartnerTutorMemoUpdateMutationParams) => {
      return PartnerTutorApi.fetchPartnerTutorMemoUpdate({ ...params });
    },
    {
      onSuccess: (data) => {
        console.log("Mutation successful:", data);
        modalClose();
        queryClient.invalidateQueries("partnerTutorList");
        enqueueSnackbar("저장되었습니다.");
      },
      onError: (error) => {
        console.error("Mutation error:", error);
      },
    },
  );
};

export default usePartnerTutorMemoUpdateMutation;
