const MenuList = ({
  items,
  selectedItems,
  onToggle,
}: {
  items: string[];
  selectedItems: string[];
  onToggle: (item: string) => void;
}) => {
  return (
    <div className="master-menu__sub">
      <ul>
        {items?.map((item) => (
          <li
            role={"presentation"}
            key={item}
            className={selectedItems.includes(item) ? "active" : ""}
            onClick={() => onToggle(item)}
          >
            <span>{item}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default MenuList;
