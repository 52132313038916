import { useMutation, useQueryClient } from "react-query";
import { Dispatch, SetStateAction } from "react";
import { enqueueSnackbar } from "notistack";
import MemberApi from "../../services/member/MemberApi";
import { MobileUpdateMutationParams } from "../../types/member/memberTypes";

const useMemberMobileUpdateMutation = (
  formClose: Dispatch<SetStateAction<boolean>>,
) => {
  const queryClient = useQueryClient();
  return useMutation(
    (params: MobileUpdateMutationParams) => {
      return MemberApi.fetchMemberMobileUpdate({ ...params });
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries("memberInfo");
        console.log("Mutation successful:", data);
        formClose(false);
        enqueueSnackbar("휴대폰번호가 변경되었습니다.");
      },
      onError: (error) => {
        console.error("Mutation error:", error);
      },
    },
  );
};

export default useMemberMobileUpdateMutation;
