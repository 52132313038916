import { useEffect, useState } from "react";
import { useRecoilState, useResetRecoilState } from "recoil";
import CommonModalDialog from "../../../common/modal/CommonModalDialog";
import FilterSubjectSelectContainer from "./FilterSubjectSelectContainer";
import FilterBottomSheetModal from "./FilterBottomSheetModal";
import {
  personalCheckBoxOption,
  personalRadioBoxOption,
  tutoringCheckBoxOption,
  tutoringRadioBoxOption,
} from "../../../../config/config";
import FilterButton from "./FilterButton";
import { partnerTutorFilterAtom } from "../../../../store/partnerTutor/partnerTutorFilterStore";
import PartnerTutorSearchForm from "./PartnerTutorSearchForm";

function PartnerTutorFilter() {
  const [openNameSearchForm, setOpenNameSearchForm] = useState(false);
  const [partnerTutorFilter, setPartnerTutorFilter] = useRecoilState(
    partnerTutorFilterAtom,
  );
  const [modalName, setModalName] = useState("");
  const [searchName, setSearchName] = useState(partnerTutorFilter.name || "");
  const partnerTutorFilterReset = useResetRecoilState(partnerTutorFilterAtom);

  // 필터 버튼의 active 상태를 반환하는 함수
  const getActiveConditions = () => ({
    isPersonalActive:
      partnerTutorFilter.gender !== null ||
      partnerTutorFilter.ageGroup !== null,
    isTutoringActive:
      partnerTutorFilter.classType !== null ||
      partnerTutorFilter.demoClassType !== null,
    isSubjectActive: partnerTutorFilter.subject !== null,
    isNameActive: partnerTutorFilter.name !== "",
  });

  const activeConditions = getActiveConditions();

  const onClickSearchName = () => {
    setPartnerTutorFilter((prevReq: any) => ({
      ...prevReq,
      ageGroup: null,
      classType: null,
      demoClassType: null,
      gender: null,
      subject: null,
      name: searchName,
    }));
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      onClickSearchName();
    }
  };

  useEffect(() => {
    if (partnerTutorFilter.name === "") {
      setOpenNameSearchForm(false);
    }
  }, [partnerTutorFilter.name]);

  return (
    <>
      <div className="filter-bar">
        <div className="filter-bar__group">
          <div className="filter-bar__item">
            <button
              className={`fr-search ${activeConditions.isNameActive ? "active" : ""}`}
              onClick={() => {
                setOpenNameSearchForm((e) => !e);
              }}
            >
              <span />
            </button>
          </div>
          <FilterButton
            label="초기화"
            onClick={partnerTutorFilterReset}
            icon={"fr-refresh"}
          />
          <FilterButton
            label="인적조건"
            onClick={() => setModalName("personalConditions")}
            icon={"fr-option"}
            isActive={activeConditions.isPersonalActive}
          />
          <FilterButton
            label="과외조건"
            onClick={() => setModalName("tutoringConditions")}
            icon={"fr-option"}
            isActive={activeConditions.isTutoringActive}
          />
          <FilterButton
            label="과목"
            onClick={() => setModalName("subject")}
            icon={"fr-option"}
            isActive={activeConditions.isSubjectActive}
          />
        </div>

        {openNameSearchForm && (
          <PartnerTutorSearchForm
            searchName={searchName}
            setSearchName={setSearchName}
            onClickSearchName={onClickSearchName}
            handleKeyDown={handleKeyDown}
          />
        )}
      </div>

      {modalName === "personalConditions" && (
        <FilterBottomSheetModal
          title={"인적 조건"}
          subTitle={["성별", "연령대"]}
          radioOption={personalRadioBoxOption}
          checkBoxOption={personalCheckBoxOption}
          setBottomSheetName={setModalName}
        />
      )}

      {modalName === "tutoringConditions" && (
        <FilterBottomSheetModal
          title={"과외 조건"}
          subTitle={["과외 방식", "시범 과외"]}
          radioOption={tutoringRadioBoxOption}
          checkBoxOption={tutoringCheckBoxOption}
          setBottomSheetName={setModalName}
        />
      )}

      {modalName === "subject" && (
        <CommonModalDialog setModalName={setModalName}>
          {({ clickClose }) => (
            <FilterSubjectSelectContainer clickClose={clickClose} />
          )}
        </CommonModalDialog>
      )}
    </>
  );
}

export default PartnerTutorFilter;
