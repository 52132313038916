import moment from "moment";
import { StudyCalendar } from "../../../types/studyCalendar/studyCalendarTypes";

function StudyCalendarClassDayCard({
  data,
  index,
  type,
}: {
  data: StudyCalendar;
  index: number;
  type?: string;
}) {
  const colorArray = [
    "#304A64",
    "#057E05",
    "#6150CB",
    "#B21DCD",
    "#53708C",
    "#058E8E",
    "#08A2D5",
    "#6BA506",
    "#F48907",
    "#C35B11",
  ];
  return (
    <>
      <li
        className={`schedule-main__item ${type !== "home" && "schedule-main__item--type2"}`}
      >
        <span
          className="schedule-main__line"
          style={{
            backgroundColor: colorArray[index % colorArray.length],
          }}
        />
        <div className="schedule-main__title">
          {data?.studentNames.map((student) => student.studentName).join(", ")}
          <em className="dot" />
          {data?.className}
          <em className="dot" /> {data?.location}
        </div>
        <div className="schedule-main__time">
          {" "}
          {moment(data?.classStartTime).format("a hh:mm")} ~
          {moment(data?.classEndTime).format("a hh:mm")}
        </div>
      </li>
    </>
  );
}

export default StudyCalendarClassDayCard;
