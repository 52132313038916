import { useMutation } from "react-query";
import BoardApi from "../../../services/myInfo/BoardApi";

const useBoardAddUpdateMutation = (
  setModalName: React.Dispatch<React.SetStateAction<string>>,
) => {
  // const queryClient = useQueryClient();
  return useMutation(
    ({ params }: { params: FormData }) => {
      return BoardApi.fetchAddBoard(params);
    },
    {
      onSuccess: (data) => {
        setModalName("add");
        // queryClient.invalidateQueries("questionList");
        console.log("Mutation successful:", data);
      },
      onError: (error) => {
        console.error("Mutation error:", error);
      },
    },
  );
};

export default useBoardAddUpdateMutation;
