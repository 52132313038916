import styled from "styled-components";

const RadioButtonStyle = styled.div<{ $isSelected: boolean }>`
  border-color: ${(props) => (props.$isSelected ? "#242424" : "#efefef")};
  color: ${(props) => (props.$isSelected ? "#242424" : "#868686")};
`;

const CheckBoxStyle = styled.div<{ $isSelected: boolean }>`
  background-color: ${(props) => (props.$isSelected ? "#f66217" : "white")};
  color: ${(props) => (props.$isSelected ? "white" : "#868686")};
  border-color: ${(props) => (props.$isSelected ? "#f66217" : "#dbdbdb")};
`;

const CommonCheckChip = ({
  subject,
  onClick,
  isSelected,
  chipType,
  isDirectButton,
}: {
  subject: string;
  onClick: (subject: string) => void;
  isSelected: boolean;
  chipType: string;
  isDirectButton?: boolean;
}) => {
  const handleClick = () => {
    onClick(subject);
  };

  const StyledButton = chipType === "radio" ? RadioButtonStyle : CheckBoxStyle;

  return (
    <div className="">
      <label htmlFor="chips1" className="">
        <input type={chipType} id="chips1" />
        <StyledButton
          role={"presentation"}
          className="check-chip__title"
          $isSelected={isSelected}
          onClick={handleClick}
        >
          <span>
            {isDirectButton && <i className="icon i-edit" />}
            {subject}
          </span>
        </StyledButton>
      </label>
    </div>
  );
};

export default CommonCheckChip;
