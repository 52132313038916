import { AxiosApiWrapper } from "../abstract/axios-api-wrapper.abstract";
import {
  AreaListType,
  HighSchoolListType,
  MajorListType,
  SubjectListType,
  UniversityListType,
} from "../../types/standardInfo/standardInfoTypes";

class StandardInfoApi extends AxiosApiWrapper {
  protected endPoint!: string;
  public fetchAreas(): Promise<AreaListType> {
    this.endPoint = "api/v1/master/area";
    try {
      return this.get();
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  }

  public fetchHighSchools(
    data: Record<string, unknown>,
  ): Promise<HighSchoolListType> {
    this.endPoint = "api/v1/master/school/high";
    try {
      return this.post(data);
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  }

  public fetchMajors(data: Record<string, unknown>): Promise<MajorListType> {
    this.endPoint = "api/v1/master/univ/major";
    try {
      return this.post(data);
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  }

  public fetchSubjects(): Promise<SubjectListType> {
    this.endPoint = "api/v1/master/subject";
    try {
      return this.get();
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  }

  public fetchUniversities(
    data: Record<string, unknown>,
  ): Promise<UniversityListType> {
    this.endPoint = "api/v1/master/univ/university";
    try {
      return this.post(data);
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  }
}

export default new StandardInfoApi();
