import { atom } from "recoil";
import { AlarmType } from "../../types/home/homeTypes";

export const alarmListAtom = atom<AlarmType[]>({
  key: "alarmList",
  default: [
    {
      id: 13,
      createdAt: new Date(),
      readFlag: "",
      readDate: new Date(),
      memberCode: 0,
      msgId: 0,
      title: "",
      content: "",
      msgKindType: "",
      senderInfo: {
        memberCode: 0,
        profileImage: "",
      },
    },
  ],
});
