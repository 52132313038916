import { AxiosError, AxiosResponse } from "axios";
import { enqueueSnackbar } from "notistack";
interface ErrorResponse {
  msg: string;
}
export default function AxiosResponseErrorHandler(
  error: AxiosError,
): Promise<AxiosResponse> {
  const responseData = error.response?.data as ErrorResponse;
  if (responseData?.msg) {
    // alert(responseData?.msg);
    enqueueSnackbar(responseData?.msg, { variant: "error" });
  }
  return Promise.reject(responseData);
}
