function FilterButton({
  label,
  isActive,
  onClick,
  icon,
}: {
  label: string;
  isActive?: boolean;
  onClick: () => void;
  icon: string;
}) {
  return (
    <div className="filter-bar__item">
      <button
        className={`${icon} ${isActive ? "active" : ""}`}
        onClick={onClick}
      >
        <span>{label}</span>
      </button>
    </div>
  );
}

export default FilterButton;
