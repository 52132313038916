import { AxiosApiWrapper } from "../abstract/axios-api-wrapper.abstract";
import { AlarmSettingType } from "../../types/member/memberTypes";

class AlarmSettingApi extends AxiosApiWrapper {
  protected endPoint!: string;

  //  알림셋팅 조회
  public fetchAlarmSetting(memberId: string): Promise<AlarmSettingType> {
    this.endPoint = `api/v1/member/alim-setting/member/${memberId}`;
    try {
      return this.get();
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  }

  // 알림 받기 여부 수정
  public fetchAlarmSettingAlimUpdate(
    data: Record<string, unknown>,
    alarmId: string,
    endPoint: string,
  ) {
    this.endPoint = `api/v1/member/alim-setting/${alarmId}/${endPoint}`;
    try {
      return this.put(data);
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  }

  // 이벤트 혜택 알림 받기 여부 수정
  public fetchAlarmSettingEventUpdate(
    data: Record<string, unknown>,
    alarmId: string,
  ) {
    this.endPoint = `api/v1/member/alim-setting/${alarmId}/event`;
    try {
      return this.put(data);
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  }

  // 매너 모드 여부 수정
  public fetchAlarmSettingMannerUpdate(
    data: Record<string, unknown>,
    alarmId: string,
  ) {
    this.endPoint = `api/v1/member/alim-setting/${alarmId}/manner`;
    try {
      return this.put(data);
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  }
}

export default new AlarmSettingApi();
