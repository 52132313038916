import { useQuery, UseQueryResult } from "react-query";
import AuthApi from "../../services/auth/AuthApi";
import {
  EmailSearchQueryMutationParamsType,
  SearchEmailType,
} from "../../types/auth/authTypes";

const useEmailSearchQuery = (
  params: EmailSearchQueryMutationParamsType,
  setIsAuth: React.Dispatch<React.SetStateAction<boolean>>,
): UseQueryResult<SearchEmailType, Error> => {
  return useQuery<SearchEmailType, Error>({
    queryKey: ["searchEmail", params],
    queryFn: () => AuthApi.fetchEmailSearch({ ...params }),
    enabled: false, // 비활성화하여 수동으로 트리거되도록 설정
    onSuccess: (data: SearchEmailType) => {
      console.log("Query successful:", data);
      setIsAuth(true);
    },
    onError: (error) => {
      console.error("Query error:", error);
    },
  });
};

export default useEmailSearchQuery;
