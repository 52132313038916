import { useRecoilValue } from "recoil";
import AuthApi from "../../../../services/auth/AuthApi";
import { userAtom } from "../../../../store/common/userStore";

function SignUpMobileAuth() {
  const baseUrl = AuthApi.getBaseUrl();
  const user = useRecoilValue(userAtom);

  const openAuthPopup = () => {
    const authUrl = `${baseUrl}/api/v1/mok/${user.member.id}`; // 본인인증을 위한 URL
    window.location.href = authUrl;
  };

  return (
    <main className="content pb0">
      <div className="section">
        <div className="flex-box flex-box--direction-column mt8">
          <div className="typo-large strong color-black">
            사업자의 대표자 확인을 위해
            <br /> 대표자 <span className="color-primary">휴대폰 본인인증</span>
            을 진행해주세요.
          </div>
          <div className="typo-small color-black mt4">
            본인인증 완료 후 가입 검수가 진행됩니다.
            <br />
            검수는 영업일 기준 1~2일이 소요되며,
            <br />
            가입 완료 시 휴대폰번호로 안내해드립니다.
          </div>
        </div>
      </div>
      <div className="content-footer content-footer--transparent">
        <button
          id="btn-submit"
          className="btn btn--full btn--primary"
          type="button"
          onClick={openAuthPopup}
        >
          휴대폰 본인인증
        </button>
      </div>
    </main>
  );
}

export default SignUpMobileAuth;
