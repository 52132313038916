import { AxiosApiWrapper } from "../../abstract/axios-api-wrapper.abstract";
import { AlarmListType } from "../../../types/home/homeTypes";

class AlarmApi extends AxiosApiWrapper {
  protected endPoint!: string;

  // 알림 리스트
  public fetchAlarmList(data: Record<string, unknown>): Promise<AlarmListType> {
    this.endPoint = "api/v1/tutor/push-mapping/list";
    try {
      return this.post(data);
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  }

  // 알림 리스트 수정
  public fetchAlarmUpdate(data: Record<string, unknown>, id: string) {
    this.endPoint = `api/v1/tutor/push-mapping/${id}`;
    try {
      return this.put(data);
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  }

  // 알림 리스트 삭제
  public fetchAlarmDelete(data: Record<string, unknown>) {
    this.endPoint = "api/v1/tutor/push-mapping";
    try {
      return this.delete(data);
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  }
}

export default new AlarmApi();
