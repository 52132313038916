import { useQuery, UseQueryResult } from "react-query";
import ConfigListApi from "../../services/config/ConfigListApi";
import { ConfigListType } from "../../types/config/configTypes";

const useConfigListQuery = (): UseQueryResult<ConfigListType> => {
  return useQuery<ConfigListType>({
    queryKey: ["configList"],
    queryFn: () => ConfigListApi.fetchConfigList(),
    onSuccess: (data: ConfigListType) => {
      console.log("Query successful:", data);
    },
    onError: (error) => {
      console.error("Query error:", error);
    },
  });
};

export default useConfigListQuery;
