import axios, {
  AxiosInstance,
  AxiosInterceptorManager,
  AxiosRequestConfig,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from "axios";
import AxiosRequestHandler from "./request-handler/request.handler";
import AxiosRequestErrorHandler from "./request-handler/request-error.handler";
import AxiosResponseHandler from "./response-handler/response.handler";
import AxiosResponseErrorHandler from "./response-handler/response-error.handler";

export interface CustomInstance extends AxiosInstance {
  interceptors: {
    request: AxiosInterceptorManager<InternalAxiosRequestConfig>;
    response: AxiosInterceptorManager<AxiosResponse>;
  };
  get<T>(url: string, config?: AxiosRequestConfig): Promise<T>;
  delete<T>(
    url: string,
    data?: unknown,
    config?: AxiosRequestConfig,
  ): Promise<T>;
  options<T>(url: string, config?: AxiosRequestConfig): Promise<T>;
  post<T>(url: string, data?: unknown, config?: AxiosRequestConfig): Promise<T>;
  put<T>(url: string, data?: unknown, config?: AxiosRequestConfig): Promise<T>;
  patch<T>(
    url: string,
    data?: unknown,
    config?: AxiosRequestConfig,
  ): Promise<T>;
}

class AxiosInterceptor {
  constructor() {
    axios.interceptors.request.use(
      AxiosRequestHandler,
      AxiosRequestErrorHandler,
    );
    axios.interceptors.response.use(
      AxiosResponseHandler,
      AxiosResponseErrorHandler,
    );
  }

  getAxiosInstance(): CustomInstance {
    return axios;
  }
}

export default new AxiosInterceptor();
