import { Suspense, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { useCookies } from "react-cookie";
import HeaderBar from "./HeaderBar";
import ReactRoute from "../../routes/ReactRoute";
import NavigationBar from "./NavigationBar";
import useConfigListQuery from "../../hooks/config/useConfigListQuery";
import { configAtom } from "../../store/common/configStore";
import { userAtom } from "../../store/common/userStore";

const GlobalWrapper = () => {
  const setConfig = useSetRecoilState(configAtom);
  const { data } = useConfigListQuery();
  const location = useLocation();
  const navigate = useNavigate();
  const [cookies] = useCookies(["accessToken"]);
  const user = useRecoilValue(userAtom);

  useEffect(() => {
    if (data) {
      setConfig(data);
    }
  }, [data, setConfig]);

  useEffect(() => {
    if (
      (!cookies.accessToken || !user.member.id) &&
      ![
        /^\/pro\/pre\/ext\/[A-Za-z0-9%_.-]+$/, // 인코딩된 문자열 경로
        "/",
        "/login",
        "/signup",
        "/signup/mobile-auth",
        "/mobile-auth-confirm",
        "/signup-complete",
        "/find-login-info/email",
        "/find-login-info/password",
        "/find-login-info/password-reset",
        "/privacy-policy",
        "/terms-service",
      ].some((path) =>
        typeof path === "string"
          ? path === location.pathname
          : path.test(location.pathname),
      )
    ) {
      navigate("/");
    }
  }, [cookies, location.pathname, navigate, user.member.id]);

  const containerClass = () => {
    if (
      [
        "/profile/original",
        "/profile/copy",
        "/partner-tutor/study-calendar",
        "/my-info/security-check",
      ].includes(location.pathname)
    ) {
      return "container--bg-white";
    }
    if (
      [
        "/",
        "/login",
        "/signup",
        "/signup/mobile-auth",
        "/signup-complete",
        "/find-login-info/password",
        "/find-login-info/email",
        "/find-login-info/password-reset",
        "/privacy-policy",
        "/terms-service",
      ].includes(location.pathname)
    ) {
      return "container--bg-white auth";
    }
    if (location.pathname === "/my-info") {
      return "mypage";
    }
    if (location.pathname === "/tutorial") {
      return "container--bg-white auth px0";
    }
    if (
      [
        "/course-management/course-class-report-preview",
        "/course-management/course-index-report-preview",
      ].includes(location.pathname)
    ) {
      return "container--grow0";
    }
    return "";
  };

  const noDisPlayNavBar = () => {
    const dynamicRoutes = [
      /^\/profile\/preview\/\d+$/,
      /^\/pro\/pre\/ext\/[A-Za-z0-9%_.-]+$/, // 외부 암호화 링크
      "/",
      "/login",
      "/signup",
      "/signup-complete",
      "/signup/mobile-auth",
      "/find-login-info/password",
      "/find-login-info/email",
      "/find-login-info/password-reset",
      "/tutorial",
      "/privacy-policy",
      "/terms-service",
    ];

    return !dynamicRoutes.some((route) =>
      typeof route === "string"
        ? route === location.pathname
        : route.test(location.pathname),
    );
  };

  const LoadingFallback = () => (
    <div
      style={{
        minHeight: "100vh",
        paddingBottom: "calc(3.375rem + env(safe-area-inset-bottom))",
      }}
    >
      loading...
    </div>
  );

  return (
    <>
      <HeaderBar />
      <Suspense fallback={<LoadingFallback />}>
        <div id="app-body">
          <div className={`container ${containerClass()}`}>
            <ReactRoute />
          </div>
        </div>
      </Suspense>
      {noDisPlayNavBar() && <NavigationBar />}
    </>
  );
};

export default GlobalWrapper;
