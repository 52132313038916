import { AxiosApiWrapper } from "../abstract/axios-api-wrapper.abstract";
import { StudyCalendarListType } from "../../types/studyCalendar/studyCalendarTypes";

class StudyCalendarListApi extends AxiosApiWrapper {
  protected endPoint!: string;
  public fetchStudyCalendarList(
    data: Record<string, unknown>,
    endPoint: string,
  ): Promise<StudyCalendarListType> {
    this.endPoint = `api/v1/business/course-calendar/${endPoint}`;
    try {
      return this.post(data);
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  }
}

export default new StudyCalendarListApi();
