import { useMutation, useQueryClient } from "react-query";
import BoardApi from "../../../services/myInfo/BoardApi";

const useBoardDeleteMutation = (
  setModalName: React.Dispatch<React.SetStateAction<string>>,
) => {
  const queryClient = useQueryClient();
  return useMutation(
    (params: { id: string }) => {
      return BoardApi.fetchDeleteBoard({ ...params });
    },
    {
      onSuccess: (data) => {
        setModalName("");
        queryClient.invalidateQueries("questionList");
        console.log("Mutation successful:", data);
      },
      onError: (error) => {
        console.error("Mutation error:", error);
      },
    },
  );
};

export default useBoardDeleteMutation;
