import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useCookies } from "react-cookie";
import mainlogo from "../../assets/img/symbol.png";
function OnBoarding() {
  const navigate = useNavigate();
  const [cookies] = useCookies(["accessToken"]);

  useEffect(() => {
    if (cookies.accessToken) {
      navigate("/home");
    }
  }, [cookies.accessToken, navigate]);

  return (
    <main className="content pb0">
      <div className="section flex-box">
        <div
          className="flex-box flex-box--direction-column flex-box--items-center flex-box--justify-center"
          style={{ width: "100%" }}
        >
          <div className="typo-large3 text-center mb12">
            안녕하세요
            <br /> <b className="highlight">사업자</b>님!
          </div>
          <div>
            <img src={mainlogo} width="100" alt={"symbol"} />
          </div>
          <div className="typo-medium color-black text-center mt2">
            과외노트를 통해
            <br /> 선생님과 파트너를 맺고
            <br /> 과외를 더욱 편하게 관리해보세요!
          </div>
        </div>
      </div>
      <div className="content-footer content-footer--direction-column content-footer--transparent">
        <button
          id="btn-submit"
          className="btn btn--full btn--primary"
          type="button"
          onClick={() => {
            navigate("/signup");
          }}
        >
          회원가입
        </button>
        <div>
          <p className="text-btn py2">
            이미 회원이신가요?{" "}
            <span
              className="text-btn text-btn--underline color-black ml2"
              role={"presentation"}
              onClick={() => {
                navigate("/login");
              }}
            >
              로그인
            </span>
          </p>
        </div>
      </div>
    </main>
  );
}

export default OnBoarding;
