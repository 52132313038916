import { atom, selector } from "recoil";
import { userAtom } from "../common/userStore";
import { partnerTutorFilterAtomType } from "../../types/partnerTutor/partnerTutorTypes";

export const partnerTutorFilterDefaultSelector =
  selector<partnerTutorFilterAtomType>({
    key: "partnerTutorFilterDefaultSelector",
    get: ({ get }) => {
      const user = get(userAtom);

      return {
        name: "",
        gender: null,
        ageGroup: null,
        classType: null,
        demoClassType: null,
        subject: null,
        businessCode: String(user.member.id),
      };
    },
  });

export const partnerTutorFilterAtom = atom<partnerTutorFilterAtomType>({
  key: "partnerTutorFilter",
  default: partnerTutorFilterDefaultSelector,
});
