const SelectedItems = ({
  selectedItems,
  onRemove,
}: {
  selectedItems: string[];
  onRemove: (item: string) => void;
}) => {
  return (
    <div className="master-menu__result">
      <div className="master-menu__result-title">
        선택항목 <em className="highlight strong">{selectedItems.length}</em>
      </div>
      <ul className="master-menu__result-list">
        {selectedItems.map((item) => (
          <li key={item} className="master-menu__result-item">
            <span className="item-name">{item}</span>
            <span
              role={"presentation"}
              className="btn-delete"
              onClick={() => onRemove(item)}
            >
              <i className="icon i-close-white" />
            </span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SelectedItems;
