import { AxiosRequestConfig } from "axios";
import AxiosInterceptor, {
  CustomInstance,
} from "../interceptors/axois.interceptors";

export abstract class AxiosApiWrapper {
  protected abstract endPoint: string;
  protected baseUrl: string;
  protected axios: CustomInstance;

  constructor() {
    this.baseUrl =
      process.env.REACT_APP_ENV === "local"
        ? "http://localhost:8080"
        : process.env.REACT_APP_ENV === "development"
          ? (process.env.REACT_APP_DEV_DOMAIN as string)
          : process.env.REACT_APP_ENV === "production"
            ? (process.env.REACT_APP_PROD_DOMAIN as string)
            : "http://localhost:3000/api/mock";
    this.axios = AxiosInterceptor.getAxiosInstance();
  }

  public getBaseUrl(): string {
    return this.baseUrl;
  }

  protected get<T>(config?: AxiosRequestConfig): Promise<T> {
    return this.axios.get<T>(`${this.baseUrl}/${this.endPoint}`, config);
  }

  protected post<T>(
    data: Record<string, unknown>,
    config?: AxiosRequestConfig,
  ): Promise<T> {
    return this.axios.post<T>(`${this.baseUrl}/${this.endPoint}`, data, config);
  }

  protected put<T>(
    data: Record<string, unknown> | FormData,
    config?: AxiosRequestConfig,
  ): Promise<T> {
    return this.axios.put<T>(`${this.baseUrl}/${this.endPoint}`, data, config);
  }

  protected patch<T>(
    data: Record<string, unknown>,
    config?: AxiosRequestConfig,
  ): Promise<T> {
    return this.axios.patch<T>(
      `${this.baseUrl}/${this.endPoint}`,
      data,
      config,
    );
  }

  protected delete<T>(
    data: Record<string, unknown>,
    config?: AxiosRequestConfig,
  ): Promise<T> {
    return this.axios.delete<T>(
      `${this.baseUrl}/${this.endPoint}`,
      { data },
      config,
    );
  }
}
