import { formatPhoneNumber } from "../../../util/util";
import ButtonDropDown from "../select/ButtonDropDown";
import { parentListType } from "../../../types/requestedCourse/requestedCourseTypes";

function ParentsInfoCard({
  pl,
  onEdit,
  onDelete,
}: {
  pl: parentListType;
  onEdit: () => void;
  onDelete: () => void;
}) {
  return (
    <div className="parents-list__item">
      <ButtonDropDown
        buttonIcon="i-more"
        items={[
          { text: "변경", onClick: onEdit },
          { text: "삭제", onClick: onDelete },
        ]}
      />
      <div className="parents-list__body">
        <i className="icon i-parents" />
        <h5 className="parents-list__title">
          <b>
            {pl.studentName}({pl.studentInfo})
          </b>
          학부모
        </h5>
        <p className="parents-list__phone">
          {formatPhoneNumber(pl.parentMobile)}
        </p>
      </div>
    </div>
  );
}

export default ParentsInfoCard;
