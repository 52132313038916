import { useMutation } from "react-query";
import axios from "axios";
import AuthApi from "../../services/auth/AuthApi";

interface passwordUpdateParamsType {
  email: string;
  certNum: string;
  newPassword: string;
  confirmPassword: string;
  memberType: string;
}

const fetchSignUp = async (param: passwordUpdateParamsType): Promise<any> => {
  const baseUrl = AuthApi.getBaseUrl();
  try {
    await axios.post(`${baseUrl}/api/v1/auth/password/change`, param);
  } catch (error) {
    console.error("password-update failed:", error);
    throw error;
  }
};

export const useUpdatePasswordNoTokenMutation = (
  setIsComplete: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  return useMutation(fetchSignUp, {
    onSuccess: () => {
      setIsComplete(true);
    },
    onError: (error) => {
      console.error("Sign-up failed:", error);
    },
  });
};
