function PrivacyPolicyContainer({
  clickClose,
  noModal,
}: {
  clickClose?: () => void;
  noModal?: boolean;
}) {
  return (
    <>
      {!noModal && (
        <div className="modal-dialog__header">
          <span className="modal-dialog__title">
            과외노트 개인정보 처리방침
          </span>
          <button
            type="button"
            className="modal-dialog__close"
            onClick={clickClose}
          >
            <i className="icon i-close" />
          </button>
        </div>
      )}
      <div className="modal-dialog__body">
        <div
          className="select-dropdown mt4"
          style={{ maxWidth: "fit-content" }}
        >
          <div className="select-dropdown__inner">
            <div className="select-dropdown__arrow" />
            <div className="select-dropdown__label">
              <span>2024.10.01</span>
            </div>
            <ul className="select-dropdown__list">
              <li className="select-dropdown__item">2024.08.01</li>
              <li className="select-dropdown__item">2024.07.01</li>
            </ul>
          </div>
        </div>
        <div className="termsBox mt8">
          <p>
            멘토랩스(이하 “회사”)가 처리하는 모든 개인정보는
            「개인정보보호법」과 「정보통신망 이용촉진 및 정보보호 등에 관한
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            법률(이하 "정보통신망법")」 등 관련 법령을 준수합니다.
          </p>

          <h4>1.개인정보 수집 항목 및 목적과 보유 기간</h4>
          <p>
            회사는 이용자들의 회원가입 및 회원서비스 이용 시 원활한 서비스
            제공을 위하여 아래와 같이 개인정보를 수집하고 있습니다. 회원은
            개인정보의 수집 및 이용 등과 관련한 아래 사항에 대하여 원하지 않는
            경우 동의를 거부할 수 있습니다. 그러나 필수항목에 대한 수집 및 이용
            동의를 거부하실 경우 회원가입 및 서비스 이용에 제한이 있을 수
            있습니다.
          </p>
          <div className="tableDiv">
            <span className="typo-medium2 strong">- 사업자 회원</span>
            <table>
              <colgroup>
                <col width="18%" />
                <col width="25%" />
                <col width="25%" />
                <col width="*" />
              </colgroup>
              <thead>
                <tr>
                  <th>분류</th>
                  <th>목적</th>
                  <th>항목</th>
                  <th>보유 및 이용기간</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>필수</td>
                  <td>회원 가입 및 이용자 식별, 회원관리</td>
                  <td>아이디(이메일), 사업자명, 이름, 휴대폰번호, 비밀번호</td>
                  <td>
                    회원 탈퇴시까지.
                    <br />
                    단, 관계법령에 별도로 정하고 있는 경우 그에 따른 법정기간
                    동안 보관
                  </td>
                </tr>
                <tr>
                  <td>필수</td>
                  <td>학부모 회원에게 정보 제공</td>
                  <td>사업자명, 휴대폰번호</td>
                  <td>
                    회원 탈퇴시까지.
                    <br />
                    단, 관계법령에 별도로 정하고 있는 경우 그에 따른 법정기간
                    동안 보관
                  </td>
                </tr>

                <tr>
                  <td>선택</td>
                  <td>회원 가입 시 사업자 인증</td>
                  <td>사업자등록증</td>
                  <td>
                    회원 탈퇴시까지.
                    <br />
                    단, 관계법령에 별도로 정하고 있는 경우 그에 따른 법정기간
                    동안 보관
                  </td>
                </tr>
                <tr>
                  <td>선택</td>
                  <td>
                    선생님 회원과 파트너 연결시 - 선생님 회원에게 정보 제공
                  </td>
                  <td>사업자명, 휴대폰번호</td>
                  <td>
                    파트너 해지시까지.
                    <br />
                    단, 관계법령에 별도로 정하고 있는 경우 그에 따른 법정기간
                    동안 보관
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="tableDiv">
            <span className="typo-medium2 strong">- 선생님 회원</span>
            <table>
              <colgroup>
                <col width="18%" />
                <col width="25%" />
                <col width="25%" />
                <col width="*" />
              </colgroup>
              <thead>
                <tr>
                  <th>분류</th>
                  <th>목적</th>
                  <th>항목</th>
                  <th>보유 및 이용기간</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>필수</td>
                  <td>회원 가입 및 이용자 식별, 회원관리</td>
                  <td>아이디(이메일), 이름, 휴대폰번호, 비밀번호</td>
                  <td>
                    회원 탈퇴시까지.
                    <br />
                    단, 관계법령에 별도로 정하고 있는 경우 그에 따른 법정기간
                    동안 보관
                  </td>
                </tr>
                <tr>
                  <td>필수</td>
                  <td>프로필 서비스 제공</td>
                  <td>이름, 성별, 생년월일(나이)</td>
                  <td>
                    회원 탈퇴시까지.
                    <br />
                    단, 관계법령에 별도로 정하고 있는 경우 그에 따른 법정기간
                    동안 보관
                  </td>
                </tr>
                <tr>
                  <td>선택</td>
                  <td>프로필 서비스 제공</td>
                  <td>
                    과외 영업 인증 서류[재학증명서, 개인과외교습자 신고증명서,
                    통신판매업 신고증], 학력 정보, 합격유형, 합격수기, 출신
                    고등학교, 고교 성적, 출신 중학교, 거주지 등 프로필 서비스를
                    이용하기 위해 회원이 직접 입력하는 기타 프로필 정보(프로필
                    정보는 정보주체가 결정하여 입력합니다.)
                  </td>
                  <td>
                    정보주체가 삭제시까지.
                    <br />
                    단, 관계법령에 별도로 정하고 있는 경우 그에 따른 법정기간
                    동안 보관
                  </td>
                </tr>
                <tr>
                  <td>선택</td>
                  <td>
                    사업자 회원과 파트너 연결시 - 사업자 회원에게 정보 제공,
                    선생님 프로필 편집 및 전송을 위한 사업자 회원에게 정보 제공
                  </td>
                  <td>이름, 휴대폰번호, 선생님 프로필</td>
                  <td>
                    파트너 해지시까지.
                    <br />
                    단, 관계법령에 별도로 정하고 있는 경우 그에 따른 법정기간
                    동안 보관
                  </td>
                </tr>
              </tbody>
            </table>
            <p>
              ※선생님이 수업 보고서에 작성한 내용은 개인에 따라 민감한 정보가 될
              수 있습니다. (수업태도, 성적 등)
            </p>
          </div>
          <div className="tableDiv">
            <span className="typo-medium2 strong">- 학부모 회원</span>
            <table>
              <colgroup>
                <col width="18%" />
                <col width="25%" />
                <col width="25%" />
                <col width="*" />
              </colgroup>
              <thead>
                <tr>
                  <th>분류</th>
                  <th>목적</th>
                  <th>항목</th>
                  <th>보유 및 이용기간</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>필수</td>
                  <td>회원 가입 및 이용자 식별, 회원관리</td>
                  <td>아이디(이메일), 이름, 휴대폰번호, 비밀번호</td>
                  <td>
                    회원 탈퇴시까지.
                    <br />
                    단, 관계법령에 별도로 정하고 있는 경우 그에 따른 법정기간
                    동안 보관
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          {/* <h4>2.개인정보 제 3자 제공</h4> */}
          {/* <ul className="numList2"> */}
          {/*   <li> */}
          {/*     회사는 이용자들의 개인정보를 고지한 범위 내에서 사용하며, 이용자의 */}
          {/*     사전 동의 없이는 동 범위를 초과하여 이용하거나 원칙적으로 이용자의 */}
          {/*     개인정보를 외부에 공개하지 않습니다. */}
          {/*   </li> */}
          {/*   <li> */}
          {/*     다만, 아래의 경우에는 예외로 합니다. */}
          {/*     <ul className="numList"> */}
          {/*       <li>이용자들이 사전에 동의한 경우</li> */}
          {/*       <li> */}
          {/*         법률에 특별한 규정이 있거나 법령상 의무를 준수하기 위하여 */}
          {/*         불가피한 경우 */}
          {/*       </li> */}
          {/*       <li>기타 「개인정보 보호법」에서 정하고 있는 경우</li> */}
          {/*     </ul> */}
          {/*   </li> */}
          {/*   <li> */}
          {/*     회사는 사업자 회원이 가입하는 경우 일부 회원에게 아래 정보를 */}
          {/*     제공합니다. */}
          {/*     <div className="tableDiv"> */}
          {/*       <table> */}
          {/*         <colgroup> */}
          {/*           <col width="18%" /> */}
          {/*           <col width="25%" /> */}
          {/*           <col width="25%" /> */}
          {/*           <col width="*" /> */}
          {/*         </colgroup> */}
          {/*         <thead> */}
          {/*           <tr> */}
          {/*             <th>제공받는 자</th> */}
          {/*             <th>제공받는 자의 이용 목적</th> */}
          {/*             <th>제공 항목</th> */}
          {/*             <th>보유.이용기간</th> */}
          {/*           </tr> */}
          {/*         </thead> */}
          {/*         <tbody> */}
          {/*           <tr> */}
          {/*             <td>선생님 회원</td> */}
          {/*             <td>파트너 서비스 제공</td> */}
          {/*             <td>사업자명, 휴대폰번호</td> */}
          {/*             <td> */}
          {/*               개인정보 이용목적 달성 시까지. */}
          {/*               <br /> */}
          {/*               단, 관계법령에 정해진 규정에 따라 법정기간 동안 보관 */}
          {/*             </td> */}
          {/*           </tr> */}
          {/*           <tr> */}
          {/*             <td>학부모 회원</td> */}
          {/*             <td>사업자 정보 제공</td> */}
          {/*             <td>사업자명, 휴대폰번호</td> */}
          {/*             <td> */}
          {/*               개인정보 이용목적 달성 시까지. */}
          {/*               <br /> */}
          {/*               단, 관계법령에 정해진 규정에 따라 법정기간 동안 보관 */}
          {/*             </td> */}
          {/*           </tr> */}
          {/*         </tbody> */}
          {/*       </table> */}
          {/*     </div> */}
          {/*   </li> */}
          {/*   <li> */}
          {/*     회사는 선생님 회원이 가입하는 경우 일부 회원에게 아래 정보를 */}
          {/*     제공합니다. */}
          {/*     <div className="tableDiv"> */}
          {/*       <table> */}
          {/*         <colgroup> */}
          {/*           <col width="18%" /> */}
          {/*           <col width="25%" /> */}
          {/*           <col width="25%" /> */}
          {/*           <col width="*" /> */}
          {/*         </colgroup> */}
          {/*         <thead> */}
          {/*           <tr> */}
          {/*             <th>제공받는 자</th> */}
          {/*             <th>제공받는 자의 이용 목적</th> */}
          {/*             <th>제공 항목</th> */}
          {/*             <th>보유.이용기간</th> */}
          {/*           </tr> */}
          {/*         </thead> */}
          {/*         <tbody> */}
          {/*           <tr> */}
          {/*             <td rowSpan={2}>사업자 회원</td> */}
          {/*             <td>파트너 서비스 제공</td> */}
          {/*             <td>이름, 휴대폰번호</td> */}
          {/*             <td> */}
          {/*               개인정보 이용목적 달성 시까지. */}
          {/*               <br /> */}
          {/*               단, 관계법령에 정해진 규정에 따라 법정기간 동안 보관 */}
          {/*             </td> */}
          {/*           </tr> */}
          {/*           <tr> */}
          {/*             <td>프로필 편집/전송 서비스 제공</td> */}
          {/*             <td> */}
          {/*               이름, 성별, 나이, 과외 영업 인증 서류[재학증명서, */}
          {/*               개인과외교습자 신고증명서, 사업자등록증, 통신판매업 */}
          {/*               신고증], 학력 정보, 합격유형, 합격수기, 출신 고등학교, */}
          {/*               고교 성적, 출신 중학교, 거주지 등 선생님이 직접 입력하는 */}
          {/*               기타 프로필 정보(프로필 정보는 정보주체가 결정하여 */}
          {/*               입력합니다.) */}
          {/*             </td> */}
          {/*             <td> */}
          {/*               개인정보 이용목적 달성 시까지. */}
          {/*               <br /> */}
          {/*               단, 관계법령에 정해진 규정에 따라 법정기간 동안 보관 */}
          {/*             </td> */}
          {/*           </tr> */}
          {/*           <tr> */}
          {/*             <td>학부모 회원</td> */}
          {/*             <td>프로필 조회 서비스 제공</td> */}
          {/*             <td> */}
          {/*               이름, 성별, 나이, 학력 정보, 합격유형, 합격수기, 출신 */}
          {/*               고등학교, 고교 성적, 출신 중학교, 거주지 등 선생님이 */}
          {/*               직접 입력하는 기타 프로필 정보(프로필 정보는 정보주체가 */}
          {/*               결정하여 입력합니다.) */}
          {/*             </td> */}
          {/*             <td> */}
          {/*               개인정보 이용목적 달성 시까지. */}
          {/*               <br /> */}
          {/*               단, 관계법령에 정해진 규정에 따라 법정기간 동안 보관 */}
          {/*             </td> */}
          {/*           </tr> */}
          {/*         </tbody> */}
          {/*       </table> */}
          {/*     </div> */}
          {/*   </li> */}
          {/* </ul> */}

          <h4>2.개인정보 처리 위탁</h4>
          <p>
            회사는 이용자에게 원활한 서비스를 제공하기 위해 업무 중 일부를
            외부에 위탁하고 있으며, 위탁 받은 업체가 정보통신망법에 따라
            개인정보를 안전하게 처리하도록 필요한 사항을 규정하고 이에 대한
            관리·감독을 수행합니다.
          </p>
          <div className="tableDiv">
            <span className="typo-medium2 strong">
              - 개인정보 처리업무 위탁 현황
            </span>
            <table>
              <colgroup>
                <col width="40%" />
                <col width="*" />
              </colgroup>
              <thead>
                <tr>
                  <th>수탁자</th>
                  <th>위탁 업무 내용</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>원샷</td>
                  <td>SMS/LMS, 카카오 알림톡 메시지 발송</td>
                </tr>
                <tr>
                  <td>드림시큐리트</td>
                  <td>본인인증 서비스</td>
                </tr>
              </tbody>
            </table>
          </div>

          <h4>3.개인정보의 파기 절차 및 방법</h4>
          <p>
            회사는 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가
            불필요하게 되었을 때에는 해당 개인정보를 파기합니다. 개인정보의 파기
            절차 및 방법은 다음과 같습니다.
          </p>
          <ul className="numList4">
            <li>
              파기 절차 : 개인정보 목적 달성 후 내부 방침 및 관련 법령에 의한
              의무에 따라 파기합니다.
            </li>
            <li>
              파기 방법 : 전자적 파일 형태로 저장된 개인정보는 기록을 복원이
              불가능한 방법으로 영구 파기하며, 종이문서에 저장된 개인정보는
              분쇄기로 분쇄하거나 소각 등을 통하여 파기합니다.
            </li>
          </ul>
          <p>
            이용자로부터 동의 받은 개인정보 보유기간이 경과하거나 처리목적이
            달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속 보존하여야
            하는 경우에는, 해당 개인정보를 별도로 분리하여 저장·관리합니다.
          </p>
          <div className="tableDiv">
            <table>
              <colgroup>
                <col width="33%" />
                <col width="33%" />
                <col width="*" />
              </colgroup>
              <thead>
                <tr>
                  <th>보유정보</th>
                  <th>보유기간</th>
                  <th>근거법령</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>계약 또는 청약철회 등에 관한 기록</td>
                  <td>5 년</td>
                  <td rowSpan={2}>
                    「전자상거래 등에서의 소비자보호에 관한 법률」
                  </td>
                </tr>
                <tr>
                  <td>소비자의 불만 또는 분쟁 처리에 관한 기록</td>
                  <td>3 년</td>
                </tr>
              </tbody>
            </table>
          </div>

          <h4>4.이용자와 권리 및 행사 방법</h4>
          <p>
            이용자의 개인정보는 아래와 같은 방법을 통해 열람, 정정, 삭제, 동의
            철회, 처리정지 요청을 할 수 있습니다. 서비스를 통해 직접 확인하지
            못하는 정보는 [과외노트 앱에서 – 내 정보 {">"} 문의하기] 또는
            개인정보 보호 책임자를 통해 요청하여 확인할 수 있습니다.
          </p>
          <ul className="numList4">
            <li>
              서비스를 통해 확인하는 방법: 과외노트 앱에서 - 내 정보 {">"} 내
              정보 관리 {">"} 회원정보 수정
              <br />
              다만, 아래의 경우에는 동의 철회, 삭제, 처리 정지가 어려울 수
              있습니다.
            </li>
            <li>
              법령에 특별한 규정이 있거나 법령상 의무를 다하기 위해 불가피한
              경우
            </li>
            <li>
              다른 사람의 신체, 생명을 해할 우려가 있거나 다른 사람의 재산과 그
              밖의 이익을 부당하게 침해할 우려가 있는 경우
            </li>
            <li>
              개인정보를 처리하지 않으면서 이용자와 약정한 서비스를 제공하지
              못하는 경우로서 이용자가 그 계약의 해지 의사를 명확하게 밝히지
              않은 경우
            </li>
          </ul>
          <p>
            아이디 및 비밀번호는 타인에게 양도하거나 대여할 수 없으며 이로 인해
            발생한 문제에 대해서는 회사가 책임지지 않습니다. 타인의 정보 및
            존엄성을 훼손할 시에는「개인정보 보호법」,「정보통신망법」등에 의해
            처벌받을 수 있습니다.
          </p>

          <h4>5.개인정보의 안전성 확보 조치</h4>
          <p>
            회사는 이용자의 개인정보를 처리함에 있어 개인정보가 도난, 유출,
            변조, 또는 훼손되지 않도록 기술적 관리적 보호조치를 강구하고
            있습니다.
          </p>
          <ul className="numList4">
            <li>
              기술적 보호조치: 해킹시도 및 유출에 대한 실시간 모니터링 및 차단,
              접근통제 적용, 접속기록의 보관 및 위변조 방지 적용, 비밀번호 및
              개인정보 암호화 저장, 전송 시 암호화
            </li>
            <li>
              관리적 보호조치: 개인정보보호 전담조직 운영, 개인정보취급자 대한
              정기적인 교육 수행, 직원을 대상으로 한 인식제고 활동, 규정
              준수상태 정기적 점검
            </li>
          </ul>

          <h4>6.개인정보 보호 책임자</h4>
          <p>
            회원은 회사의 서비스를 이용하며 발생하는 모든 개인정보보호 관련
            민원을 개인정보보호책임자 또는 담당부서에 문의하실 수 있습니다.
            회사는 회원들의 문의사항에 대해 신속하고 충분한 답변 을 드릴
            것입니다.
          </p>
          <div className="tableDiv">
            <table>
              <colgroup>
                <col width="32%" />
                <col width="*" />
              </colgroup>
              <tbody>
                <tr>
                  <th>개인정보보호책임</th>
                  <td>임호찬</td>
                </tr>
                <tr>
                  <th>전화번호</th>
                  <td>010-2605-1830</td>
                </tr>
                <tr>
                  <th>이메일</th>
                  <td>photoi0299@gamil.com</td>
                </tr>
              </tbody>
            </table>
          </div>

          <h4>7.정보주체의 권익침해에 대한 구제방법</h4>
          <p>
            개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에
            문의하시기 바랍니다.
          </p>
          <div className="tableDiv">
            <span className="typo-medium2">
              - 개인정보침해 신고센터 (한국인터넷진흥원 운영)
            </span>
            <table>
              <colgroup>
                <col width="32%" />
                <col width="*" />
              </colgroup>
              <tbody>
                <tr>
                  <th>소관업무</th>
                  <td>개인정보 침해사실 신고, 상담 신청</td>
                </tr>
                <tr>
                  <th>홈페이지 </th>
                  <td>privacy.kisa.or.kr</td>
                </tr>
                <tr>
                  <th>전화</th>
                  <td>(국번없이) 118</td>
                </tr>
                <tr>
                  <th>주소</th>
                  <td>
                    (58324) 전남 나주시 진흥길 9(빛가람동 301-2) 3층
                    한국인터넷진흥원 개인정보침해 신고센터
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="tableDiv">
            <span className="typo-medium2">
              - 개인정보 분쟁조정위원회 (한국인터넷진흥원 운영)
            </span>
            <table>
              <colgroup>
                <col width="32%" />
                <col width="*" />
              </colgroup>
              <tbody>
                <tr>
                  <th>소관업무</th>
                  <td>개인정보 분쟁조정신청, 집단분쟁조정 (민사적 해결)</td>
                </tr>
                <tr>
                  <th>홈페이지 </th>
                  <td>www.kopico.go.kr</td>
                </tr>
                <tr>
                  <th>전화</th>
                  <td>1833-6972</td>
                </tr>
                <tr>
                  <th>주소</th>
                  <td>
                    (03171) 서울특별시 종로구 세종대로 209 정부서울청사 12층
                    개인정보 분쟁조정위원회
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="tableDiv">
            <span className="typo-medium2">- 대검찰청 사이버수사과</span>
            <table>
              <colgroup>
                <col width="32%" />
                <col width="*" />
              </colgroup>
              <tbody>
                <tr>
                  <th>홈페이지 </th>
                  <td>www.spo.go.kr</td>
                </tr>
                <tr>
                  <th>전화</th>
                  <td>(국번없이)1301</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="tableDiv">
            <span className="typo-medium2">- 경찰청 사이버수사국</span>
            <table>
              <colgroup>
                <col width="32%" />
                <col width="*" />
              </colgroup>
              <tbody>
                <tr>
                  <th>홈페이지 </th>
                  <td>https://cyberbureau.police.go.kr</td>
                </tr>
                <tr>
                  <th>전화</th>
                  <td>(국번없이)182</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="tableDiv">
            <span className="typo-medium2">- 개인정보보호협회</span>
            <table>
              <colgroup>
                <col width="32%" />
                <col width="*" />
              </colgroup>
              <tbody>
                <tr>
                  <th>홈페이지 </th>
                  <td>www.eprivacy.or.kr</td>
                </tr>
                <tr>
                  <th>전화</th>
                  <td>02-550-9500</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="mt10">
            <p className="typo-medium2">
              <b>개인정보처리방침 시행일자 :</b> 2024. 10. 1
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default PrivacyPolicyContainer;
