import { useRecoilState, useRecoilValue } from "recoil";
import { JSX, useEffect, useState } from "react";
import defaultImage from "../../../../assets/img/profile_default.jpg";
import { profileGroups } from "../info/ProfileGroups";
import ProfileInfoList from "../info/ProfileInfoList";
import { selectedPartnerInfoAtom } from "../../../../store/profile/selectedPartnerInfoStore";
import CommonModalDialog from "../../../common/modal/CommonModalDialog";
import ProfileTrialTutoringEditContainer from "../info/editContainers/ProfileTrialTutoringEditContainer";
import ProfileSubjectEditContainer from "../info/editContainers/ProfileSubjectEditContainer";
import ProfileIntroductionEditContainer from "../info/editContainers/ProfileIntroductionEditContainer";
import ProfilePreferredGradeEditContainer from "../info/editContainers/ProfilePreferredGradeEditContainer";
import ProfileCommonEditContainer from "../info/editContainers/ProfileCommonEditContainer";
import ProfileTransferModal from "../modal/ProfileTransferModal";
import useCreateCopyProfileQuery from "../../../../hooks/profile/useCreateCopyProfileQuery";
import { calculateAge, translateValue } from "../../../../util/util";
import { genderConfig } from "../../../../config/config";

function ProfileCopyTabContainer() {
  const [, setSelectedPartnerInfo] = useRecoilState(selectedPartnerInfoAtom);
  const [modalName, setModalName] = useState<string>("");
  const selectedPartnerInfo = useRecoilValue(selectedPartnerInfoAtom);
  const { data: profileInfo } = useCreateCopyProfileQuery({
    tutorCode: String(selectedPartnerInfo.tutorCode),
    businessCode: String(selectedPartnerInfo.businessCode),
  });
  const imageSrc = profileInfo?.profileImage
    ? profileInfo?.profileImage.toString()
    : defaultImage;

  // create한 copyProfileId를 selectedPartnerInfo에 담기
  useEffect(() => {
    if (profileInfo?.id) {
      setSelectedPartnerInfo((prev) => ({
        ...prev,
        copyProfileId: profileInfo.id,
      }));
    }
  }, [profileInfo, setSelectedPartnerInfo]);

  const renderModalContent = (): JSX.Element | null => {
    const group = profileGroups.find((group) => group.label === modalName);
    if (!group) return null;

    if (group.label === "시범 과외(유료/무료)") {
      return (
        <CommonModalDialog setModalName={setModalName}>
          {({ clickClose }) => (
            <ProfileTrialTutoringEditContainer clickClose={clickClose} />
          )}
        </CommonModalDialog>
      );
    }

    if (group.label === "지도과목") {
      return (
        <CommonModalDialog setModalName={setModalName}>
          {({ clickClose }) => (
            <ProfileSubjectEditContainer clickClose={clickClose} />
          )}
        </CommonModalDialog>
      );
    }

    if (group.label === "한줄소개") {
      return (
        <CommonModalDialog setModalName={setModalName}>
          {({ clickClose }) => (
            <ProfileIntroductionEditContainer clickClose={clickClose} />
          )}
        </CommonModalDialog>
      );
    }

    if (group.label === "수업 선호 학년") {
      return (
        <CommonModalDialog setModalName={setModalName}>
          {({ clickClose }) => (
            <ProfilePreferredGradeEditContainer clickClose={clickClose} />
          )}
        </CommonModalDialog>
      );
    }

    if (group.common) {
      return (
        <CommonModalDialog setModalName={setModalName}>
          {({ clickClose }) => (
            <ProfileCommonEditContainer
              fields={group.fields}
              title={group.label}
              placeholder={group.placeholder || ""}
              clickClose={clickClose}
            />
          )}
        </CommonModalDialog>
      );
    }

    return null;
  };

  return (
    <main className="content profile">
      <div className="msg-ly mt4">
        <i className="icon i-info" />
        <p className="msg-ly__text">
          사업자 수정 프로필에서 사업자는 파트너 선생님의 프로필을 일부 수정할
          수 있습니다. 또한 인증 서류를 확인하고 인증 배지를 노출할 수 있습니다.
        </p>
      </div>
      <div className="section pb8">
        <div className="flex-box flex-box--direction-column flex-box--justify-center flex-box--items-center">
          <div className="typo-large text-center strong pt5">선생님 프로필</div>
          <div className="profile-photo">
            <div className="profile-photo__image-preview">
              <img
                className="profile-photo__img"
                src={imageSrc}
                alt="Profile"
              />
            </div>
          </div>
          <div className="flex-box flex-box--direction-column flex-box--items-center text-center">
            <span className="typo-large strong my1">
              {profileInfo?.name} 선생님
            </span>
            {profileInfo?.gender && (
              <span className="typo-small">
                <>
                  {translateValue(profileInfo?.gender, genderConfig)} /{" "}
                  {calculateAge(profileInfo?.birthDate)}세
                </>
              </span>
            )}
          </div>
        </div>
      </div>
      <div className="divider" />

      <div className="section">
        {profileGroups.map(({ label, noPreview, noEdit }, index) => (
          <ProfileInfoList
            key={`profileInfoList-${index}`}
            setModalName={setModalName}
            profileData={profileInfo}
            label={label}
            noPreview={noPreview}
            noEdit={noEdit}
            type={"copy"}
          />
        ))}
      </div>

      <div className="bottom-button-wrap pt10">
        <button
          type="button"
          className="btn btn--full btn--primary"
          onClick={() => {
            setModalName("profileTransfer");
          }}
        >
          전송하기
        </button>
      </div>

      {renderModalContent()}
      {modalName === "profileTransfer" && (
        <ProfileTransferModal setModalName={setModalName} type={"COPY"} />
      )}
    </main>
  );
}

export default ProfileCopyTabContainer;
