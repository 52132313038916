import Modal from "@mui/material/Modal";
import React, { useEffect } from "react";
import { setBackPressOn } from "../../../gnote_api";
export default function CommonModal({
  open,
  modalClose,
  title,
  text,
  onConfirm,
  noCancelButton,
}: {
  open: boolean;
  modalClose: () => void;
  onConfirm?: () => void;
  title?: string;
  text: React.ReactNode;
  noCancelButton?: boolean;
}) {
  useEffect(() => {
    if (open) {
      setBackPressOn(false);
    } else {
      setBackPressOn(true);
    }
  }, [open]);

  return (
    <Modal
      open={open}
      onClose={modalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <>
        <div className="modal">
          <div className="modal__container">
            <div className="modal__header">
              {title && <> {title}</>}
              <p className="modal__description">{text}</p>
            </div>

            {noCancelButton ? (
              <div className="modal__footer">
                <button
                  onClick={onConfirm}
                  className="btn btn--small btn--full btn--primary"
                >
                  확인
                </button>
              </div>
            ) : (
              <div className="modal__footer">
                <button
                  onClick={onConfirm}
                  className="btn btn--small btn--full btn--primary"
                >
                  네
                </button>

                <button
                  className="btn btn--small btn--full"
                  onClick={modalClose}
                >
                  아니오
                </button>
              </div>
            )}
          </div>
        </div>
      </>
    </Modal>
  );
}
