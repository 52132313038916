import React from "react";
import { InfiniteData } from "react-query";
import PartnerCard from "./PartnerCard";
import {
  PartnerTutorListType,
  PartnerTutorType,
} from "../../../types/partnerTutor/partnerTutorTypes";

function PartnerListContainer({
  partnerTutorList,
  type,
}: {
  partnerTutorList?: InfiniteData<PartnerTutorListType> | undefined;
  type?: string;
}) {
  return (
    <div className="partner-bslist">
      {partnerTutorList?.pages.map((page, pageIndex) =>
        page.items.map((data: PartnerTutorType, index: number) => (
          <React.Fragment key={`completed-course${pageIndex}-${index}`}>
            {data.status !== "ACCEPT" && (
              <PartnerCard data={data} type={type} />
            )}
          </React.Fragment>
        )),
      )}
    </div>
  );
}

export default PartnerListContainer;
