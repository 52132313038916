import { useMutation, useQueryClient } from "react-query";
import { useRecoilValue } from "recoil";
import { enqueueSnackbar } from "notistack";
import ProfileApi from "../../services/profile/ProfileApi";
import { selectedPartnerInfoAtom } from "../../store/profile/selectedPartnerInfoStore";

const useProfileListUpdateMutation = (modalClose: () => void) => {
  const queryClient = useQueryClient();
  const selectedPartnerInfo = useRecoilValue(selectedPartnerInfoAtom);

  return useMutation(
    ({ params, endPoint }: { params: FormData; endPoint: string }) => {
      return ProfileApi.fetchProfileListUpdate(
        String(selectedPartnerInfo.copyProfileId),
        endPoint,
        params,
      );
    },
    {
      onSuccess: (data) => {
        console.log("Mutation successful:", data);
        queryClient.invalidateQueries("profileCopyList");
        modalClose();
        enqueueSnackbar("저장되었습니다.");
      },
      onError: (error) => {
        console.error("Mutation error:", error);
      },
    },
  );
};

export default useProfileListUpdateMutation;
