import { Outlet, useLocation, useNavigate } from "react-router-dom";

function CourseManagementContainer() {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  return (
    <>
      <div className="local-nav-bar">
        <ul className="local-nav-bar__group">
          <li
            className={`local-nav-bar__item local-nav-bar__link ${pathname === "/partner-tutor/course-management/proceeding" ? "local-nav-bar__link--active" : ""}`}
            role="presentation"
            onClick={() => {
              navigate("/partner-tutor/course-management/proceeding");
            }}
          >
            진행중
          </li>
          <li
            className={`local-nav-bar__item local-nav-bar__link ${pathname === "/partner-tutor/course-management/completed" ? "local-nav-bar__link--active" : ""}`}
            role="presentation"
            onClick={() => {
              navigate("/partner-tutor/course-management/completed");
            }}
          >
            종료
          </li>
        </ul>
      </div>
      <Outlet />
    </>
  );
}

export default CourseManagementContainer;
