import { AxiosApiWrapper } from "../abstract/axios-api-wrapper.abstract";
import {
  CourseInfoListType,
  CourseListType,
} from "../../types/courseManagement/courseManagementTypes";

class CourseManagementApi extends AxiosApiWrapper {
  protected endPoint!: string;

  // 과외 진행중,종료 리스트
  public fetchCourseList(
    data: Record<string, unknown>,
  ): Promise<CourseListType> {
    this.endPoint = "api/v1/business/course-info/list";
    try {
      return this.post(data);
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  }

  // 과외 진행중/종료/요청 상세 정보
  public fetchCourseInfoList(params: string): Promise<CourseInfoListType> {
    this.endPoint = `api/v1/business/course-info/${params}`;
    try {
      return this.get();
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  }
}

export default new CourseManagementApi();
