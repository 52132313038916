import { Controller, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { useRecoilValue } from "recoil";
import { ProfileListType } from "../../../../../types/profile/profileListTypes";
import CommonTextArea from "../../../../common/form/CommonTextArea";
import useProfileListUpdateMutation from "../../../../../hooks/profile/useProfileListUpdateMutation";
import { configAtom } from "../../../../../store/common/configStore";
import SelectDropDown from "../../../../common/select/SelectDropDown";

function ProfileTrialTutoringEditContainer({
  clickClose,
}: {
  clickClose: () => void;
}) {
  const {
    handleSubmit,
    control,
    formState: { isValid },
  } = useForm();
  const config = useRecoilValue(configAtom);
  const mutation = useProfileListUpdateMutation(clickClose);

  const onSubmit = async (formData: any) => {
    const formDataToSend = new FormData();
    formDataToSend.append("demoClassType", formData.demoClassType);
    formDataToSend.append("demoClassDesc", formData.demoClassDesc);
    mutation.mutate({ params: formDataToSend, endPoint: "demo-class-type" });
  };

  const queryClient = useQueryClient();
  const profileData =
    queryClient.getQueryData<ProfileListType>("profileCopyList");

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      style={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div className="modal-dialog__header">
        <span className="modal-dialog__title">시범 과외(유료/무료)</span>
        <button
          onClick={clickClose}
          type="button"
          className="modal-dialog__close"
        >
          <i className="icon i-close" />
        </button>
      </div>

      <div className="modal-dialog__body">
        <div className="field-group">
          <div className="field-group__cont field-group__cont--no-style">
            <Controller
              name="demoClassType"
              control={control}
              defaultValue={profileData?.demoClassType || ""}
              rules={{ required: true }}
              render={({ field }) => (
                <SelectDropDown
                  value={field.value}
                  initialLabel={"* 시범 과외 형태"}
                  onChange={field.onChange}
                  options={config.demoClassType}
                  isFull
                />
              )}
            />
          </div>
        </div>
        <div className="field-group2 field-group2--no-border">
          <div className="field-group2__cont">
            <Controller
              name="demoClassDesc"
              control={control}
              defaultValue={profileData?.demoClassDesc}
              rules={{ required: true }}
              render={({ field }) => (
                <CommonTextArea
                  value={field.value}
                  onChange={field.onChange}
                  placeholder={
                    "시범 과외 소요시간 등 추가적인 설명이 있다면 작성해주세요."
                  }
                />
              )}
            />
          </div>
        </div>
      </div>

      <div className="modal-dialog__footer modal-dialog__footer--sticky">
        <button
          type="button"
          className="btn btn--full btn--gray"
          onClick={clickClose}
        >
          취소
        </button>
        <button
          disabled={!isValid}
          type="submit"
          className={`btn btn--full ${!isValid ? "is-disabled" : "btn--primary"}`}
        >
          저장하기
        </button>
      </div>
    </form>
  );
}

export default ProfileTrialTutoringEditContainer;
