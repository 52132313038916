import { useQuery, UseQueryResult } from "react-query";
import MemberApi from "../../services/member/MemberApi";
import { MemberInfoType } from "../../types/member/memberTypes";

const useMemberInfoQuery = (
  id: string,
): UseQueryResult<MemberInfoType, Error> => {
  return useQuery<MemberInfoType, Error>({
    queryKey: ["memberInfo", id],
    queryFn: () => MemberApi.fetchMemberInfo(id),
    onSuccess: (data: MemberInfoType) => {
      console.log("Query successful:", data);
    },
    onError: (error) => {
      console.error("Query error:", error);
    },
  });
};

export default useMemberInfoQuery;
