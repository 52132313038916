import moment from "moment";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import CommonModal from "../../common/modal/CommonModal";
import useRequestedCourseInfoQuery from "../../../hooks/requestedCourse/useRequestedCourseInfoQuery";
import {
  convertTimeFormat,
  minutesToTimeString,
  numberToDay,
} from "../../../util/util";

function RequestedCourseInfoContainer() {
  const location = useLocation();
  const { data: requestedCourseInfo } = useRequestedCourseInfoQuery(
    String(location.state),
  );
  const [openedModal, setOpenedModal] = useState<string | null>(null);

  // const modalOpen = (modalId: string) => {
  //   setOpenedModal(modalId);
  // };

  const modalClose = () => {
    setOpenedModal(null);
  };

  let dayInfo = [];
  try {
    if (requestedCourseInfo?.courseInfo?.dayInfoJson) {
      dayInfo =
        JSON.parse(requestedCourseInfo.courseInfo.dayInfoJson)?.data || [];
    }
  } catch (error) {
    console.error("Invalid JSON in dayInfoJson", error);
  }

  function getStatusInfo(status: string | undefined) {
    switch (status) {
      case "ACCEPT":
        return { text: "승인 완료", className: "complete" };
      case "REJECT":
        return { text: "승인 거절", className: "refuse" };
      case "EXPIRED":
        return { text: "기간 만료", className: "refuse" };
      case "CANCELLED":
        return { text: "요청 취소", className: "refuse" };
      default:
        return null;
    }
  }

  const statusInfo = getStatusInfo(requestedCourseInfo?.courseInfo.shareStatus);

  return (
    <main className="content pb12">
      <div className="section pb6">
        <div className="flex-wrap">
          <span className="flex-wrap__title">파트너 선생님</span>
          <div className="flex-wrap__cont">
            <p className="flex-wrap__text">
              {requestedCourseInfo?.courseInfo.tutorName}
            </p>
          </div>
        </div>

        <div className="flex-wrap">
          <span className="flex-wrap__title">학부모 연락처</span>
          <div className="parents-list">
            {requestedCourseInfo?.courseParent?.map(
              (parent: any, index: number) => (
                <div
                  className="parents-list__item"
                  key={`parents-list__item-${index}`}
                >
                  <div className="parents-list__body">
                    <i className="icon i-parents" />
                    <h5 className="parents-list__title">
                      <b>
                        {parent.studentName}({parent.studentInfo})
                      </b>
                      학부모
                    </h5>
                    <p className="parents-list__phone">{parent.parentMobile}</p>
                  </div>
                </div>
              ),
            )}
          </div>
        </div>

        <div className="flex-wrap pb6">
          <span className="flex-wrap__title">지역</span>
          <div className="flex-wrap__cont">
            <p className="flex-wrap__text">
              {requestedCourseInfo?.courseInfo.location}
            </p>
          </div>
        </div>

        <div className="divider" />

        <div className="flex-wrap">
          <span className="flex-wrap__title">과목</span>
          <div className="flex-wrap__cont">
            <div className="check-chip">
              {requestedCourseInfo?.courseInfo.className
                .split(",")
                .map((className: string, index: number) => (
                  <div
                    className="check-chip__item"
                    key={`class-names-item-${index}`}
                  >
                    <label htmlFor="chips1" className="check-chip__label">
                      <input type="checkbox" id="chips1" checked disabled />
                      <div className="check-chip__title selected">
                        <span>{className}</span>
                      </div>
                    </label>
                  </div>
                ))}
            </div>
          </div>
        </div>

        <div className="flex-wrap pb6">
          <div className="class-summary">
            <div className="class-summary__item">
              <div className="class-summary__info">
                <b>{requestedCourseInfo?.courseInfo.times}</b> 회
              </div>
              <div className="class-summary__title">수업 횟수</div>
            </div>
            <div className="class-summary__item">
              <div className="class-summary__info">
                <b>
                  {minutesToTimeString(
                    requestedCourseInfo?.courseInfo.hour ?? 0,
                  )}
                </b>
              </div>
              <div className="class-summary__title">회당 수업 시간</div>
            </div>
            <div className="class-summary__item">
              <div className="class-summary__info">
                <b>
                  {minutesToTimeString(
                    requestedCourseInfo?.courseInfo.totalHour ?? 0,
                  )}
                </b>
              </div>
              <div className="class-summary__title">총 수업 시간</div>
            </div>
          </div>
        </div>

        <div className="divider" />

        <div className="flex-wrap">
          <span className="flex-wrap__title">요일 및 시간</span>
          <div className="flex-wrap__cont">
            <ul className="tutoring-schedule tutoring-schedule--large">
              {dayInfo.map((courseInfo: any, index: number) => (
                <li
                  className="tutoring-schedule__item"
                  key={`tutoring-schedule-item-${index}`}
                >
                  <b>{numberToDay(courseInfo?.day)}</b>
                  {convertTimeFormat(courseInfo?.start)} ~
                  {convertTimeFormat(courseInfo?.end)}
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div className="flex-wrap flex-wrap--direction-row">
          <div className="flex-wrap flex-wrap--grow-1">
            <span className="flex-wrap__title">시작일</span>
            <div className="flex-wrap__cont">
              <p className="flex-wrap__text">
                <i className="icon i-date" />
                {moment(requestedCourseInfo?.courseInfo.startDate).format(
                  "YYYY.MM.DD(ddd)",
                )}
              </p>
            </div>
          </div>

          <div className="flex-wrap flex-wrap--grow-1">
            <span className="flex-wrap__title">예상 종료일</span>
            <div className="flex-wrap__cont">
              <p className="flex-wrap__text">
                <i className="icon i-date" />
                {moment(requestedCourseInfo?.courseInfo.endDate).format(
                  "YYYY.MM.DD(ddd)",
                )}
              </p>
            </div>
          </div>
        </div>

        {/* <div className="bottom-button-wrap py10"> */}
        {/*   <button */}
        {/*     type="button" */}
        {/*     className="btn btn--full btn--primary" */}
        {/*     onClick={() => { */}
        {/*       modalOpen("approve"); */}
        {/*     }} */}
        {/*   > */}
        {/*     요청취소 */}
        {/*   </button> */}
        {/* </div> */}

        {/* TODO 승인/거절 버튼 승인 완료, 승인거절 등 승인상태에 따라 조건부 렌더링 */}

        <div className="flex-box flex-box--items-center flex-box--justify-end py10">
          <div className="status-info status-info--large">
            <span className={statusInfo?.className}>{statusInfo?.text}</span>
          </div>
        </div>
        {/* <div className="flex-box flex-box--items-center flex-box--justify-end py10"> */}
        {/*   <div className="status-info status-info--large"> */}
        {/*     <span className="refuse">승인 거절 (2024.3.2)</span> */}
        {/*   </div> */}
        {/* </div> */}
        {/* <div className="flex-box flex-box--items-center flex-box--justify-end py10"> */}
        {/*   <div className="status-info status-info--large"> */}
        {/*     <span className="refuse">요청 취소 (2024.3.2)</span> */}
        {/*   </div> */}
        {/* </div> */}
        {/* <div className="flex-box flex-box--items-center flex-box--justify-end py10"> */}
        {/*   <div className="status-info status-info--large"> */}
        {/*     <span className="refuse">기간만료</span> */}
        {/*   </div> */}
        {/* </div> */}
      </div>
      <CommonModal
        open={openedModal === "reject"}
        modalClose={modalClose}
        title={"승인 거절"}
        text={
          "거절 시 요청한 사업자에게 거절 안내 푸시가 전송됩니다. 거절 하시겠습니까?"
        }
      />
      <CommonModal
        open={openedModal === "approve"}
        modalClose={modalClose}
        title={"승인 완료"}
        text={
          "승인시 요청한 사업자에게 승인 안내 푸시가 전송됩니다. 승인 하시겠습니까?"
        }
      />
    </main>
  );
}

export default RequestedCourseInfoContainer;
