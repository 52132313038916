import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { enqueueSnackbar } from "notistack";
import TabMenu from "./subjectTab/TabMenu";
import MenuList from "./subjectTab/MenuList";
import SelectedItems from "./subjectTab/SelectedItems";
import useSubjectsQuery from "../../../../hooks/standardInfo/useSubjectsQuery";
import { partnerTutorFilterAtom } from "../../../../store/partnerTutor/partnerTutorFilterStore";

const FilterSubjectSelectContainer = ({
  clickClose,
}: {
  clickClose: () => void;
}) => {
  const { data } = useSubjectsQuery();
  const [partnerTutorFilter, setPartnerTutorFilter] = useRecoilState(
    partnerTutorFilterAtom,
  );

  const categories: string[] = Object.keys(data ?? {});
  const [selectedCategory, setSelectedCategory] = useState<string>("");
  const [selectedItems, setSelectedItems] = useState<string[]>(
    partnerTutorFilter.subject?.split(",") || [],
  );
  const [isValid, setIsValid] = useState(false);

  const handleCategorySelect = (category: string) => {
    setSelectedCategory(category);
  };

  const handleItemToggle = (item: string) => {
    setSelectedItems((prev) =>
      prev.includes(item) ? prev.filter((i) => i !== item) : [...prev, item],
    );
  };

  const handleItemRemove = (item: string) => {
    setSelectedItems((prev) => prev.filter((i) => i !== item));
  };

  const handleReset = () => {
    setSelectedItems([]);
    setPartnerTutorFilter((prevReq: any) => ({
      ...prevReq,
      subject: null,
    }));
    enqueueSnackbar("초기화되었습니다.");
  };

  const handleSave = () => {
    console.log(selectedItems);
    setPartnerTutorFilter((prevReq: any) => ({
      ...prevReq,
      name: "",
      subject: selectedItems.join(","),
    }));
    clickClose();
  };

  useEffect(() => {
    if (categories.length > 0 && !selectedCategory) {
      setSelectedCategory(categories[0]);
    }
  }, [categories, selectedCategory]);

  useEffect(() => {
    if (selectedItems.length > 0) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [selectedItems]);

  return (
    <>
      <div className="modal-dialog__header">
        <span className="modal-dialog__title">지도과목</span>
        <button
          onClick={clickClose}
          type="button"
          className="modal-dialog__close"
        >
          <i className="icon i-close" />
        </button>
      </div>
      <div className="modal-dialog__body">
        <div className="msg-ly">
          <i className="icon i-info" />
          <p className="msg-ly__text">
            지도가 가능한 과목을 모두 선택해주세요.
          </p>
        </div>
        <div className="master-menu mt4">
          <div className="master-menu__container">
            <div className="master-menu__list">
              <TabMenu
                categories={categories}
                selectedCategory={selectedCategory}
                onSelect={handleCategorySelect}
              />
              <MenuList
                items={data ? data[selectedCategory] : []}
                selectedItems={selectedItems}
                onToggle={handleItemToggle}
              />
            </div>
            <SelectedItems
              selectedItems={selectedItems}
              onRemove={handleItemRemove}
            />
          </div>
        </div>
      </div>
      <div className="modal-dialog__footer">
        <button
          type="button"
          className="btn btn--full btn--gray"
          onClick={handleReset}
        >
          선택항목 초기화
        </button>
        <button
          type="button"
          disabled={!isValid}
          className={`btn btn--full ${!isValid ? "is-disabled" : "btn--primary"}`}
          onClick={handleSave}
        >
          저장하기
        </button>
      </div>
    </>
  );
};

export default FilterSubjectSelectContainer;
