import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { Controller, useForm } from "react-hook-form";
import { useRecoilValue } from "recoil";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import RadioBoxGroup from "../../../common/select/RadioBoxGroup";
import useProfileTransferMutation from "../../../../hooks/profile/useProfileTransferMutation";
import useProfileListQuery from "../../../../hooks/profile/useProfileListQuery";
import useCreateCopyProfileQuery from "../../../../hooks/profile/useCreateCopyProfileQuery";
import { userAtom } from "../../../../store/common/userStore";
import { selectedPartnerInfoAtom } from "../../../../store/profile/selectedPartnerInfoStore";
import { runContactPicker, setBackPressOn } from "../../../../gnote_api";
import { generateEncryptedLink } from "../../../../util/crypto";
import SelectDropDown from "../../../common/select/SelectDropDown";
import CommonModal from "../../../common/modal/CommonModal";

function ProfileTransferModal({
  setModalName,
  type,
}: {
  setModalName: (name: string) => void;
  type?: string;
}) {
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const { handleSubmit, register, control, setValue, watch, getValues } =
    useForm();
  const [selectedRadioValue, setSelectedRadioValue] = useState<string>(
    type || "ORIGINAL",
  );
  const profileTransferMutation = useProfileTransferMutation(() => {
    setModalName("");
  });
  const user = useRecoilValue(userAtom);
  const selectedPartnerInfo = useRecoilValue(selectedPartnerInfoAtom);
  const navigate = useNavigate();
  const [phoneNumbers, setPhoneNumbers] = useState<string[]>([]);
  const [profileOriginalList, setProfileOriginalList] = useState<any>();
  const [profileCopyList, setProfileCopyList] = useState<any>();
  const { data: originalProfileData } = useProfileListQuery(
    String(selectedPartnerInfo.id),
  );
  const { data: copyProfileData } = useCreateCopyProfileQuery({
    tutorCode: String(selectedPartnerInfo.tutorCode),
    businessCode: String(selectedPartnerInfo.businessCode),
  });
  const [isValid, setIsValid] = useState(false);
  const [alertName, setAlertName] = useState("");
  const alertClose = () => {
    setAlertName("");
  };

  useEffect(() => {
    if (selectedRadioValue === "ORIGINAL" && originalProfileData) {
      setProfileOriginalList(originalProfileData);
    } else if (selectedRadioValue === "COPY" && copyProfileData) {
      setProfileCopyList(copyProfileData);
    }
  }, [selectedRadioValue, originalProfileData, copyProfileData]);

  const clickPreview = () => {
    if (selectedRadioValue === "ORIGINAL") {
      navigate(`/profile/preview/${profileOriginalList?.id}`);
    } else if (selectedRadioValue === "COPY") {
      navigate(`/profile/preview/${profileCopyList?.id}`);
    }
  };

  const handleAnimationComplete = () => {
    if (!isOpen) {
      setModalName("");
    }
  };

  const expireDate = moment().add(7, "days").format("YYYY-MM-DD");
  const link = generateEncryptedLink(
    selectedRadioValue === "ORIGINAL"
      ? String(profileOriginalList?.id)
      : String(profileCopyList?.id),
    expireDate,
    "pro",
  );

  const onSubmit = async () => {
    setAlertName("transfer");
  };

  const handleConfirm = () => {
    profileTransferMutation.mutate({
      profileType: selectedRadioValue,
      tutorCode: String(selectedPartnerInfo.tutorCode),
      businessCode: String(user.member.id),
      comment: getValues("comment"),
      phone: getValues("phoneNumber"),
      url: link,
    });
  };

  useEffect(() => {
    (window as any).onMessageListener = (key: string, value: string) => {
      if (key === "runContactPickerResult") {
        const contact = JSON.parse(value);
        if (
          contact.phoneNumbers &&
          Array.isArray(contact.phoneNumbers) &&
          contact.phoneNumbers.length > 0
        ) {
          // 배열의 첫 번째 값을 직접 가져와서 문자열로 변환
          const firstPhoneNumber = String(contact.phoneNumbers[0]);
          // 하이픈 제거 후 설정
          const cleanPhoneNumber = firstPhoneNumber.replace(/-/g, "");
          setValue("phoneNumber", cleanPhoneNumber);
          setPhoneNumbers(
            contact.phoneNumbers.map((num: any) =>
              String(num).replace(/-/g, ""),
            ),
          );
        }
      }
    };
  }, [setValue]);

  const phoneNumbersOption = phoneNumbers.reduce(
    (acc, cur) => {
      const formattedNumber = cur.replace(/-/g, ""); // 하이픈 제거
      acc[formattedNumber] = formattedNumber;
      return acc;
    },
    {} as Record<string, string>,
  );

  useEffect(() => {
    setBackPressOn(false);
    return () => {
      setBackPressOn(true);
    };
  }, []);

  const phoneNumber = watch("phoneNumber"); // watch 값을 변수로 추출

  useEffect(() => {
    if (phoneNumber) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [phoneNumber]);

  return (
    <div className="bottom-sheet">
      <motion.div
        className="bottom-sheet__container"
        initial={{ y: "100%" }}
        animate={{ y: isOpen ? "0%" : "100%" }}
        transition={{ type: "spring", stiffness: 250, damping: 30 }}
        onAnimationComplete={handleAnimationComplete}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <button
            type="button"
            className="bottom-sheet__close"
            onClick={() => {
              setIsOpen(false);
            }}
          >
            <i className="icon i-close" />
          </button>

          <div className="bottom-sheet__header">선생님 프로필 전송</div>
          <div className="bottom-sheet__body">
            <div className="msg-ly">
              <div className="msg-ly__text">
                <ul className="msg-ly__ul">
                  <li>허위사실에 대한 모든 책임은 전송자에게 있습니다.</li>
                  <li>입력한 휴대폰번호로 프로필 알림톡이 전송됩니다.</li>
                </ul>
              </div>
            </div>

            <RadioBoxGroup
              radioOption={{
                ORIGINAL: "선생님 프로필 원본",
                COPY: "사업자 수정 프로필",
              }}
              subTitle="전송 프로필"
              selectedValue={selectedRadioValue}
              setSelectedValue={setSelectedRadioValue}
            />

            <div className="field-group">
              <div style={{ display: "flex" }}>
                <label className="field-group__label">전송 번호(알림톡)</label>
                <p
                  role={"presentation"}
                  onClick={() => runContactPicker()}
                  className={"ml3"}
                  style={{ fontSize: "0.9rem", marginTop: "0.1rem" }}
                >
                  연락처 찾기 {">"}
                </p>
              </div>
              <div>
                <Controller
                  name="phoneNumber"
                  control={control}
                  render={({ field }) => (
                    <>
                      {phoneNumbers.length > 1 ? (
                        <div className={"mb2"}>
                          <SelectDropDown
                            isFull
                            value={field.value || ""} // Controller의 값으로 셀렉트박스를 제어
                            onChange={field.onChange}
                            initialLabel={"선택"}
                            options={phoneNumbersOption}
                          />
                        </div>
                      ) : (
                        <div className="field-group__cont">
                          <input
                            type="number"
                            className="field-group__input"
                            placeholder="* 휴대폰번호 입력(숫자만 입력)"
                            value={field.value || ""} // Controller로 관리되는 값
                            onChange={(e) => field.onChange(e.target.value)} // 직접 input 값 변경
                          />
                          <span
                            role={"presentation"}
                            className="field-group__clear"
                            onClick={() => {
                              setValue("phoneNumber", "");
                            }}
                          />
                        </div>
                      )}
                    </>
                  )}
                />
              </div>
            </div>
            <div className="field-group">
              <label className="field-group__label">코멘트</label>
              <div className="field-group__cont">
                <textarea
                  className="field-group__textarea"
                  placeholder="입력해주세요."
                  {...register("comment")}
                />
              </div>
            </div>
          </div>
          <div className="bottom-sheet__footer">
            <button
              type="button"
              className="btn btn--full btn--gray"
              onClick={clickPreview}
            >
              미리보기
            </button>
            <button
              type="submit"
              className={`btn btn--full ${!isValid ? "is-disabled" : "btn--primary"}`}
            >
              전송하기
            </button>
          </div>
        </form>
      </motion.div>
      <CommonModal
        open={alertName === "transfer"}
        modalClose={alertClose}
        title={"프로필 전송"}
        text={"전송 하시겠습니까?"}
        onConfirm={handleConfirm}
      />
    </div>
  );
}

export default ProfileTransferModal;
