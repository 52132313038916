import React, { useEffect, useRef } from "react";

function CommonTextArea({
  value,
  placeholder,
  onChange,
}: {
  value: string;
  placeholder: string;
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
}) {
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    const textarea = textareaRef.current;
    if (textarea) {
      autoResize(textarea);

      const handleInput = () => {
        autoResize(textarea);
      };

      textarea.addEventListener("input", handleInput);

      return () => {
        textarea.removeEventListener("input", handleInput);
      };
    }
    return () => {};
  }, []);

  const autoResize = (textarea: HTMLTextAreaElement) => {
    textarea.style.height = "auto";
    textarea.style.height = `${textarea.scrollHeight}px`;
  };

  return (
    <div className="field-group2__cont">
      <textarea
        value={value}
        onChange={onChange}
        className="field-group2__textarea"
        rows={1}
        placeholder={placeholder}
        ref={textareaRef}
      />
    </div>
  );
}

export default CommonTextArea;
