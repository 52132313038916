import moment from "moment/moment";
import { useRecoilValue } from "recoil";
import { useNavigate } from "react-router-dom";
import { AlarmType } from "../../../../types/home/homeTypes";
import { userAtom } from "../../../../store/common/userStore";
import useAlarmListUpdateMutation from "../../../../hooks/home/alarm/useAlarmListUpdateMutation";
import ButtonDropDown from "../../../common/select/ButtonDropDown";
import useAlarmListDeleteMutation from "../../../../hooks/home/alarm/useAlarmListDeleteMutation";

function AlarmListCard({ item }: { item: AlarmType }) {
  const alarmListUpdateMutation = useAlarmListUpdateMutation();
  const alarmListDeleteMutation = useAlarmListDeleteMutation();
  const user = useRecoilValue(userAtom);
  const navigate = useNavigate();

  const onClickAlarm = () => {
    if (!item.readFlag) {
      alarmListUpdateMutation.mutate({
        params: {
          readFlag: "Y",
          readDate: new Date(),
          memberCode: user.member.id,
          msgId: item.msgId,
        },
        id: String(item.id),
      });
    }
    alarmClickNavigate();
  };

  const alarmClickNavigate = () => {
    switch (item.msgKindType) {
      case "REJECT_REQUEST_COURSE":
        navigate("/requested-course"); // 과외 요청 거절 시 이동할 경로
        break;
      case "ACCEPT_REQUEST_COURSE":
        navigate("/requested-course"); // 과외 요청 승인 시 이동할 경로
        break;
      case "REJECT_REQUEST_PARTNER":
        navigate("/partner-tutor/requested"); // 파트너 요청 거절 시 이동할 경로
        break;
      case "ACCEPT_REQUEST_PARTNER":
        navigate("/partner-tutor/requested"); // 파트너 요청 승인 시 이동할 경로
        break;
      default:
        navigate("/"); // 그 외의 경우 기본 경로로 이동
        break;
    }
  };

  const getAlarmProfileImage = () => {
    if (item.senderInfo.profileImage) {
      return (
        <img src={item.senderInfo.profileImage?.toString()} alt="Profile" />
      );
    }
    if (item.senderInfo.memberCode && !item.senderInfo.profileImage) {
      return <i className="icon i-parents" />;
    }
    if (!item.senderInfo.memberCode) {
      return <i className="icon i-cscenter" />;
    }
    return <></>;
  };

  const onClickAlarmDelete = () => {
    alarmListDeleteMutation.mutate({
      id: String(item.id),
    });
  };

  return (
    <li className="board-list2__item" role="presentation">
      <div
        role="presentation"
        className="board-list2__link"
        onClick={onClickAlarm}
      >
        <div className={`board-list2__pic ${!item.readFlag && "is-new"}`}>
          {getAlarmProfileImage()}
        </div>
        <div className="board-list2__info">
          <div className="board-list2__title">{item.content}</div>
          <span className="board-list2__date">
            {moment(item.createdAt).format("YYYY-MM-DD HH:mm:ss")}
          </span>
        </div>

        <div role={"presentation"} onClick={(event) => event.stopPropagation()}>
          <ButtonDropDown
            buttonIcon="i-more"
            items={[
              {
                text: "삭제",
                onClick: onClickAlarmDelete,
              },
            ]}
          />
        </div>
      </div>
    </li>
  );
}

export default AlarmListCard;
