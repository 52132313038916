import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import styled from "styled-components";
import { parentListType } from "../../../types/requestedCourse/requestedCourseTypes";
import { runContactPicker, setBackPressOn } from "../../../gnote_api";
import SelectDropDown from "../select/SelectDropDown";

const BottomModalHeaderWrap = styled.div`
  width: 100%;
`;

const BottomModalBodyWrap = styled.div`
  width: 100%;
`;

const BottomModalFooterWrap = styled.div`
  width: 100%;
`;

interface BottomSheetModalProps {
  modalName: string;
  setModalName: (name: string) => void;
  setDirectInput?: (value: string) => void;
  setParentList?: (
    list: (prevList: parentListType[]) => parentListType[],
  ) => void;
  placeHolder: string[];
  textLimit?: number;
  title?: string;
  text?: string[];
  inputKeys: string[];
  initialValues?: { [key: string]: string };
  editIndex?: number | null;
}

function BottomSheetModal({
  setModalName,
  modalName,
  setDirectInput,
  setParentList,
  placeHolder,
  textLimit,
  title,
  text,
  inputKeys,
  initialValues,
  editIndex,
}: BottomSheetModalProps) {
  const [inputValues, setInputValues] = useState<any>(
    inputKeys.reduce((acc, key) => ({ ...acc, [key]: "" }), {}),
  ); // ["student_name", "student_info"] => {student_name : "",student_info,"" }
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const [phoneNumbers, setPhoneNumbers] = useState<string[]>([]);
  const [isValid, setIsValid] = useState<boolean>(false);

  useEffect(() => {
    if (inputKeys[0] !== "subject") {
      setInputValues(initialValues);
    }
  }, [initialValues, inputKeys]);

  const clickClose = () => {
    setIsOpen(false);
  };

  const clickUpdate = () => {
    setIsOpen(false);
    if (modalName === "directInput" && setDirectInput) {
      setDirectInput(inputValues[inputKeys[0]]);
    } else if (modalName === "parentMobile" && setParentList) {
      if (editIndex !== null) {
        // 변경(update) 할 때 / onEdit 변경 모달 열때 set한 index를 전달
        setParentList((prevList: parentListType[]) => {
          const newList: parentListType[] = [...prevList];
          newList[editIndex!] = inputValues;
          return newList;
        });
      } else {
        // 추가(add) 할 때
        setParentList((prevList: parentListType[]) => [
          ...prevList,
          inputValues,
        ]);
      }
    }
  };

  const onTextChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    key: string,
  ) => {
    const newValue = e.target.value;

    // directInput 모달이고 textLimit이 설정되어 있을 때만 길이 체크
    if (modalName === "directInput" && textLimit) {
      // 입력된 값이 제한 길이를 초과하면 잘라내기
      if (newValue.length > textLimit) {
        setInputValues({
          ...inputValues,
          [key]: newValue.slice(0, textLimit),
        });
        return;
      }
    }

    setInputValues({
      ...inputValues,
      [key]: newValue,
    });
  };

  const handleAnimationComplete = () => {
    if (!isOpen) {
      setModalName("");
    }
  };

  useEffect(() => {
    (window as any).onMessageListener = (key: string, value: string) => {
      if (key === "runContactPickerResult") {
        const contact = JSON.parse(value);
        if (contact.phoneNumbers && contact.phoneNumbers.length > 0) {
          const firstPhoneNumber = String(contact.phoneNumbers[0]);
          // 하이픈 제거 후 설정
          const cleanPhoneNumber = firstPhoneNumber.replace(/-/g, "");
          setPhoneNumbers(
            contact.phoneNumbers.map((num: any) =>
              String(num).replace(/-/g, ""),
            ),
          );
          setInputValues({
            parentMobile: cleanPhoneNumber,
          });
        }
      }
    };
  }, []);

  const setParentMobile = (selectedPhoneNumber: string) => {
    setInputValues({
      parentMobile: selectedPhoneNumber,
    });
  };

  const phoneNumbersOption = phoneNumbers.reduce(
    (acc, cur) => {
      const formattedNumber = cur.replace(/-/g, ""); // 하이픈 제거
      acc[formattedNumber] = formattedNumber;
      return acc;
    },
    {} as Record<string, string>,
  );

  const handleClearInput = (key: string) => {
    setInputValues({
      ...inputValues,
      [key]: "",
    });
  };

  useEffect(() => {
    setBackPressOn(false);
    return () => {
      setBackPressOn(true);
    };
  }, []);

  useEffect(() => {
    if (modalName === "parentMobile") {
      const { studentName, studentInfo, parentMobile } = inputValues;
      setIsValid(
        Boolean(
          studentName?.trim() && studentInfo?.trim() && parentMobile?.trim(),
        ),
      );
    } else {
      const { subject } = inputValues;
      setIsValid(Boolean(subject?.trim()));
    }
  }, [inputValues, modalName]);

  return (
    <div className="bottom-sheet">
      <motion.div
        className="bottom-sheet__container"
        initial={{ y: "100%" }}
        animate={{ y: isOpen ? "0%" : "100%" }}
        transition={{ type: "spring", stiffness: 250, damping: 30 }}
        onAnimationComplete={handleAnimationComplete}
      >
        {title && (
          <BottomModalHeaderWrap>
            <button
              type="button"
              className="bottom-sheet__close"
              onClick={clickClose}
            >
              <i className="icon i-close" />
            </button>
            <div className="bottom-sheet__header">{title}</div>
          </BottomModalHeaderWrap>
        )}

        <BottomModalBodyWrap>
          <div className="bottom-sheet__body">
            {text && (
              <div className="msg-ly">
                <div className="msg-ly__text">
                  <ul className="msg-ly__ul">
                    {text.map((text: string, index: number) => (
                      <li key={`text-list-${index}`}>{text}</li>
                    ))}
                  </ul>
                </div>
              </div>
            )}

            {/* Input */}
            {placeHolder.map((ph: string, index: number) => (
              <div className="field-group" key={`field-group-${index}`}>
                <label className="field-group__label field-group__label--blind">
                  입력
                </label>
                {/* parentMobile 입력 input이면서 연락처 찾기에서 2개이상의 번호를 가져왔을때 */}
                {inputKeys[index] === "parentMobile" &&
                phoneNumbers.length > 1 ? (
                  <div style={{ marginTop: "0.5rem" }}>
                    <SelectDropDown
                      initialLabel={"선택"}
                      options={phoneNumbersOption}
                      isFull
                      value={inputValues.parentMobile}
                      onChange={setParentMobile}
                    />
                  </div>
                ) : (
                  <div className="field-group__cont">
                    <input
                      type={`${inputKeys[index] === "parentMobile" ? "number" : "text"}`}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        onTextChange(e, inputKeys[index]);
                      }}
                      className="field-group__input"
                      placeholder={ph}
                      value={inputValues[inputKeys[index]] || ""}
                      maxLength={
                        modalName === "directInput" ? textLimit : undefined
                      }
                    />
                    <span
                      className="field-group__clear"
                      role={"presentation"}
                      onClick={() => handleClearInput(inputKeys[index])}
                    />
                  </div>
                )}
                {modalName === "directInput" && (
                  <span className="field-group__check-characters">
                    <em>{inputValues[inputKeys[index]].length}</em> /{" "}
                    {textLimit}자
                  </span>
                )}
              </div>
            ))}
            {modalName === "parentMobile" && (
              <>
                <div className="ml2 mt2 mb1">
                  <p
                    role={"presentation"}
                    onClick={() => runContactPicker()}
                    style={{ fontSize: "0.9rem" }}
                  >
                    연락처 찾기 {">"}{" "}
                  </p>
                </div>
              </>
            )}
          </div>
        </BottomModalBodyWrap>

        <BottomModalFooterWrap>
          <div className="bottom-sheet__footer">
            <button
              type="button"
              className="btn btn--full btn--gray"
              onClick={clickClose}
            >
              취소
            </button>
            <button
              type="button"
              className={`btn btn--full btn--primary ${
                !isValid ? "is-disabled" : ""
              }`}
              onClick={clickUpdate}
            >
              저장하기
            </button>
          </div>
        </BottomModalFooterWrap>
      </motion.div>
    </div>
  );
}

export default BottomSheetModal;
