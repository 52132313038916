import { useRecoilValue } from "recoil";
import { useState } from "react";
import useProfileListQuery from "../../../../hooks/profile/useProfileListQuery";
import defaultImage from "../../../../assets/img/profile_default.jpg";
import { profileGroups } from "../info/ProfileGroups";
import ProfileInfoList from "../info/ProfileInfoList";
import { selectedPartnerInfoAtom } from "../../../../store/profile/selectedPartnerInfoStore";
import ProfileTransferModal from "../modal/ProfileTransferModal";
import { calculateAge, translateValue } from "../../../../util/util";
import { genderConfig } from "../../../../config/config";

function ProfileOriginalTabContainer() {
  const selectedPartnerInfo = useRecoilValue(selectedPartnerInfoAtom);
  const { data: profileInfo } = useProfileListQuery(
    String(selectedPartnerInfo.id),
  );

  const imageSrc = profileInfo?.profileImage
    ? profileInfo?.profileImage.toString()
    : defaultImage;
  const [modalName, setModalName] = useState<string>("");

  return (
    <main className="content profile">
      <div className="section pb8">
        <div className="flex-box flex-box--direction-column flex-box--justify-center flex-box--items-center">
          <div className="typo-large text-center strong pt5">선생님 프로필</div>
          <div className="profile-photo">
            <div className="profile-photo__image-preview">
              <img
                className="profile-photo__img"
                src={imageSrc}
                alt="Profile"
              />
            </div>
          </div>
          <div className="flex-box flex-box--direction-column flex-box--items-center text-center">
            <span className="typo-large strong my1">
              {profileInfo?.name} 선생님
            </span>
            {profileInfo?.gender && (
              <span className="typo-small">
                <>
                  {translateValue(profileInfo?.gender, genderConfig)} /{" "}
                  {calculateAge(profileInfo?.birthDate)}세
                </>
              </span>
            )}
          </div>
        </div>
      </div>
      <div className="divider" />

      <div className="section">
        {profileGroups.map(({ label, noPreview }, index) => (
          <ProfileInfoList
            key={`profileInfoList-${index}`}
            profileData={profileInfo}
            label={label}
            type={"original"}
            noPreview={noPreview}
          />
        ))}
      </div>

      <div className="bottom-button-wrap pt10">
        <button
          type="button"
          className="btn btn--full btn--primary"
          onClick={() => setModalName("profileTransfer")}
        >
          전송하기
        </button>
      </div>

      {modalName === "profileTransfer" && (
        <ProfileTransferModal setModalName={setModalName} type={"ORIGINAL"} />
      )}
    </main>
  );
}

export default ProfileOriginalTabContainer;
