import { useMutation } from "react-query";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import AuthApi from "../../services/auth/AuthApi";
import { userAtom } from "../../store/common/userStore";

const fetchSignUp = async (formData: FormData): Promise<any> => {
  const baseUrl = AuthApi.getBaseUrl();

  try {
    const response = await axios.post(
      `${baseUrl}/api/v1/auth/signup`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
    );

    return response;
  } catch (error) {
    console.error("Sign-up failed:", error);
    throw error;
  }
};

export const useSignUpMutation = () => {
  const navigate = useNavigate();
  const setUser = useSetRecoilState(userAtom);

  return useMutation(fetchSignUp, {
    onSuccess: (res) => {
      setUser({
        member: {
          id: res.id,
          loginId: res.loginId,
          name: res.name,
          memberType: res.memberType,
          memberStatus: res.memberStatus,
        },
      });

      navigate("/signup/mobile-auth");
    },
    onError: (error) => {
      console.error("Sign-up failed:", error);
    },
  });
};
