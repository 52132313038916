const TabMenu = ({
  categories,
  selectedCategory,
  onSelect,
}: {
  categories: string[];
  selectedCategory: string;
  onSelect: (category: string) => void;
}) => {
  return (
    <div className="master-menu__main">
      <ul>
        {categories.map((category) => (
          <li
            role={"presentation"}
            key={category}
            data-menu={category}
            className={selectedCategory === category ? "active" : ""}
            onClick={() => onSelect(category)}
          >
            <span>{category}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default TabMenu;
