import moment from "moment";
import { CourseClassType } from "../../../types/courseManagement/courseManagementTypes";

function ClassCard({ data }: { data: CourseClassType }) {
  return (
    <div className={"class-list__item"}>
      <div className="class-list__body">
        <i
          className={`icon ${data?.status === "COMPLETE" ? "i-class-before" : "i-class-after"}`}
        />
        <h5 className="class-list__title">
          {data?.classIndex}회
          <em className="dot" />
          {moment(data?.classDate).format("YY.MM.DD.(ddd)")}
        </h5>
        <p className="class-list__time">
          {moment(data?.classStartTime).format("a hh:mm")} ~
          {moment(data?.classEndTime).format("a hh:mm")}
        </p>
      </div>
    </div>
  );
}

export default ClassCard;
