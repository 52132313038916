import { useNavigate } from "react-router-dom";
import verificationImg from "../../../../assets/img/verification_img01.png";

function SignUpComplete() {
  const navigate = useNavigate();
  return (
    <>
      <main className="content pb0">
        <div className="section flex-box">
          <div
            className="flex-box flex-box--direction-column flex-box--items-center flex-box--justify-center"
            style={{ width: "100%" }}
          >
            <div>
              <img src={verificationImg} width="100" alt={verificationImg} />
            </div>
            <div className="typo-medium color-black text-center mt4">
              본인인증이 완료되었습니다.
            </div>
            <div className="typo-small strong color-black text-center mt4">
              가입 검수는 영업일 기준 1~2일이 소요되며,
              <br /> 가입 완료 시 휴대폰번호로 안내해드립니다.
            </div>
          </div>
        </div>
        <div className="content-footer content-footer--transparent">
          <button
            id="btn-submit"
            className="btn btn--full btn--primary"
            type="button"
            onClick={() => {
              navigate("/");
            }}
          >
            확인
          </button>
        </div>
      </main>
    </>
  );
}

export default SignUpComplete;
